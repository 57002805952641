import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
        <header id="homepage_header">
            <div className="container header_container">
                <h1 className="header_title"> La plateforme web qui facile l’échange entre <i>propriétaire</i> et <i>locataire</i></h1>
                <a href="#headerPopup" id="headerVideoLink" target="_blank" className="popup-modal primary_btn">Voir la vidéo</a>
                <div id="headerPopup" class="mfp-hide embed-responsive embed-responsive-21by9">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/dcAzjdYypPg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
                <br />
                <br />
                <br />
                <img className="header_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2FFrame_untava-Original.png?alt=media&token=b6a3fe61-de62-4e7a-bca8-1f30b0b73b25" />
            </div>
        </header>
    )
  }
}

export default Header;
