import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import firebase from 'firebase';
import * as selectors from '../selectors';

const firestore = firebase.firestore();

export function* fetchNotificationsSaga(action) {
    try {
        const user = yield select(selectors.getUser);
        const nbNotifs = yield call(()=> {
            // console.log("FETCH_NOTIFICATION", user);
            const uid = user.uid;
            const type = user.type;
            const firestoreDocument = type === 'loc' ?  'notifications_loc' : 'notifications_prop';
            return firestore.collection(firestoreDocument).where("to","==",uid).get().then(snap=>{return snap.size})
        });
        // yield console.log(nbNotifs);
        yield put({type: "NB_NOTIFICATION_FETCHED", nbNotifs });
    } catch (e) {
        console.log("ERROR in notif saga", e);
    }
}

export function* fetchUserTypeSaga(action) {
    try {
        // console.log("CALLING SAGA USER TYPE", action);
        const user = yield select(selectors.getUser);
        const userType = yield call( ()=>{
            // console.log(action)
            const uid = user.uid;
            return firestore.collection('users').doc(uid).get().then(snap =>{
            const data = snap.data();
            // console.log("USER_TYPE", data.user_type)
            return data.user_type;
        })
        });
        yield put({type: "USER_TYPE_CHANGE", userType });

    } catch (e) {
        console.log(e);
    }
}

export function* watchSagas() {
    yield takeEvery('USER_CHANGE',fetchUserTypeSaga);
    yield takeEvery('USER_TYPE_CHANGE',  fetchNotificationsSaga);
    yield takeEvery('REFRESH_NOTIFS', fetchNotificationsSaga);
}


