import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { NavLink } from 'react-router-dom';
import { UserBindingInstance } from 'twilio/lib/rest/ipMessaging/v2/service/user/userBinding';
const firestore = firebase.firestore();

class FormPerso extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      last_name: "",
      piece: ""
    }

  }

  componentDidMount() {

    firestore.collection('profils_locataires').doc(this.props.uid).get()
      .then(userDataSnapshot => {
        var userData = userDataSnapshot.data();
        console.log(userData);
        if (userData) {
          this.setState({
            last_name: userData.last_name,
            first_name: userData.first_name,
            date: userData.birth_date,
            email: userData.email,
            phone: userData.phone,
            country: userData.country,
            city: userData.city,
            postal_code: userData.postal_code,
            description: userData.description,
          })

          // document.getElementById("nom").value = userData.last_name;
          $("#nom").val(userData.last_name);
          $('#prenom').val(userData.first_name);
          $('#datenaissance').val(userData.birth_date);
          $('#email').val(userData.email);
          $('#tel').val(userData.phone);
          $('#country').val(userData.country);
          $('#ville').val(userData.city);
          $('#postal_code').val(userData.postal_code);
          $('#descripion').text(userData.description);
        }
      })
  }


  handleClick(e) {
    e.preventDefault();
    console.log('saving firebase');

    var nom = $("#nom").val();
    var prenom = $('#prenom').val();
    var date = $('#datenaissance').val();
    var email = $('#email').val();
    var tel = $('#tel').val();
    var country = $('#pays').val();
    var city = $('#ville').val();
    var postal_code = $('#postal_code').val();
    // var piece = $('#piece').val();

    var is_single = $('#is_single').prop('checked');
    var is_relationship = $('#is_relationship').prop('checked');

    var is_student = $('#is_student').prop('checked');
    var is_employed = $('#is_employed').prop('checked');

    var statut_animaux = "";

    // var animal = $('#animal').prop('checked');
    // var no_animal = $('#no_animal').prop('checked');

    var description = $('#description').prop('value');

    const new_profil =
    {
      last_name: nom,
      first_name: prenom,
      birth_date: date,
      email: email,
      tel: tel,
      country: country,
      city: city,
      postal_code: postal_code,
      //piece: piece,

      is_single: is_single,
      is_relationship: is_relationship,

      is_student: is_student,
      is_professional: is_employed,

      statut_animaux: statut_animaux,

      // animal: animal,
      // no_animal: no_animal,

      description: description,
      uid: this.props.user.uid
    }
    console.log(new_profil);
    firestore.collection('profils_locataires').doc(this.props.uid).set(new_profil, {merge: true});
  }

  componentDidUpdate() {

  }

  render() {

    return (
      <div className="all_notification_continer">
        <div className="notification_container">
          <div className="row text-center">
            <form onSubmit={this.handleClick} style={{display: 'contents'}}>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="nom" type="text" placeholder="Nom" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="prenom" type="text" placeholder="Prénom" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="datenaissance" type="date" placeholder="Date de naissance" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="email" type="text" placeholder="Courriel" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="tel" type="tel" placeholder="Numéro de téléphone" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="pays" type="text" placeholder="Pays" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="ville" type="text" placeholder="Ville" required />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="postal_code" type="text" placeholder="Code postal" required />
              </div>
              
              <div className="col-lg-6 col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12 text-left">
                    <p id="info">Situation Famillliale</p>
                    <input id="is_single" type="radio" name="StatutFamilliale" required/> <span>Célibataire</span>
                    <br />
                    <input id="is_relationship" type="radio" name="StatutFamilliale" required/> <span>En couple</span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 text-left">
                    <p id="info">Statut Professionnel</p>
                    <input id="is_student" type="radio" name="StatutProfessionnel" required />
                    <span> Étudiant</span>
                    <br />
                    <input id="is_employed" type="radio" name="StatutProfessionnel" required />
                    <span> Travailleur</span>
                  </div>

                  {/* <div className="col-lg-4 col-md-4 col-12 text-left">
                    <p id="info">Animaux</p>
                    <input id="animal" type="radio" name="Animaux" required />
                    <span> Oui</span>
                    <br />
                    <input id="no_animal" type="radio" name="Animaux" required />
                    <span> Non</span>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-12" style={{marginBottom: '45px'}}>
                <p >Description</p>
                <textarea id="description" rows="5" name="message" className="form-control mobileForm" placeholder="Sports, loisirs, films, ect ..." />
              </div>
              <br />
              <br />
              <div className="col-lg-12 col-md-12 col-12 text-right">
                <button className="primary_btn" type="submit"> Enregistrer </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

class FormProf extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="container">
          <h1 className="critere_title"> Renseignements professionnels</h1>
        </div>
        <div className="notification_container">
            <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-12">
                    <input className="form-control form_input" type="text" placeholder="Courriel du référent" />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <input className="form-control form_input" type="text" placeholder="Confirmer le courriel du référent" />
                </div>
                <div className="col-lg-12 col-md-12 col-12 text-right" style={{marginTop: '15px'}}>
                    <span className="primary_btn"> Confirmer </span>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

class FormLocataire extends Component {
  constructor() {
    super();
    this.state = {
    }
  }

  disconnect() {
    firebase.auth().signOut();
  }

  render() {
    return (
      <div>
      <header id="notification_header">
          <div className="container">
              <h1 className="critere_title"> Renseignements personnels</h1>
          </div>
      </header>

      <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
          <div className="row">
              <div className="col-12">
                  <FormPerso uid={this.props.user.uid} />
                  <br />
                  <FormProf />
              </div>
          </div>
      </div>
  </div>
    )
  }
}

function mapStateToProps(state) {
  if (state.user) {
    return {
      user: state.user,
    }
  }
}

export default connect(mapStateToProps)(FormLocataire);
