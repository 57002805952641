
export function showToken(currentToken) {
    // Show token in console and UI.
    var tokenElement = document.querySelector('#token');
    tokenElement.textContent = currentToken;
}

export function sendTokenToServer(db, uid, currentToken) {
    if (true) {
        console.log('Sending token to server ', uid);
        db.collection('users').doc(uid).set({
            fcm: currentToken
        }, {merge:true})
        // TODO(developer): Send the current token to your server.
        setTokenSentToServer(true);
    } else {
        console.log('Token already sent to server so won\'t send it again ' +
            'unless it changes');
    }
}

export function isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
}

export function setTokenSentToServer(sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
}
