import React, { Component } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class About extends Component {
constructor() {
    super();
    this.state = {
        action_type: "rensignementPerso",
    }
    this.handleClickForm = this.handleClickForm.bind(this);
    }
    handleClickForm(e) {
    this.setState({ action_type: e.target.id });
    console.log(e.target.id);
    }

render() {
    var btn_action;
    btn_action = this.state.action_type;

    if (btn_action == "slide_left") {
        $(".comments_container").animate({
            marginLeft: '-=250px'
        },
        500, 'linear');
    }

    else  if (btn_action == "slide_right") {
        $(".comments_container").animate({
            marginLeft: '+=250px'
        },
        500, 'linear');
    }

    return (
        <div>
            <section id="search_appartment">
                <div className="container">
                    <h1 className="search_text">{this.props.user.type === 'loc' ? 'Iloc est aussi un moteur de recherche qui permet aux locataires de trouver le logement qui leur convient.' : 'Iloc est aussi un moteur de recherche qui permet aux propriétaires de trouver des locataires qui leur convient.'}  </h1>
                    <br />
                    <a href="/critere" className="secondary_btn custum_secondary_btn_padding">
                    {this.props.user.type === 'loc' ? 'Trouver un appartement' : 'Trouver un locataire'}                    
                        {/* <span className="header_search_icon"><i class="fas fa-building"></i></span> */}
                    </a>
                </div>
            </section>
            <section id="fiche_iloc">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <img className="fiche_iloc_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Ffiche_iloc_home.svg?alt=media&token=201580f0-139e-467b-a2f2-4a46865357e8" />
                        </div>
                        <div className="col-lg-8 col-12">
                            <h3 className="fiche_iloc_title">Fiche iloc Locataire</h3>
                            <p className="fiche_iloc_text"> Votre fiche ILOC vous permettra d’accéder plus facilement aux logements,
                            de voyager partout dans le monde avec votre historique de location, 
                            de consulter les profils des différents propriétaires sur le marché 
                            et surtout de gagner du temps dans toutes vos démarches !
                            </p>
                            <br />
                            <a href="signup" className="primary_btn">M’inscrire comme locataire</a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="proprietaire">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <h3 className="fiche_iloc_title">Fiche iloc Propriétaire</h3>
                            <p className="fiche_iloc_text"> Votre fiche ILOC vous permettra de trouver de bons locataires pour votre logement,
                            de pouvoir occuper tous vos appartements,
                            de faciliter vos recherches en accédant à un bassin potentiel de locataires,
                            Et surtout de gagner du temps dans vos démarches administratives !
                            </p>
                            <br />
                            <a href="signup" className="primary_btn">M’inscrire comme propriétaire</a>
                        </div>
                        <div className="col-lg-4 col-12">
                            <img className="fiche_iloc_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Ffiche_proprietaire_home.svg?alt=media&token=17ca0357-03c4-4433-be99-b3164dfc8f78" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="fonctionnalite">
                <div className="container mobile_padding">
                    <h3 className="fiche_iloc_title text-left text-center-mobile">Nos fonctionnalités</h3>
                    <div className="row text-center">
                        <div className="col-lg-3 col-md-6 col-12 fonctionnality_container first_items">
                            <img className="icon_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fphone_hand.svg?alt=media&token=191862e2-2b1d-4b56-bd75-9b21012d6d12" />
                            <p className="fonctionnality_text">Gestion facile et en ligne</p>                         
                            <hr className="hr_mobile" />
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 fonctionnality_container">
                            <div className="border_left_items">
                                <img className="icon_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fsecured.svg?alt=media&token=2a2b6d18-0183-473a-b8a0-71484818bb27" />
                                <p className="fonctionnality_text">Vos informations sont sécurisées</p>
                            </div>
                            <hr className="hr_mobile" />
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 fonctionnality_container">
                            <div className="border_left_items">
                                <img className="icon_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fphone_hand.svg?alt=media&token=191862e2-2b1d-4b56-bd75-9b21012d6d12" />
                                <p className="fonctionnality_text">99% d'approbation grâce à votre score de location</p>
                            </div>
                            <hr className="hr_mobile" />
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 fonctionnality_container last_items">
                            <div className="border_left_items">
                                <img className="icon_image" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fsecured.svg?alt=media&token=2a2b6d18-0183-473a-b8a0-71484818bb27" />
                                <p className="fonctionnality_text">Vos informations sont sécurisées</p>
                            </div>                        
                        </div>
                    </div>
                </div>
            </section>
            <section id="avis">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 text-center-mobile">
                            <h3 className="fiche_iloc_title">L’avis de nos utilisateurs</h3>
                        </div>
                        <div className="col-lg-8 text-right text-center-mobile">
                        <span onClick={this.handleClickForm} id="slide_left" className="btn_prev_next active_slider"><img onClick={this.handleClickForm} id="slide_left" className="arrow_left" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fleft_arrow.svg?alt=media&token=e5631cde-6114-4446-91ce-6c30db036d02"/></span>
                        <span onClick={this.handleClickForm} id="slide_right" className="btn_prev_next inactive_slider"><img onClick={this.handleClickForm} id="slide_right" className="arrow_right" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fright_arrow.svg?alt=media&token=163e1239-20fd-47f4-b6c5-4e3e8c7f1c88"/></span>
                            <span style={{marginLeft: '20px'}} className="primary_btn margin_right_desktop">Donner votre avis</span>
                        </div>
                    </div>
                </div>
                <div className="container-fluid float_right">
                    <div className="row">
                        <div className="col-12 margin-top-mobile comments_container">
                            <div className="fonctionnality_items_comments">
                                <p className>Etoiles</p>
                                <p className="review_name">Phanuel</p>
                                <p className="review_text">Iloc eu sodales elit. Ut ullamcorper nec dolor ut volutpat. Duis at ornare risus. Aliquam in finibus orci, malesuada sagittis sapien. Praesent lobortis faucibus dui.</p>
                            </div>
                            <div className="fonctionnality_items_comments second">
                                <p className>Etoiles</p>
                                <p className="review_name">Elyeser</p>
                                <p className="review_text">Iloc eu sodales elit. Ut ullamcorper nec dolor ut volutpat. Duis at ornare risus. Aliquam in finibus orci, malesuada sagittis sapien. Praesent lobortis faucibus dui.</p>
                            </div>
                            <div className="fonctionnality_items_comments last">
                                <p className>Etoiles</p>
                                <p className="review_name">Jean</p>
                                <p className="review_text">Iloc eu sodales elit. Ut ullamcorper nec dolor ut volutpat. Duis at ornare risus. Aliquam in finibus orci, malesuada sagittis sapien. Praesent lobortis faucibus dui.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="infolettre" style={{padding: '50px 30px 20px 30px', marginTop: '50px'}}>
                <div className="container">
                    <h1 style={{fontSize: '15px'}} className="infolettre_title text-center"> * Grâce à votre fiche Iloc, votre acceptation pour un logement peuvent vous être garanti selon votre score de location qui se situe entre 300 - 900! Le propriétaire détient la décision final de la sélection de votre candidature. </h1>
                </div>
            </section>

            <section id="infolettre" style={{marginTop: '50px'}}>
                <div className="container">
                    <h1 className="infolettre_title text-center"> Inscrivez-vous à l’infolettre de iloc et soyez parmi les premiers à recevoir nos dernières nouvelles et offres. </h1>
                    <p className="infolettre_text text-center"> Vous pouvez vous désabonner facilement n'importe quand. </p>
                    <br />
                    <div className="row infolettre_container">
                        <div className="col-lg-4">
                            <input className="infolettre_input" placeholder="Prénom" />
                        </div>
                        <div className="col-lg-8">
                            <input className="infolettre_input" placeholder="Courriel" />
                            <span className="infolettre_submit"><span className="hide_mobile"></span> <i style={{fontSize: '10px;'}} class="far fa-paper-plane"></i></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
  }
}

function mapStateToProps(state) {
    if (state.user)
    {
      return {
        user: state.user,
      }
    }
  }
  
export default connect (mapStateToProps)(About);
