import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga'
import rootReducer from "./reducers/index";
import {watchSagas} from './sagas/sagas'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const saveToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(e) {
        
        console.error(e, state);
    }
}

const loadFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (!serializedState) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();

const store = 
createStore(
    rootReducer,
    persistedState,
    composeEnhancer(applyMiddleware(sagaMiddleware)),
    );

sagaMiddleware.run(watchSagas);

store.subscribe(()=> saveToLocalStorage(store.getState()))

export default store;