import React, { Component } from 'react';
import firebase from 'firebase';
import fire from '../js/fire';

import { NavLink } from 'react-router-dom';

var database = firebase.database();

class PanierLocataire extends Component {
    constructor(props) 
    {
        super(props);
        this.state = {
            apparts:[ ]
        }
    }

    componentWillMount() {

        const user= this.props.user;
        console.log(this.props);
        const db = firebase.firestore();
        if (user){
            console.log("INPANIER",user);
            console.log(user.uid);
            db.collection('carts').doc(user.uid).get().then(doc => {
                console.log(doc.data());
                doc.data().liked.map((appart,index) =>
                    this.state.apparts.push(appart)
                )
                this.forceUpdate();
            })
            // var appartments = this.state.appartments;
            // cart.once('value').then(this.receiveSnapshot.bind(this));
        }
        
        
      }
    
    
    receiveSnapshot(iAppartSnap) {

        var appartments= [];
        iAppartSnap.forEach((child)=> {
            // console.log(child.val());
            var appartmentValue = child.val();
            appartments.push({
                img: appartmentValue.img
            });
        } 
            //snapshot.val().map((value => console.log(value)));
        )
        this.setState({apparts:appartments});
        console.log("RECEIVED");
        console.log(this.state.apparts);

    }

  render() {
    var appartments;
    if (this.state.apparts)
    {
    var appartments = this.state.apparts.slice(0);
    console.log(appartments);
    //  this.state.apparts.map((value)=> console.log("IN RENDER",value));
    
      var appartmentsToRender = this.state.apparts.map((value, index)=> {
        return(
            <div className="col-lg-6 col-md-6 col-12" key={index}>
                <NavLink to="#" >
                    <div className="notification_container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                                <img className="img_favoris_proprietaire" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <p className="name_favoris">Bel appartement de la Rive-Sud</p>
                                <p className="name_favoris">Adresse</p>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
            )
        });
    }

    return (
        <div>
            <header id="notification_header">
                <div className="container">
                    <h1 className="critere_title"> Vos favoris</h1>
                </div>
            </header>

            <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                <div className="all_notification_continer">
                    <div className="row">
                        {appartmentsToRender}
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default PanierLocataire;