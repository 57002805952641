import React, { Component } from 'react';
import firebase from 'firebase';
import {connect} from 'react-redux';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { runInThisContext } from 'vm';

const firestore = firebase.firestore();
const functions = firebase.functions();
const getUser = functions.httpsCallable('getUserInfo');
class FormReference extends Component {
  state = {
    critereProp1: 0,
    critereProp2: 0,
    critereProp3: 0,
    critereProp4: 0,
    proprio: {},
    sendState: "before",
    appartData: {}
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    firestore.collection('prop_loc').where('loc','==', this.props.user.uid).get()
    .then(snap =>{
      if (!snap.empty)
      {
        snap.docs.forEach(propLocRef => {
          const  propLocData = propLocRef.data();
          //getproprio
          getUser(propLocData.prop).then(data => {
            this.setState({proprio: data.data})
          })
          
          console.log("appart", propLocData.locId);
          firestore.collection('apparts').doc(propLocData.locId).get().then(appartRef => {
            const appartData = appartRef.data()
            this.setState({appartData: appartData.d})
  
          })
        })
        //get loc

      }
    })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  sendEval = ()=> {
    this.setState({
      sendState: "sending"
    })
    const ratings = {
      critereProp1: this.state.critereProp1,
      critereProp2: this.state.critereProp2,
      critereProp3: this.state.critereProp3,
      critereProp4: this.state.critereProp4,
    }
    const data_to_send = {
      from : this.props.user.uid,
      to: this.state.proprio.uid,
      ratings,
      type: "prop"
    }
    const sendEval = functions.httpsCallable('receiveEval');
    sendEval(data_to_send)
    .then((message)=>{
      console.log(message);
      this.setState({
        sendState: "sent"
      })
    })
  }

  render() {

    $(function () {
      $('[data-toggle="popover"]').popover()
    })

    let modalButton =
    <Button onClick={this.sendEval} variant="contained" component="span" color="primary">    
    Évaluer 
   </Button> ;
    if (this.state.sendState == "sending")
    {
      modalButton = <CircularProgress/>
    }
    else if (this.state.sendState == "sent")
    {
      modalButton = <Button variant="contained" component="span" color="primary" data-dismiss="modal" aria-label="Close">    
      Fermer 
    </Button>
    }
    return (
      <div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12">
            <img className="img_favoris_proprietaire" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" />
          </div>
          <div className="col-lg-9 col-md-8 col-12">
            <p className="data_content">{this.state.proprio.displayName}</p>
            <p className="name_favoris">Adresse: {this.state.appartData.adresse}</p>
          </div>
          <div className="col-12 text-right">
            <hr />
            <br />
            <span data-toggle="modal" data-target="#addEvaluation" className="primary_btn">Évaluer</span>
          </div>
        </div>

        <div class="modal fade" id="addEvaluation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Laissez une évaluation</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" style={{color: 'black'}}>×</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label="Critère 1 (Communication)"
                      value={this.state.critereProp1}
                      onChange={this.handleChange('critereProp1')}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />                    
                    <hr />
                  </div>

                   <div className="col-12">
                    <TextField
                      label="Critère 2 (État de l'appartement)"
                      value={this.state.critereProp2}
                      onChange={this.handleChange('critereProp2')}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />     
                    <hr />
                  </div>

                   <div className="col-12">
                   <TextField
                      label="Critère 3 (Respect du contrat)"
                      value={this.state.critereProp3}
                      onChange={this.handleChange('critereProp3')}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />     
                    <hr />
                  </div>
                  <div className="col-12">
                   <TextField
                      label="Critère 4 (Disponibilité)"
                      value={this.state.critereProp4}
                      onChange={this.handleChange('critereProp4')}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />     
                    <hr />
                  </div>

                   <div className="col-12">
                    <h4 className="titre-critere">Laissez votre commentaire</h4>
                      <textarea rows="4" id="description" className="form-control" placeholder=""></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              {modalButton}
            </div>
            </div>
          </div>
        </div>
    </div>
    )
  }
}


class FormLocataire extends Component {
  constructor() {
    super();
    this.state = {
    }
  }
  disconnect() {
    firebase.auth().signOut();
  }

  render() {
    return (
      <div>
        <header id="notification_header">
          <div className="container" style={{ zIndex: '500' }}>
            <h1 className="critere_title"> Évaluation de mon logement et propriétaire</h1>
          </div>
        </header>

        <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
          <div className="all_notification_continer">
            <div className="row">
              <div className="col-12">
                <div className="notification_container">
                  <FormReference user={this.props.user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class ReviewLocataire extends Component {
  constructor() {
    super();
    this.state = {
      // typeUser: "",
    }
  }

  componentDidMount() {
    const user= this.props.user;
    if (user){
      this.setState({
      });
    }
  }
  render() {
    return (
        <div>    
           <FormLocataire user={this.props.user} />
        </div>
    )
  }
}
function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps)(ReviewLocataire);