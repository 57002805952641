import React, { Component } from 'react';
import firebase from 'firebase';
import { Result } from 'range-parser';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { onRefreshNotifs } from '../redux/actions/index';

import CheckoutForm from './paiement/CheckoutForm'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from 'reactstrap';
import { withRouter } from 'react-router-dom';

import Loading from './loading';

const firestore = firebase.firestore();
const functions = firebase.functions();
class NotificationItem extends Component {
    constructor(props){
        super(props);
    }
    
    state = {
        formattedTime : "",
        dialogPay: false,
        redirectTo: "",
       
        
    }

    componentDidMount(){
        // console.log("TIME:" + new Date(this.props.time.seconds*1000));
        var mSeconds =  Date.now() - new Date(this.props.time.seconds * 1000);
        var formattedTime = "unknown time";
        var seconds = Math.floor(mSeconds / 1000);
        var minutes = Math.floor(seconds / 60);
        var heures = Math.floor(minutes / 60);
        var jours = Math.floor(heures / 24);
        // console.log(`${seconds} sec ${minutes}min ${heures}h ${jours}j`)
        if (jours > 0)
        {
            var formattedTime = jours + ' j';
        }
        else if (heures > 0)
        {
            var formattedTime = heures + ' h';
        } 
        else if (minutes > 0)
        {
            var formattedTime = minutes + ' min';
        } 
        else if (seconds > 0)
        {
            var formattedTime = seconds + ' s';
        } 

        // Minutes part from the timestamp
        // Seconds part from the timestamp

        this.setState({
            formattedTime
        })

        switch (this.props.message) {
            case "REQUEST_BE_ILOC":
            case "APPROVED_BE_ILOC":         
            case "REJECTED_BE_ILOC":
                firestore.collection('apparts').doc(this.props.params.locId).get().then(appartRef =>{
                    const appartData = appartRef.data();
                    console.log('appartData', appartData)
                    this.setState({
                        address : appartData.d.adresse,
                        locId: this.props.params.locId
                    })
                })
                break;
        }

    }

    changeStatusRequest = (requestId, newStatus) => {
        // console.log("changing", requestId);
        let firestoreDocument = this.props.type === 'loc' ?  'requests_iloc_loc' : 'requests_iloc_prop';
        console.log("firestore document", firestoreDocument);
        firestore.collection(firestoreDocument).doc(requestId).update({
            status: newStatus
        })
    }

    changeBeIlocStatusRequest = (requestId, newStatus) => {
        firestore.collection('requests_be_iloc').doc(requestId).update({
            status: newStatus,
            locId: this.props.params.locId
        })
    }


    successCallback = () => {
        this.setState({dialogPay:false});
        console.log("success")
    }

    render() {
        // console.log("HISTORY", this.props.history);
        const paiementForm = 
        <CheckoutForm closed={()=>{this.setState({dialogPay:false})}} history={this.props.history} redirectTo={this.state.redirectTo} open={this.state.dialogPay} successCallback={this.successCallback}/>

        var message;
        var buttons;
        // console.log("MESSAGE", this.props);
        switch (this.props.message) {
            case "PROPRIO_IN_ZONE":
                message = <span> a inclus votre propriété dans sa zone de recherche </span>
                buttons =
                    <React.Fragment>
                        <NavLink to={"/profilLoc/"+ this.props.from.uid } className="btn success_btn" style={{ marginTop: '5px' }} > Voir </NavLink> &nbsp;
                        <span onClick={() => this.props.click(this.props.id)} style={{ marginTop: '5px' }} className="btn danger_btn">Supprimer</span>
                    </React.Fragment>
                break;
            case "REQUEST_ILOC_LOC":
            case "REQUEST_ILOC_PROP":
                message = <span> veut accéder à votre fiche ILOC </span>
                buttons =
                    <React.Fragment>
                        <span onClick={() => { this.changeStatusRequest(this.props.requestId, "APPROVED"); this.props.click(this.props.id) }} style={{ marginTop: '5px' }} className="btn success_btn">Accepter</span> &nbsp;
                        <span onClick={() => { this.changeStatusRequest(this.props.requestId, "REJECTED"); this.props.click(this.props.id) }} style={{ marginTop: '5px' }} className="btn danger_btn">Refuser</span>
                    </React.Fragment>
                break;
            case "REJECTED_ILOC_LOC":
            case "REJECTED_ILOC_PROP":
                message = <span> a rejeté votre demande de fiche ILOC  </span>
                buttons =
                    <React.Fragment>
                        <span onClick={() => this.props.click(this.props.id)} style={{ marginTop: '5px' }} className="btn danger_btn">Supprimer</span>
                    </React.Fragment>
                break;
            case "APPROVED_ILOC_LOC":
            case "APPROVED_ILOC_PROP":
                message = <span> a accépté votre demande de fiche ILOC </span>
                buttons =
                    <React.Fragment>
                         {/* <NavLink to={"/ficheIlocLoc/"+ this.props.from.uid}  className="btn success_btn" style={{ marginTop: '5px' }} > Voir </NavLink>  */}
                         <Button onClick={()=>this.setState({dialogPay:true, redirectTo: "/ficheIlocLoc/"+ this.props.from.uid })}  className="btn success_btn" style={{ marginTop: '5px' }} > Voir </Button> &nbsp;
                         <span onClick={() => this.props.click(this.props.id)} style={{ marginTop: '5px' }} className="btn danger_btn">Supprimer</span>
                    </React.Fragment>
            break;          
            case "REQUEST_BE_ILOC":      
                message = <span> a initié un ILOC pour l'appartement <a href={"profilAppart/" + this.state.locId}><b>{this.state.address}</b></a>  </span>
                buttons =
                    <React.Fragment>
                         {/* <NavLink to={"/ficheIlocLoc/"+ this.props.from.uid}  className="btn success_btn" style={{ marginTop: '5px' }} > Voir </NavLink>  */}
                         <span onClick={() => { this.changeBeIlocStatusRequest(this.props.requestId, "APPROVED"); this.props.click(this.props.id) }} style={{ marginTop: '5px' }} className="btn success_btn">Accepter</span> &nbsp;
                         <span onClick={() => { this.changeBeIlocStatusRequest(this.props.requestId, "REJECTED"); this.props.click(this.props.id) }} style={{ marginTop: '5px' }} className="btn danger_btn">Refuser</span>
                    </React.Fragment>
                break;                
            case "APPROVED_BE_ILOC":   
                message = <span> a approuvé votre ILOC request pour l'appartement <a href={"profilAppart/" + this.state.locId}><b>{this.state.address}</b></a>   </span>
                buttons = <span onClick={() => this.props.click(this.props.id)} style={{ marginTop: '5px' }} className="btn danger_btn">Supprimer</span>;
                break;
            case "REJECTED_BE_ILOC":
                message = <span> a refusé votre ILOC request pour l'appartement <a href={"profilAppart/" + this.state.locId}><b>{this.state.address}</b></a>   </span>;
                buttons = <span onClick={() => this.props.click(this.props.id)} style={{ marginTop: '5px' }} className="btn danger_btn">Supprimer</span>;
                break;
            default:
                message = <span> unknown message {this.props.message} </span>
                break;
        }
        console.log(this.props);
        return (
            <div className="notification_container">
                  {paiementForm}
                <span><b>{this.props.from.displayName}</b></span>
                {message}
                <br />
                <span style={{ fontSize: '14px', color: '#888' }}>{this.state.formattedTime}</span>
                <br />
                {buttons}
            </div>
        )
    }
}

class Notification extends Component {

    state = {
        notifications: [],
        loading: true
    }

    deleteNotification = (notificationId) => {
        var notificationFiltered = this.state.notifications.filter(element => element.id !== notificationId);
        let firestoreDocument = this.props.user.type === 'loc' ?  'notifications_loc' : 'notifications_prop';
        console.log(`firestoredocument ${firestoreDocument}`);
        firestore.collection(firestoreDocument).doc(notificationId).delete()
        .then(() =>{
            console.log("notification deleted");
            this.props.onRefreshNotifs();
        }
        ).catch((error) => console.error(error));

        this.setState({
            notifications: notificationFiltered,
           
        })
    }


    componentDidMount() {
        const uid = this.props.user.uid;
        const type = this.props.user.type;
        let firestoreDocument = type === 'loc' ?  'notifications_loc' : 'notifications_prop';
        console.log("Document", this.props.user.type); 

        const getUser = functions.httpsCallable('getUserInfo');
        //const uid = firebase.auth().currentUser.uid;
        // console.log("USER IN NOTIFICATION", firebase.auth().currentUser);

        let userRecordMap = {};

        firestore.collection(firestoreDocument).where("to", "==", uid).orderBy("time").get()
            .then(notifications => {
                if (notifications.size == 0) {
                    this.setState ({
                        no_notification: true,
                    })

                    return;
                }
                notifications.forEach(notification => {
                    const data = notification.data();
                    console.log('data', data);
                    var message = data.message;

                    let requestId;
                    if (data.requestId) {
                        requestId = data.requestId
                    }

                    // console.log("DATA", data);
                    const senderUid = data.from;
                    let userRecord;
                    const timestamp = data.time;
                    console.log(data);
                    //BAd BAD Bad
                    if (userRecordMap[senderUid]) {
                        userRecord = userRecordMap[senderUid];
                        // const diplayName = userRecord.diplayName;
                        const notificationsRef = this.state.notifications;
                        notificationsRef.push({
                            id: notification.id,
                            from: userRecord.displayName,
                            uid: userRecord.uid,
                            message: message,
                            requestId: requestId,
                            time: timestamp,
                            params: data.params
                        })


                        this.setState({
                            notifications: notificationsRef
                        });
                    }
                    else {
                        getUser(senderUid).then(result => {
                            userRecord = result.data;
                            const diplayName = userRecord.diplayName;
                            const notificationsRef = this.state.notifications;
                            notificationsRef.push({
                                id: notification.id,
                                from: userRecord,
                                uid: userRecord.uid,
                                message: message,
                                requestId: requestId,
                                time: timestamp,
                                params: data.params
                            })
                            this.setState({
                                notifications: notificationsRef
                            });

                            userRecordMap[senderUid] = userRecord;
                        })
                    }
                    this.setState(
                        {loading:false}
                    )
                })
            })
    }

    render() {
        if (this.state.no_notification) {
            return (
                <div>
                    <header id="notification_header">
                        <div className="container" style={{ zIndex: '500' }}>
                            <h1 className="critere_title"> Vos alertes</h1>
                        </div>
                    </header>
                    <div className="row no_notification">
                        <div className="col-12 text-center">
                            <h1>Aucune notification</h1>
                        </div>
                    </div>
                </div>
            )
        }

        else if(this.state.loading)
        {
            return (
                <div>
                    <header id="notification_header">
                        <div className="container" style={{ zIndex: '500' }}>
                            <h1 className="critere_title"> Vos alertes</h1>
                        </div>
                    </header>
    
                    <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <Loading />
                                <h1>Chargement des notifications</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            const notifications = this.state.notifications.map(notification => {
                console.log("INMAPE", notification);
                return <NotificationItem params={notification.params} history={this.props.history} type={this.props.user.type} requestId={notification.requestId} message={notification.message} id={notification.id} key={notification.id} click={this.deleteNotification} displayName={notification.from.diplayName} time={notification.time} from={notification.from} />
            })

            return (                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                <div>
                    <header id="notification_header">
                        <div className="container" style={{ zIndex: '500' }}>
                            <h1 className="critere_title"> Vos alertes</h1>
                        </div>
                    </header>

                    <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="all_notification_continer">
                                    {notifications}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    // console.log('in redux', state);
    if (state.user)
    {
      return {
        user: {...state.user},
      }
    }
  }


  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ onRefreshNotifs }, dispatch);
  }

  
  export default connect (mapStateToProps, mapDispatchToProps)(Notification);
