import React, { Component } from 'react';
import PanierLocataire from './favoris_locataire';
import PanierProprietaire from './favoris_proprietaire';
import {connect} from 'react-redux';
class Panier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeUser: "",
    }
  }

  componentDidMount() {
    
  }
  

  render() {
    var pageToLoad, typeUtilisateur;
    typeUtilisateur = this.props.user.type;

    if (typeUtilisateur == "loc")  {
      pageToLoad = <PanierLocataire {...this.props}/>;
    } else {
      pageToLoad = <PanierProprietaire  {...this.props}/>;
    }
    return (
        <div>     
           {pageToLoad}
        </div>
    )
  }
}
function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps) (Panier);
