import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { NavLink } from 'react-router-dom';
import { UserBindingInstance } from 'twilio/lib/rest/ipMessaging/v2/service/user/userBinding';

const firestore = firebase.firestore();

class FormulaireEmploie extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            referent_first_name: "",
            referent_last_name: "",
            referent_poste: "",
            referent_email: "",
            referent_phone: "",
            name_entreprise: "",
            name_employe: "",
            date_embauche: "",
            poste: "",
            salaire: "",
            is_temporaire: false,
            is_permanent: false,
        }
    }

    componentDidMount() {
        firestore.collection('professionnal_info_locataires').doc(this.props.user.uid).get()
            .then(userDataSnapshot => {
            var userData = userDataSnapshot.data();
            console.log(userData);
            if (userData) {
                this.setState({
                    referent_first_name: userData.last_name,
                    referent_last_name: userData.first_name,
                    referent_poste: userData.referent_poste,
                    referent_email: userData.referent_email,
                    referent_phone: userData.referent_phone,
                    name_entreprise: userData.name_entreprise,
                    name_employe: userData.name_employe,
                    date_embauche: userData.date_embauche,
                    poste: userData.poste,
                    salaire: userData.salaire,
                    is_temporaire: userData.is_temporaire,
                    is_permanent: userData.is_permanent,
                })

                $("#referent_first_name").val(userData.referent_first_name);
                $('#referent_last_name').val(userData.referent_last_name);
                $('#referent_poste').val(userData.referent_poste);
                $('#referent_email').val(userData.referent_email);
                $('#referent_phone').val(userData.referent_phone);
                $('#name_entreprise').val(userData.name_entreprise);
                $('#name_employe').val(userData.name_employe);
                $('#date_embauche').val(userData.date_embauche);
                $('#poste').text(userData.poste);
                $('#salaire').val(userData.salaire);
            }
        })
    }


    handleClick(e) {
        e.preventDefault();
        console.log('saving firebase');

        var referent_first_name = $("#referent_first_name").val();
        var referent_last_name = $('#referent_last_name').val();
        var referent_poste = $('#referent_poste').val();
        var referent_email = $('#referent_email').val();
        var referent_phone = $('#referent_phone').val();
        var name_entreprise = $('#name_entreprise').val();
        var name_employe = $('#name_employe').val();
        var date_embauche = $('#date_embauche').val();
        var poste = $('#poste').val();
        var salaire = $('#salaire').val();

        var is_temporaire = $('#is_temporaire').prop('checked');
        var is_permanent = $('#is_permanent').prop('checked');

        const new_professionnal_info =
        {
            referent_first_name: referent_first_name,
            referent_last_name: referent_last_name,
            referent_poste: referent_poste,
            referent_email: referent_email,
            referent_phone: referent_phone,
            name_entreprise: name_entreprise,
            name_employe: name_employe,
            date_embauche: date_embauche,
            date_embauche: date_embauche,
            poste: poste,
            salaire: salaire,
            is_temporaire: is_temporaire,
            is_permanent: is_permanent
        }
        console.log(new_professionnal_info);
        firestore.collection('professionnal_info_locataires').doc(this.props.user.uid).set(new_professionnal_info);
    }

    render() {
        return (
            <div>
                <header id="notification_header">
                    <div className="container">
                        <h1 className="critere_title"> Renseignements professionnels</h1>
                    </div>
                </header>

                <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="all_notification_continer">
                                <div className="notification_container">
                                    <div className="row text-center">
                                        <form onSubmit={this.handleClick} style={{display: 'contents'}}>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="referent_first_name" placeholder="Nom du référent" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="referent_last_name" placeholder="Prénom du référent"required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="referent_poste" placeholder="Poste du référent" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="referent_email" placeholder="Courriel" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="tel" id="referent_phone" placeholder="Numéro de téléphone" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="name_entreprise" placeholder="Nom de l'entreprise" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="name_employe" placeholder="Nom de l'employé" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="date" id="date_embauche" placeholder="Date d'embauche" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="poste" placeholder="Poste (Occupation)" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <input className="form-control form_input" type="text" id="salaire" placeholder="Tranche de salaire" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6 text-left">
                                                <div className="form-group">
                                                    <p id="info">Type de contrat</p>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <input type="radio" id="is_temporaire" name="sfamilliale" required />
                                                            <span> Temporaire</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <input type="radio" id="is_permanent" name="sfamilliale" required />
                                                            <span> Permanent</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12"> 
                                                <hr/>
                                            </div>
                                            <div className="col-12 text-left">
                                                <h4>Upload attestation de travail</h4>
                                                <div className="form-group">
                                                    <p id="info">Documents (PDF, ...)</p>
                                                    <input type="file" accept=".jpg,.jpeg.,.png,.pdf" />
                                                </div>
                                            </div>
                                        

                                            <div className="col-lg-12 col-md-12 col-12 text-right" style={{marginTop: '15px'}}>
                                                <button className="primary_btn" type="submit"> Enregistrer </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
  }
}

function mapStateToProps(state) {
    if (state.user) {
      return {
        user: state.user,
      }
    }
  }
  
export default connect(mapStateToProps)(FormulaireEmploie);
  
