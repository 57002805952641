import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase';
import fire from '../js/fire';
import $ from 'jquery';
import Chat from 'twilio-chat'
import '../style/chat.css'
import {connect} from 'react-redux';

import Navbar from './navbar';

class MessageListItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                 <NavLink to="/message">
                <img className="lists-img" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg" />
                <p id="lists-username"><span>{this.props.statut}</span> - <span> {this.props.username}</span></p>
                <p id="duree-username"><span>{this.props.duree}</span></p>
                
                <p id="lists-message">{this.props.message} </p>
                < hr/>
                </NavLink>
            </div>
        )
    }
}

class MessageLists extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Navbar />
            <header id="notification_header">
                <div className="container" style={{ zIndex: '500' }}>
                    <h1 className="critere_title"> Messages</h1>
                </div>
            </header>

            <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="all_notification_continer">
                            <section id="section-lists">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-9">
                                            <p>Vous avez <span id="nbMsgNonLu">1</span> messages non lus</p>
                                        </div>
                                        <div className="col-3 text-right col-details">
                                            {/* <span style ={{display: 'none'}}id="details-nav">Details</span> */}
                                        </div>
                                    </div>
                                    <div>
                                        < hr style={{ width: '50%' }} />
                                        <MessageListItem username={"Phanuel"} statut={"Confirmé"} duree={"1w ago"} message={"Bonjour, ..."} />
                                        <MessageListItem username={"Elyeser"} statut={"Annulé"} duree={"2d ago"} message={"Bonsoir, ..."} />
                                        <MessageListItem username={"Moussa"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonjour, ..."} />
                                        <MessageListItem username={"Babs"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
                                        <MessageListItem username={"Sophie"} statut={"Annulé"} duree={"Il y a une semaine"} message={"Bonjour, ..."} />
                                        <MessageListItem username={"Marc"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
                                        <MessageListItem username={"Rick"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
                                        <MessageListItem username={"Joannie"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            // <div>
            //     <Navbar />
            //     <section id="section-lists">
            //         <div className="container">
            //             <div className="row">
            //                 <div className="col-9">
            //                     <p id="username-nav-lists"><span id="messageTitle">Messages</span></p>
            //                     <p>Vous avez <span id="nbMsgNonLu">1</span> messages non lus</p>
            //                 </div>
            //                 <div className="col-3 text-right col-details">
            //                     {/* <span style ={{display: 'none'}}id="details-nav">Details</span> */}
            //                 </div>
            //             </div>
            //             <div>
            //             < hr style={{width: '50%'}}/>
            //                 <MessageListItem username={"Phanuel"} statut={"Confirmé"} duree={"1w ago"} message={"Bonjour, ..."} />
            //                 <MessageListItem username={"Elyeser"} statut={"Annulé"} duree={"2d ago"} message={"Bonsoir, ..."} />
            //                 <MessageListItem username={"Moussa"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonjour, ..."} />
            //                 <MessageListItem username={"Babs"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
            //                 <MessageListItem username={"Sophie"} statut={"Annulé"} duree={"Il y a une semaine"} message={"Bonjour, ..."} />
            //                 <MessageListItem username={"Marc"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
            //                 <MessageListItem username={"Rick"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
            //                 <MessageListItem username={"Joannie"} statut={"Confirmé"} duree={"Il y a une semaine"} message={"Bonsoir, ..."} />
            //             </div>
            //         </div>
            //     </section>
            // </div>
        )
    }
}
export default MessageLists;