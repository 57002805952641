import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import firebase from 'firebase';
import {onUserTypeChange} from '../redux/actions'
import { bindActionCreators} from 'redux';
import 'bootstrap';
import $ from 'jquery';

class ChoixProfil extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(type) {
   
    var db = firebase.firestore();
    
    if (!this.props.user.logged)
    {
      this.props.history.push('/login');
      $("#ChixProfil").modal('hide');
    }
    else{
  
        if (type === "prop")
        {
          console.log('changing to proprio');
          db.collection('users').doc(this.props.user.uid).set({
            user_type: 'prop'
          }, {merge:true});
          this.props.onUserTypeChange('proprio');
         
        }
        else if (type === "loc")
        {
          console.log('changing to loc');
          db.collection('users').doc(this.props.user.uid).set({
            user_type: 'loc'
          }, {merge:true});
          this.props.onUserTypeChange('loc');
          
        }
        $("#ChixProfil").modal('hide');
        this.props.history.push('/settings');
      
    }
  }

     
  showModal() {
    $('#ChixProfil').modal('show')  
  } 

  hideModal() {
    $('#ChixProfil').modal('hide') 
    // this.props.history.push('/geolocalisation'); 
  } 

  render() {
    return (
      <div>
        <div className="modal_fab" onClick={this.showModal.bind(this)}> Choisir mon profil </div>
        <div className="modal fade" id="ChixProfil" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="choix_modal_title" id="exampleModalLongTitle">Veuillez choisir un profil</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="close_modal">×</span>
              </button>
            </div>
            <div className="modal-body" style={{paddingBottom: '0px'}}>
              <div className="row">
                <div id="proprietaire_profil" className="col-lg-6 col-md-12 col-12 text-center typeContainer dismissModal">
                    <div id="proprio" style={{cursor: 'pointer'}} onClick={()=>this.handleClick("prop")}>
                      <img id="proprio" onClick={this.handleClick}  style={{width: '200px'}} src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fprofil_proprietaire.svg?alt=media&token=fbded369-6701-4917-91c8-936f86106139" />
                    </div>
                    <div id="proprio" onClick={this.handleClick} style={{cursor: 'pointer'}} className="profil_choice_container">
                      <span className="im_locataire"> Je suis propriétaire</span>
                    </div>
                </div>
                <div id="locataire_profil" className="col-lg-6 col-md-12 col-12 text-center typeContainer dismissModal">
                    <div id="locataire" style={{cursor: 'pointer'}} onClick={()=>this.handleClick("loc")}>
                        <img onClick={this.handleClick}  style={{width: '216px'}} src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fprofil_locataire.svg?alt=media&token=6c6f851e-74af-4a4d-a886-e877c8bd52f2" />
                    </div>
                    <div id="locataire" onClick={this.handleClick} style={{cursor: 'pointer'}} className="profil_choice_container">
                      <span className="im_locataire">Je suis locataire</span>
                    </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              
              <NavLink to="/geolocalisation"><button type="button" className="btn btn-secondary" onClick={this.hideModal}>Fermer</button></NavLink>
            </div> */}
          </div>
        </div>
      </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
 
  if (state.user)
  {
    return {
      user: state.user,    
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserTypeChange}, dispatch);
}
export default connect (mapStateToProps, mapDispatchToProps)(ChoixProfil);

