import React, { Component } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import { withStyles } from '@material-ui/styles';
import {combineReducers, bindActionCreators} from 'redux';
import {onUserChange} from '../redux/actions/index';
import {firebaseUI} from './signup';
const firestore = firebase.firestore();
const functions = firebase.functions();
const getUser = functions.httpsCallable('getUserInfo');



const styles = {
    // root: {
    //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //   border: 0,
    //   borderRadius: 3,
    //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //   color: 'white',
    //   height: 48,
    //   padding: '0 30px',
    // },
    container :{
        display: 'flex',
        flexDirection: 'row',
        height: '250',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
    },
    card: {
        display: 'flex',
        height: 200,
        width: 500,
        margin: '10px 10px',
        // flex: '1 0 auto',
      },
      details: {
        display: 'flex',
        flexDirection: 'row',
      },
      content: {
        // flex: '1 0 auto',
      },
      cover: {
        width: 200,
        // height: '100%'
      },
      controls: {
        display: 'flex',
        alignItems: 'center',
        // paddingLeft: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
      },
      playIcon: {
        height: 38,
        width: 38,
      },    
  };


class FavorisProprietaire extends Component {

    state = {
        favorites: new Array()
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const uid = this.props.user.uid;
        firestore.collection('favorites_prop').doc(uid).get()
            .then(snap => {
                if (snap.exists) {
                    const data = snap.data();
                    data.favorites.map(uid => {
                        console.log('getting user', uid)
                        getUser(uid).then(userDataPayload => {
                            const userDatadata = userDataPayload.data;
                            console.log(userDatadata)
                            this.setState(prevState => {
                                const previousFavorites = prevState.favorites;
                                previousFavorites.push ({
                                    uid: userDatadata.uid,
                                    displayName: userDatadata.displayName,
                                    photoURL: userDatadata.photoURL
                                })
                                console.log(previousFavorites);
                                return {
                                    favorites: previousFavorites
                                }
                            })
                        })
                    })
                } else {
                    console.log('NO favorites');
                }
            })
    }    

    render() {

        const {classes} = this.props;
        console.log(this.state.favorites);

        const favorites = this.state.favorites.map((favoriteData, index) => {
           return (
               <div className="col-lg-6 col-md-6 col-12">
                   <div className="notification_container">
                       <div className="row">
                           <div className="col-lg-3 col-md-4 col-4">
                               <img className="img_favoris" src={favoriteData.photoURL || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                           </div>
                           <div className="col-lg-9 col-md-8 col-8">
                               <p className="name_favoris">{favoriteData.displayName}</p>
                               <NavLink to={"/profilLoc/"+favoriteData.uid} className="btn primary_btn">Voir profil</NavLink>
                           </div>
                           
                       </div>
                   </div>
               </div>
           )
        })

        return (
            <div>
                <header id="notification_header">
                    <div className="container">
                        <h1 className="critere_title"> Vos favoris</h1>
                    </div>
                </header>

                <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                    <div className="all_notification_continer">
                        <div className="row">
            {favorites}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(FavorisProprietaire);
