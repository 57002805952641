import React, { Component } from 'react';
import Header from './header';
import About from './about'; 


class Home extends Component {
  render() {
    return (
        <div>
            <Header />
            <About />
        </div>
    )
  }
}

export default Home;
