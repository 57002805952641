import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const firestore = firebase.firestore();
const functions = firebase.functions();

var getProfilLocataire = functions.httpsCallable('getProfilLocataire');


class ProfilLocataire extends Component {
  constructor(props) {
        super(props);
      }
          state = {
            nom: "",
            prenom: "",
            score: 20,
            picture: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",
      
            dateNaissance: "",
            email: "",
            phone: "",
            pays: "",
            ville: "",
            codePostal: "",
            situation_familliale: "",
            statut_professionnel: "",
            Animaux: "",
            onGoingRequest: false
          }
    
      componentDidMount() {
        //Si une demande de fiche a deja été faite
        const uid = this.props.match.params.uid;
        console.log("UID", uid);
        firestore.collection("requests_iloc_loc")
          .where("from", "==", this.props.user.uid)
          .where("to", "==", uid)
          .where("status", "==", "REQUEST")
          .get()
          .then(data=>{
            if (data.size > 0 )
            {
              this.setState({
                onGoingRequest: true
              })
            }
          })
    
          //Fetch les données du locatire
          getProfilLocataire(uid).then(result=>{
            const user= result.data;
            console.log("PROFIL", user);
            this.setState({uid: uid});
            if (user.profil){
                this.fillwWithData(user.profil);
                this.setState({
                    name: user.userRecord.displayName,
                    photo: user.userRecord.photoURL,

                })
            }
          })    
         }

    requestFicheLoc = (uid) => {
        console.log(this.props.user.uid);
        firestore.collection("requests_iloc_loc").add(
            {
                from: this.props.user.uid,
                to: uid,
                status: "REQUEST"
            }
        )
        this.requestSent();
    }

    requestSent = () => {
        this.setState({
            onGoingRequest: true
        })
    }

    fillwWithData = (userData) => {
      this.setState({
        ville: userData.ville,
        min_price: userData.min_price,
        max_price: userData.max_price,
        type_logement: userData.type_logement,
        has_animaux: userData.has_animaux,
        is_collocation: userData.is_collocation,
        duree: userData.duree,
        is_chauffe: userData.is_chauffe,
        is_eclaire: userData.is_eclaire,
        is_meubler: userData.is_meubler,
        has_parking: userData.has_parking,
        has_laveuse_secheuse: userData.has_laveuse_secheuse,
    })

    $("#ville_text").text(userData.ville);
    $("#prix_text").text(userData.min_price + "$ à " + userData.max_price + "$");
    $("#type_logement_text").text(userData.type_logement);
    if (userData.has_animaux === true)
    {
        $("#animaux_text").text("Oui");
    }
    else if (userData.has_animaux === false)
    {
        $("#animaux_text").text("Non");
    }

    if (userData.is_collocation === true)
    {
        $("#collocation_text").text("Oui");
    }
    else if (userData.is_collocation === false)
    {
        $("#collocation_text").text("Non");
    }

    $("#duree_text").text(userData.duree);

    if (userData.is_chauffe === true)
    {
        $("#chauffe_text").text("Oui");
    }
    else if (userData.is_chauffe === false)
    {
        $("#chauffe_text").text("Non");
    }

    if (userData.is_eclaire === true)
    {
        $("#eclaire_text").text("Oui");
    }
    else if (userData.is_eclaire === false)
    {
        $("#eclaire_text").text("Non");
    }

    if (userData.is_meubler === true)
    {
        $("#meuble_text").text("Oui");
    }
    else if (userData.is_meubler === false)
    {
        $("#meuble_text").text("Non");
    }

    if (userData.has_parking === true)
    {
        $("#parking_text").text("Oui");
    }
    else if (userData.has_parking === false)
    {
        $("#parking_text").text("Non");
    }

    if (userData.has_laveuse_secheuse === true)
    {
        $("#laveuse_secheuse_text").text("Oui");
    }
    else if (userData.has_laveuse_secheuse === false)
    {
        $("#laveuse_secheuse_text").text("Non");
    }
  }

    render() {
        return (
            <div>
                <header id="notification_header">
                    <div className="container">
                        <h1 className="critere_title"> Profil personnel </h1>
                    </div>
                </header>

                <div style={{ paddingTop: '50px'}} className="container">
                    <div className="all_notification_continer">
                        <div className="row text-center">
                            <div className="profil_container">
                                <img className="profil_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                                <p className="profil_name">{this.state.name}</p>
                                <div className="row">
                                    <div className="col-6 no-padding">
                                        <p id="looking" className="looking_statut clicked_statut">Je recherche</p>
                                    </div>
                                    <div className="col-6 no-padding">
                                        <p id="locked" className="locked_statut">J’ai trouvé</p>
                                    </div>
                                </div>
                                <br />
                                <span className="statut_title">Votre statut :</span>
                                <br />
                                <span className="current_statut">Vous êtes en recherche 🖐 </span>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <Button disabled={this.state.onGoingRequest} onClick={()=>this.requestFicheLoc(this.state.user_id)}  className="btnProfil"><i style={{color: 'green'}} class="fa fa-download lnrIconBtn" aria-hidden="true"></i>{this.props.onGoingRequest? "Demande en cours" : " Fiche "} <img className="ilocLogo" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542681974/IlocIconRadial_z9ijxj.png" /></Button>
                            <br />
                            <a>
                                <img className="fiche_icon" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fficheiloc.svg?alt=media&token=fd2f0012-f244-4799-a261-f9665548c237" />
                            </a>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="notification_container">
                                <form style={{display: 'contents'}}>
                                    <div className="row text-center">
                                        <div className="col-lg-5 col-md-12 col-12 m-t-50">
                                            <span className="profil_section_title center_element">Mes critères de recherche</span>
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-12">
                                            <div className="row align_left hide_edit">
                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Ville :</span>
                                                    <span className="data_content_profil" id="ville_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Prix :</span>
                                                    <span className="data_content_profil" id="prix_text"></span>
                                                </div>
                                                
                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Type de logement :</span>
                                                    <span className="data_content_profil" id="type_logement_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Animaux :</span>
                                                    <span className="data_content_profil" id="animaux_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Collocation :</span>
                                                    <span className="data_content_profil" id="collocation_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Durée :</span>
                                                    <span className="data_content_profil" id="duree_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                        

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Chauffé :</span>
                                                    <span className="data_content_profil" id="chauffe_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Éclairé :</span>
                                                    <span className="data_content_profil" id="eclaire_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                        

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Meublé :</span>
                                                    <span className="data_content_profil" id="meuble_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Stationnement :</span>
                                                    <span className="data_content_profil" id="parking_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                        

                                                <div className="col-lg-12 col-md-12 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Entré laveuse et sécheuse :</span>
                                                    <span className="data_content_profil" id="laveuse_secheuse_text"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    if (state.user) {
      return {
        user: state.user,
      }
    }
  }
  
export default connect(mapStateToProps)(ProfilLocataire);



































// import React, { Component } from 'react';
// import firebase from 'firebase';
// import { connect } from 'react-redux';

// import { NavLink } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import google from 'google-maps';

// const firestore = firebase.firestore();
// const functions = firebase.functions();

// var getProfilLocataire = functions.httpsCallable('getProfilLocataire');


// class Star extends Component {


//   render() {
//     return (
//       <i className="fa fa-star blue" aria-hidden="true" />
//     )
//   }
// }

// class ScoreNumber extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     let rows = [];
//     console.log(this.props.score);
//     for (let i = 0; i < this.props.score / 4; i++) {
//       rows.push(<Star key={i} />)
//     }

//     return (
//       <span> {rows} </span>
//     )
//   }
// }

// class InfoPerso extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {}
//   }

//   componentDidMount() {
    
//     firestore.collection('profils_locataires').doc(this.props.uid).get()
//     .then(doc => {
//       if (doc.exists) {
//         console.log("QUERY uid" + firebase.auth().currentUser.uid);
//         const data = doc.data();
//         console.log(data);
//         this.setState({
//           last_name: data.nom,
//           first_name: data.prenom,
//           birth_date: data.birth_date,
//           email: data.email,
//           tel: data.tel,
//           country: data.country,
//           city: data.city,
//           postal_code: data.postal_code,
//           piece: data.piece,
//           is_single: data.is_single,
//           is_relationship: data.is_relationship,
//           is_conjoint_de_fait: data.is_conjoint_de_fait,
//           is_married: data.is_married,
//           nb_kids: data.nb_kids,
//           is_student:data.is_student,
//           is_employed: data.is_employed,
//           is_contractuel: data.is_contractuel,
//           is_statutTemp: data.is_statutTemp,
//           is_tourist: data.is_tourist,
//           nb_cats: data.nb_cats,
//           nb_dogs: data.nb_dogs,
//           is_animal_others: data.is_animal_others,
//           description: data.description,
//         })
//       } else {
//         console.log('no document');
//       }
//     })
//   }

//   render() {
//     return (
//       <div className="card" id="profilRight">
//         <div className="card-header">
//           <h3 style={{ margin: '0 auto' }} className="text-center titreInfoPerso">Renseignements personnels</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row text-left">
//             <div className="col-lg-6 col-12">
//               <p><span id="infoPersoTitre"> Date de Naissance: </span> {this.state.birth_date} </p>
//               <p><span id="infoPersoTitre"> E-mail: </span> {this.state.email}  </p>
//               <p><span id="infoPersoTitre"> Téléphone: </span> {this.state.tel}  </p>
//               <p><span id="infoPersoTitre"> Pays: </span> {this.state.country}  </p>
//             </div>

//             <div className="col-lg-6 col-12">
//               <p><span id="infoPersoTitre"> Ville: </span> {this.state.city}  </p>
//               <p><span id="infoPersoTitre"> Code Postal: </span> {this.state.postal_code}  </p>
//               <p><span id="infoPersoTitre"> Situation Familliale: </span> {this.props.situation_familliale} </p>
//               <p><span id="infoPersoTitre"> Statut Civil: </span> {this.props.statut_professionnel} </p>
//               <p><span id="infoPersoTitre"> Animaux: </span> {this.props.Animaux} </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// class ProfilInfo extends Component {

//   requestFicheLoc = (uid) => {

//       console.log(this.props.user.uid);
//       firestore.collection("requests_iloc_loc").add(
//           {
//               from: this.props.user.uid,
//               to: uid,
//               status: "REQUEST"
//           }
//       )

//       this.props.requestSent();
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       statusIndex: 0
//     }
//   }
//   status = [
//     // {status:'En recherche', color:'green'},
//     // {status:'Cession/Transfert', color:'orange'},
//     // {status:'Iloc', color: 'red'}🖐 👊 ✌,
//     { status: 'En recherche', description: 'Vous recherchez un appartement', lien: '🖑', color: 'green' },
//     // { status: 'Cession/Transfert', description: 'Échanger / Transférer votre bail avec un autre locataire', lien: '👊', color: 'orange' },
//     { status: 'Iloc', description: 'Vous êtes en location', lien: '✌', color: 'red' },
//   ]

//   changeStatus(e) {
//     var db = firebase.firestore();
//     var actualIndex = this.state.statusIndex;
//     this.setState({
//       statusIndex: (this.state.statusIndex + 1) % (this.status.length)
//     });
//     actualIndex = (actualIndex + 1) % (this.status.length);
//     console.log('previous actual index', actualIndex);
//     const status = this.status[actualIndex].status;
//     if (this.props.user) {
//       db.collection('users').doc(this.props.user.uid).set({
//         status_locataire: status
//       }, { merge: true });
//       console.log('changing status_locataire ', status)
//     }
//   }

//   componentWillMount() {
//     console.log("ONGOING?", this.props.onGoingRequest)
//     var db = firebase.firestore();
//     db.collection('users').doc(this.props.user.uid).get().then((doc) => {
//       if (doc.exists) {
//         this.status.map((obj, index) => {
//           if (obj.status === doc.data().status_locataire) {
//             this.setState({
//               statusIndex: index
//             })
//           }
//         })
//       }
//     })
//   }

//   render() {
//     var color = this.status[this.state.statusIndex].color;
//     var lien = this.status[this.state.statusIndex].lien;
//     var description = this.status[this.state.statusIndex].description;
//     return (
//       <div className="row">
//         <div id="profilLeft" className="col-lg-2 col-md-12 col-sm-12">
//           <div>
//             <div className="cardInsideImage text-center" id="spaceAccount">
//               <div id="profile">
//                 <div>
//                   <img id="profilIMG" src={this.props.picture} />
//                   {/* <i class="fa fa-camera" aria-hidden="true"></i> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-1 col-0" />
//         <div className="col-lg-9 col-md-12 col-sm-12 alignCenter">
//           <span className="profilName">{this.props.prenom} {this.props.nom}</span>
//           <br />
//           <div className="scoreContainer">
//             {/* <ScoreNumber score={this.props.score} />
//           <br/>
//           <br/> */}
//             {/* <img onClick={this.changeStatus.bind(this)} id="status-image" style={{height: '40px'}} src={lien} /> */}
//             <span id="statut">Statut:</span> <span id="status-image" style={{ fontSize: '30px' }}>{lien}</span>
//             {/* <button onClick={this.changeStatus.bind(this)}  style={{backgroundColor: color}} className="statut-cercle" > </button> */}
//             <span className="text-statut">{this.status[this.state.statusIndex].status}</span>
//             <br />
//             <br />
//             <span style={{ fontSize: '18px', marginTop: '30px', color: color }}>{description}</span>
//           </div>
//           {/* <img id="starReview" src="images/stars.png" /> */}
//           {/* <h4 style={{marginBottom: '50px', color: 'white'}}>{this.props.score}</h4> */}

//            <div className="row">
//            {/* <div style={{ marginBottom: '15px' }} className="col-lg-4 col-md-6 col-12">
//               <NavLink to="/reviewlocataire"><button className="btnProfil"><span className="lnr lnr-thumbs-up lnrIconBtn" /> &nbsp; Évaluation </button></NavLink>
//               &nbsp; <img src="https://img.icons8.com/color/25/00000/help.png"></img>
//             </div> */}

//             <div style={{ marginBottom: '15px' }} className="col-lg-4 col-md-6 col-12">
//               <Button disabled={this.props.onGoingRequest} onClick={()=>this.requestFicheLoc(this.props.uid)}  className="btnProfil"><i style={{color: 'green'}} class="fa fa-download lnrIconBtn" aria-hidden="true"></i>{this.props.onGoingRequest? "Demande en cours" : " Fiche "} <img className="ilocLogo" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542681974/IlocIconRadial_z9ijxj.png" /></Button> 
//               &nbsp; <img src="https://img.icons8.com/color/25/000000/help.png"></img>
//             </div>
//             {/* <div  className="col-lg-4 col-md-6 col-12">
//               <NavLink to="/settings"><button className="btnProfil"><span className="lnr lnr-cog lnrIconBtn" /> &nbsp; Paramètres </button></NavLink>
//               &nbsp; <img src="https://img.icons8.com/color/25/000000/help.png"></img>
//             </div> */}
//           </div>

//           <br />
//           <br />
//           <InfoPerso dateNaissance={this.props.dateNaissance} email={this.props.email} phone={this.props.phone}
//             pays={this.props.pays} ville={this.props.ville} codePostal={this.props.codePostal}
//             situation_familliale={this.props.situation_familliale} statut_professionnel={this.props.statut_professionnel} Animaux={this.props.Animaux} />
//         </div>
//       </div>
//     )
//   }
// }

// class EmploiActuel extends Component {
//   render() {
//     return (
//       <div id="empActuel">
//         <h5>Emplois actuels</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//       </div>
//     )
//   }
// }

// class EmploiPrecedent extends Component {
//   render() {
//     return (
//       <div id="empPrecedent">
//         <h5>Emplois précédents</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>1ère Année</p>
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>2e Année</p>
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>3e Année</p>
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//       </div>
//     )
//   }
// }

// class InfoProf extends Component {
//   constructor() {
//     super();
//     this.state = {
//       typeUser: "empActuel",
//     }
//     this.handleClickForm = this.handleClickForm.bind(this);
//   }
//   handleClickForm(e) {
//     this.setState({ typeUser: e.target.id });
//     console.log(e.target.id);
//   }

//   render() {
//     var pageToLoad, typeUtilisateur;
//     typeUtilisateur = this.state.typeUser;

//     if (typeUtilisateur == "empActuel") {
//       pageToLoad = <EmploiActuel />;
//     } else if (typeUtilisateur == "empPrecedent") {
//       pageToLoad = <EmploiPrecedent />;
//     }

//     return (
//       <div className="card" id="profilRight">
//         <div className="card-header">
//           <h3 style={{ margin: '0 auto' }} className="text-left titreInfoPerso">Renseignements professionnels</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row">
//             <div style={{ margin: '15px 0' }} className="col-12">
//               <span onClick={this.handleClickForm} className="profil-option" id="empActuel">Emp. actuels</span> &nbsp; &nbsp;
//                 <span onClick={this.handleClickForm} className="profil-option" id="empPrecedent">Emp. précédents</span>
//             </div>
//           </div>
//           <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)', width: '100%' }} />
//           <div className="row text-left">
//             <div className="col-12">
//               {pageToLoad}
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }



// class ProfilLocataire extends Component {

//   constructor(props) {
//     super(props);
//   }
//       state = {
//         nom: "",
//         prenom: "",
//         score: 20,
//         picture: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",
  
//         dateNaissance: "",
//         email: "",
//         phone: "",
//         pays: "",
//         ville: "",
//         codePostal: "",
//         situation_familliale: "",
//         statut_professionnel: "",
//         Animaux: "",
//         onGoingRequest: false
//       }

//   componentDidMount() {
//     const uid = this.props.match.params.uid;

//     firestore.collection("requests_iloc_loc")
//       .where("from", "==", this.props.user.uid)
//       .where("to", "==", uid)
//       .where("status", "==", "REQUEST")
//       .get()
//       .then(data=>{
//         if (data.size > 0 )
//         {
//           this.setState({
//             onGoingRequest: true
//           })
//         }
//       })

      
//       getProfilLocataire(uid).then(result=>{
//         const user= result.data;
//         console.log("PROFIL", uid);
//         this.setState({uid: uid});
//         if (user.profil){
//           this.setState({
//             email: user.profil.email,
//             picture: user.userRecord.photoURL,
//             nom: user.userRecord.displayName,
//             score: 20,
//             codePostal: user.profil.postal_code,
//             dateNaissance: user.profil.birth_date,
//             pays: user.profil.country,
//             ville: user.profil.city
//           });
//         }
//       })    
//     }
    
//     requestSent = () => {
//       this.setState({
//         onGoingRequest: true
//       })
//     }
    
//     render() {
//       return (
//         <section className="MargeTop" id="accountInfo">
//         <div className="container">
//           <div className="margeProfil">
//             <ProfilInfo requestSent={this.requestSent} onGoingRequest={this.state.onGoingRequest} uid={this.state.uid} user={this.props.user} nom={this.state.nom} prenom={this.state.prenom} score={this.state.score} picture={this.state.picture}
//               dateNaissance={this.state.dateNaissance} email={this.state.email} phone={this.state.phone}
//               pays={this.state.pays} ville={this.state.ville} codePostal={this.state.codePostal}
//               situation_familliale={this.state.situation_familliale} statut_professionnel={this.state.statut_professionnel} Animaux={this.state.Animaux} />
//           </div>

//           <div className="row">
//             <div className="col-lg-6 col-12">
//               <HistoriqueLoc />
//             </div>

//             <div className="col-lg-6 col-12">
//               <HistoriqueLoc />
//             </div>
//           </div>
         
//           {/* <Hobbies /> */}

//           {/* <div><InfoProf /></div>
        
//         <RefLocatives /> */}
//           {/* <MapProfil /> */}
//         </div>
//       </section>
//     )
//   }
// }

// function mapStateToProps(state) {
//   if (state.user) {
//     return {
//       user: state.user,
//     }
//   }
// }

// export default connect(mapStateToProps)(ProfilLocataire);