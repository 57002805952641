import React, { Component } from 'react';
import {connect} from 'react-redux';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import fire from '../js/fire'
import {combineReducers, bindActionCreators} from 'redux';
import {onUserChange} from '../redux/actions/index';
import {firebaseUI} from './signup';
import $ from 'jquery';

class Login extends Component {

  uiConfig= {
    callbacks:{
      uiShown: function() {
        // The widget is rendered.
        // Hide the loader.
        // document.getElementById('loader').style.display = 'none';
      },
      signInFailure: (error) => {
        console.log("EROR LOGIN");
        console.log(error);
      }
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
       firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
      
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  
      
     
      // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ]
  };  

  constructor(props){
    super(props);
    this.state = {
      from:{},
      type_proprietaire: "hide",
    }
    this.handleClickGetId = this.handleClickGetId.bind(this);
  }


  componentDidMount() {
    if (this.state.from)
    {
      this.uiConfig.callbacks.signInSuccessUrl = this.state.from.pathname;
    }
    console.log("FRROM", this.uiConfig.callbacks.signInSuccessUrl);
    firebaseUI.start('#firebaseui-auth-container', this.uiConfig);

  }

  handleClickGetId(e) {
    this.setState({ type_proprietaire: e.target.id });
  }

  render() {
    var pageToLoad, type_proprietaire;
    type_proprietaire = this.state.type_proprietaire;

    if (type_proprietaire == "show") {
      $("#show").hide();
      $("#hide").show();
      $("#password_login").attr('type', 'text');
    } else if (type_proprietaire == "hide") {
      $("#show").show();
      $("#hide").hide();
      $("#password_login").attr('type', 'password');
    }
    return (
      <header id="signup_header">
        <div className="container">
          <div className="signup_container">
            <h1 className="signup_title"> Connexion à Iloc</h1>
            <input className="form-control signup_input" type="text" placeholder="Courriel ou nom d'utilisateur" />
            <br />
            <input id="password_login" className="form-control signup_input" type="password" placeholder="Mot de passe" />
            <i onClick={this.handleClickGetId} id="show" className="fas fa-eye see_password"></i> <i onClick={this.handleClickGetId} id="hide" style={{display: 'none'}} className="fas fa-eye-slash see_password"></i>
            <div className="row">
              <div className="col-6">
                <p id="connexion" style={{ cursor: 'default' }}><input type="checkbox" /> Rester connecté </p>
              </div>
              <div className="col-6">
                <p id="passeoublie" style={{ cursor: 'default' }}> Oublié mot de passe </p>
              </div>
            </div>
            <br />
            <span className="primary_btn mobile_login">Se connecter</span>
            <br />
            <br />
            <div id="firebaseui-auth-container"> </div>
            {/* <p className="signup_plugin"><img className="signup_icon" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1558634491/enveloppe_pln4iq.svg" /> Se connecter avec votre courriel</p>
            <p className="signup_plugin"><img className="signup_icon" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1558642565/telephone_hpgkyv.svg" /> Se connecter avec son téléphone</p> */}
            <br />
            <a href="/signup" className="switch_signup_login text-center">M'inscrire</a>
            <br />
          </div>
        </div>
      </header>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserChange}, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(Login);
