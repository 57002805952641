// import firebase from 'firebase';
import firebase from './js/fire'
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { Provider } from 'react-redux';
import Navbar from './components/navbar';
import ChoixProfil from './components/choix_profil';

import Home from './components/page_home';
import Geolocalisation from './components/page_geolocalisation';
import Administration from './components/page_administration';
import MessageDetails from './components/page_details';
import MessageLists from './components/page_message_lists';
import Message from './components/page_message';
import Profil from './components/page_profil';
import Settings from './components/page_settings';
import Critere from './components/page_critere';
import Panier from './components/page_panier';
import IlocMatch from './components/IlocMatch';
import Gestion from './components/page_gestion';
import Portail from './components/page_portail';
import Notification from './components/page_notification';

// import FicheLocataire from './components/page_fiche_locataire';
// import Ficheproprietaire from './components/page_fiche_proprietaire';

import ReviewLocataire from './components/review_locataire';
import ReviewProprietaire from './components/review_proprietaire';

import ReviewLocataireNew from './components/review_locataire';
import ReviewProprietaireNew from './components/review_proprietaire';

import HistoriquePayment from './components/historique_payment';
import FormLocataire from './components/formulaire_locataire';
import FormProprietaire from './components/formulaire_proprietaire';

import FavorisLocataire from './components/favoris_locataire';
import FavorisProprietaire from './components/favoris_proprietaire';

import FicheLocataire from './components/fiches/fiche_locataire';
import FicheProprietaire from './components/fiches/fiche_proprietaire';

import FicheIlocProprietaire from './components/fiches/fiche_proprietaire_id';
import FicheIlocLocataire from './components/fiches/fiche_locataire_id';
import FicheIlocAppartement from './components/fiches/fiche_appartement';

import ProfilProprietaire from './components/profils/profil_proprietaire_id';
import ProfilLocataire from './components/profils/profil_locataire_id';
import ProfilAppartement from './components/profils/profil_appartement_id';

import PortailEmployeur from './components/portail_employeur';
import FormulaireEmploie from './components/formulaire_emploie';

import Signup from './components/signup';
import Login from './components/login';

import Footer from './components/footer';

import './style/style.css'
import reducers from './redux/reducers/reducer_user';


import { bindActionCreators } from 'redux';

import { initialize, onUserChange, onUserTypeChange, onRefreshNotifs } from './redux/actions/index';
import { connect } from 'react-redux';

import store from "./redux/store";
import google from 'google-maps';

import posed, { PoseGroup } from 'react-pose';

import { sendTokenToServer, setTokenSentToServer, showToken } from "./js/utils_token";
import SnackBarBlock from './components/notifications/SnackbarBlock'

import Button from '@material-ui/core/Button';

import * as SW from './serviceWorker'
import { SnackbarProvider } from 'notistack';
google.KEY = 'AIzaSyC5Q8rwSUI2OrW1lOSTWVtVrCcafjAWWok';
google.LIBRARIES = ['geometry', 'places'];

const RoutesContainer = posed.div({
  enter: { opacity: 1, delay: 50 },
  exit: { opacity: 0 }
});

const firestore = firebase.firestore();

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    coordonnee: {
      lng: 0,
      lat: 0,
    },
    userLogged: false,
    user: {},

  }

  receiveUser = (user) => {
    if (user) {
      // console.log("REceiving user", this.props);
      this.props.onUserChange({ ...user, logged: true });
      var db = firebase.firestore();
      db.collection('users').doc(user.uid)
      
      .get()
      .then((doc) => {
        if (doc.data()){
          console.log("USEEEER TYYYPE",doc.data().user_type )
        this.props.onUserTypeChange(doc.data().user_type);
      }
        // else
        // this.props.onUserTypeChange('proprio');
      });


      const firestore = firebase.firestore();
      firestore.collection("users").doc(user.uid).collection("notifications").get()
        .then(doc => {
          const nbNotifications = doc.size;
          this.setState({
            nbNotification: nbNotifications
          })
        });

      this.initializeFCM(user.uid);
    }
    else {
      this.props.onUserChange({ logged: false });
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(this.receiveUser);
    this.props.initialize();
    SW.register({});
  }

  initializeFCM = (uid) => {

    const messaging = firebase.messaging();
    messaging.usePublicVapidKey("BMI22kvQBjn0JXLgq5svTUzJaNKg5ychRvpWU_m3ruirS5u_KhCYVg5fH65R1e296ULN7UelMYEU03iKuByWPTk");

    messaging.requestPermission().then(function () {
      console.log('Notification permission granted.');

      messaging.getToken().then(function (currentToken) {
        if (currentToken) {
          sendTokenToServer(firebase.firestore(), uid, currentToken);
        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
          setTokenSentToServer(false);
        }
      }).catch(function (err) {
        console.log('An error occurred while retrieving token. ', err);

        setTokenSentToServer(false);
      });
    })
      .catch(function (err) {
        console.log('Unable to get permission to notify.', err);
      });

    messaging.onMessage(this.newNotification);
  }

  newNotification = payload => {
    console.log("RECEIVED", payload);
    // this.setState({
    //   notification:true
    // })
    // setTimeout(()=>{
    //   this.setState(
    //     {notification:false}
    //   )}, 2000
    // )
    // this.props.onRefreshNotifs({ ...this.props.user });
    console.log(this.props);
    this.props.onRefreshNotifs(this.props.user);
  }

  render() {
    return (
      <BrowserRouter>
        <Route render={({ location }) => {

          if (!location.key) {
            location.key = 1
            console.log('LOCATION VIDE', location);
          }

          return <Fragment>
            <Route path={/^(?!.*message).*$/} render={() => <Navbar />} />
            <Route path={/^(?!.*message).*$/} render={(props) => <ChoixProfil {...props} />} />
            <PoseGroup>
              <RoutesContainer key={location.key}>
                <ScrollToTop>
                  <Switch location={location}>
                    <Route exact path="/" component={Home} />
                    <Route path="/geolocalisation" render={() => <Geolocalisation onPositionChange={this.changePosition} {...this.state} />} />
                    <Route path="/administration" component={Administration} />
                    <Route path="/message" render={() => <Message />} />
                    <Route path="/message-lists" component={MessageLists} />
                    <Route path="/message-details" component={MessageDetails} />

                    <Route path="/gestion" component={Gestion} />
                    <Route path="/notification" component={Notification} />
                    <Route path="/portail" component={Portail} />

                    <Route path="/fichelocataire" component={FicheLocataire} />
                    <Route path="/ficheproprietaire" component={FicheProprietaire} />

                    <Route path="/review_locataire" component={ReviewLocataire} />
                    <Route path="/review_proprietaire" component={ReviewProprietaire} />

                    <Route path="/historique_payment" component={HistoriquePayment} />

                    <Route path="/portail_employeur" component={PortailEmployeur} />
                    <Route path="/formulaire_emploie" component={FormulaireEmploie} />
                    

                    <Route path="/formulaire_locataire" component={FormLocataire} />
                    <Route path="/formulaire_proprietaire" component={FormProprietaire} />

                    <Route path="/favoris_locataire" component={FavorisLocataire} />
                    <Route path="/favoris_proprietaire" component={FavorisProprietaire} />

                    <Route path="/fiche_locataire" component={FicheLocataire} />
                    <Route path="/fiche_proprietaire" component={FicheProprietaire} />

                    <Route path="/profil_appartement" component={ProfilAppartement} />

                    <Route path="/profil" render={(props) => { return this.props.user.logged ? <Profil history={props.history} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> }} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" render={(props) => <Login {...props} />} />
                    <Route path="/critere" render={() => <Critere />} />
                    <Route path="/panier" render={() => <Panier />} />
                    <Route path="/ilocMatch" render={(props) => { console.log(props); return this.props.user.logged ? <IlocMatch /> : <Redirect to={{ pathname: "/ilocMatch", state: { from: props.location } }} /> }} />

                    <Route path="/ficheIlocProp/:uid" component={FicheIlocProprietaire} />
                    <Route path="/ficheIlocLoc/:uid" component={FicheIlocLocataire} />
                    <Route path="/ficheIlocAppart/:uid" component={FicheIlocAppartement} />

                    <Route path="/profilProp/:uid" component={ProfilProprietaire} />
                    <Route path="/profilLoc/:uid" component={ProfilLocataire} />
                    <Route path="/profilAppart/:uid" component={ProfilAppartement} />

                  </Switch>
                </ScrollToTop>
              </RoutesContainer>
            </PoseGroup>
            <SnackBarBlock />
            <Route path={/^(?!.*message).*$/} {...this.state} render={() => <Footer />} />
            {/* <Snackbar
              color={"red"}
              open={this.state.notification}
              onClose={this.handleClose}
              TransitionComponent={Slide}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Nouvelle Notification</span>}
            /> */}
          </Fragment>
        }}

        />
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onUserChange, onUserTypeChange, onRefreshNotifs, initialize }, dispatch);
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)
ReactDOM.render(
  <Provider store={store} >
    <SnackbarProvider maxSnack={5}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}

      action={(
        <Button color="secondary" size="small">
          {'Dismiss'}
        </Button>
      )}

    >


      <ConnectedApp />
    </SnackbarProvider>
  </Provider>

  , document.querySelector('#root'));
