
const initialState ={
    geolocalisation:"test",
  }
  
  export default (state=initialState, action) => {
    switch(action.type){
      case 'CHANGE_CONTINENT':
        return {...action.payload};
      default:
        return state;
    }
  }
   