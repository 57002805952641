import React, { Component } from 'react';
import { CardElement, injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const collectPay = firebase.functions().httpsCallable('collectPaiement');

class CheckoutForm extends Component {

    state = {
        error: ""
    }

    constructor(props) {
        super(props);
    }




    render() {
        return (
            <Dialog
                open={this.props.open}
                // onClose={this.dialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Paiement"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <StripeProvider apiKey="pk_test_P6EPTiN2MVSLoGIPXCql7Diu00uZxfyItT">
                            <div className="example">
                                {/* <h1>React Stripe Elements Example</h1> */}
                                <Elements>
                                    <InnerFormInjected close={this.props.closed} history={this.props.history} redirectTo={this.props.redirectTo} submit={this.submit} />
                                </Elements>
                            </div>
                        </StripeProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={this.props.closed} color="red">
                        Annuler
                     </Button>

                </DialogActions>
            </Dialog>


        );
    }
}

class InnerForm extends Component {
    state = {
        waiting: false,
        message: ""
    }

    constructor(props) {
        super(props);
    }


    submit = async (ev) => {
        this.setState({
            waiting: true
        })
        let { token } = await this.props.stripe.createToken({ name: "Name" });

        if (token) {

            collectPay({ from: token.id }).then(response => {
                console.log(response);
                const data = response.data;
                if (data.message == "success") {
                    this.setState({ message: "SUCCESS" })
                    let { history } = this.props;
                    history.push({
                     pathname: this.props.redirectTo
                    });

                }
                else {
                    this.setState({ message: "Erreur veuillez réessayer!" })
                }
                this.setState({
                    waiting: false
                })
            })

        }
        else {
            this.setState({
                waiting: false
            })
        }


    }


    render() {
        return (
            <div className="checkout">
                <p>Would you like to complete the purchase?</p>
                <CardElement />
                <div>{this.state.message}</div>
                <Button onClick={this.submit} variant="outlined" color="primary">
                    {this.state.waiting ? "En cours" : "Confirmer"}
                </Button>
            </div>
        );
    }
}

const InnerFormInjected = injectStripe(InnerForm);

export default CheckoutForm;