import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Match from './IlocMatchProprietaire';

const firestore = firebase.firestore();

class Scanner extends Component {
    state = {
        read: true
    };

    handleClickGetId(e) {
        this.setState({ action_type: e.target.id });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleScan = (data) => {
        if (data) {
            firestore.collection('apparts').doc(data).get()
                .then(snap => {
                    const data = snap.data();
                    this.setState({ read: false });
                    // this.setState({
                    //   result: data
                    // })
                    console.log(this.props);
                    let { history } = this.props;
                    history.push({
                        pathname: "profilProp/" + data.d.proprio
                    });
                })
                .catch(
                    this.setState({
                        result: "Appartement non reconnu"
                    })
                )
        }
    }
    handleError = err => {
        console.error(err)
    }
  
    render() {
      return (
          <div className="qr_code_container">
              {this.state.result}
              {
                  this.state.read ?
                      <QrReader
                          delay={300}
                          onError={this.handleError}
                          onScan={this.handleScan}
                          style={{ width: '322px' }}
                      /> : null
              }
          </div>                                        
      )
    }
}

class Matching extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
          <div className="matching_container">
              <div className="card_container">
                  {/* <img className="card_image" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1548862994/hotel-rooms-510951_1280_o1q2no.jpg" /> */}
                  <Match />
              </div>
          </div>
      )
    }
}
class ProfilProprietaire extends Component {
    state = {
      read: true
    };

    constructor(props) {
      super();
      this.state = {
        action_type: "matching",
      }
      this.handleClickGetId = this.handleClickGetId.bind(this);
    }

    componentDidMount() {
        const user = this.props.user;
        console.log(user);
        if (user) {
            this.setState({
                photo: user.photoURL,
                name: user.displayName,
            });
        }
    }

    handleClickGetId(e) {
      this.setState({ action_type: e.target.id });
    }

    toggle() {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    handleScan = (data) => {
      if (data) {
        firestore.collection('apparts').doc(data).get()
          .then(snap => {
            const data = snap.data();
            this.setState({ read: false });
            // this.setState({
            //   result: data
            // })
            console.log(this.props);
            let { history } = this.props;
            history.push({
              pathname: "profilProp/" + data.d.proprio
            });
          })
          .catch(
            this.setState({
              result: "Appartement non reconnu"
            })
          )
      }
    }
    handleError = err => {
      console.error(err)
    }
  render() {
      var pageToLoad, action_type, action_edit;
      action_type = this.state.action_type;

      if (action_type == "scanner") {
          pageToLoad = <Scanner />;
          $("#scanner").addClass("primary_btn_disabled");
          $("#matching").removeClass("primary_btn_disabled");

      } else if (action_type == "matching") {
          pageToLoad = <Matching />;
          $("#scanner").removeClass("primary_btn_disabled");
          $("#matching").addClass("primary_btn_disabled");
      }

      return (
          <div>
              <header id="notification_header">
                  <div className="container">
                      <h1 className="critere_title"> Profil personnel </h1>
                  </div>
              </header>

              <div style={{ paddingTop: '50px' }} className="container">
                  <div className="all_notification_continer">
                      <div className="row text-center">
                          <div className="profil_container">
                            <img className="profil_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                            <p className="profil_name">{this.state.name}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <br />
              <br />
              <div className="container">
                <div className="row text-center">
                  <div className="col-12">
                    <a href="ficheproprietaire">
                      <img className="fiche_icon" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fficheiloc.svg?alt=media&token=fd2f0012-f244-4799-a261-f9665548c237" />
                    </a>
                    <br />
                    <br />
                    <a href="/review_proprietaire" className="primary_btn" style={{ fontSize: '12px' }}> Mes évaluations</a>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container">
                <div className="row text-center">
                  <div className="col-12">
                    <div className="notification_container">
                      <div className="row text-center">
                        <div className="col-lg-5 col-md-6 col-12 text-center">
                          <span className="profil_section_title">Numérisez le code QR</span>
                          <br />
                          <br />
                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-6 text-center">
                              <p onClick={this.handleClickGetId} id="scanner" className="primary_btn small_btn primary_btn_disabled">Scanner</p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-6">
                              <p onClick={this.handleClickGetId} id="matching" className="primary_btn small_btn">Matching</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                          <div className="row text-right">
                            <div className="profil_feature_container">
                              {pageToLoad}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container">
                  <h1 className="critere_title"> Mes propriétés</h1>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div id="" className="carousel slide" data-ride="">
                      <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                        <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="First slide" />
                        </div>
                        <div className="carousel-item">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Third slide" />
                        </div>
                      </div>
                      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                    <a href="/profil_appartement" className="description_container">
                      <span className="description_name"> Bel et grand appartement sur la rive-sud</span>
                      <br />
                      <span className="description_price">950$ / mois</span>
                      <br />
                      <span className="description_owner_name"> Phanuel Randrianandraina</span>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div id="" className="carousel slide" data-ride="">
                      <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                        <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="First slide" />
                        </div>
                        <div className="carousel-item">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Third slide" />
                        </div>
                      </div>
                      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                    <a href="/profil_appartement" className="description_container">
                      <span className="description_name"> Bel et grand appartement sur la rive-sud</span>
                      <br />
                      <span className="description_price">950$ / mois</span>
                      <br />
                      <span className="description_owner_name"> Phanuel Randrianandraina</span>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div id="" className="carousel slide" data-ride="">
                      <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                        <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="First slide" />
                        </div>
                        <div className="carousel-item">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                          <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Third slide" />
                        </div>
                      </div>
                      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                    <a href="/profil_appartement" className="description_container">
                      <span className="description_name"> Bel et grand appartement sur la rive-sud</span>
                      <br />
                      <span className="description_price">950$ / mois</span>
                      <br />
                      <span className="description_owner_name"> Phanuel Randrianandraina</span>
                    </a>
                  </div>
                </div>          
              </div>
          </div>
      )
  }
}

function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps)(ProfilProprietaire);

