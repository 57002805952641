import * as firebase from "firebase/app";
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/firestore';
var config = {
    apiKey: "AIzaSyBFb5bBXnAblkdgsGoBRpJSmmv6kH25Ww4",
    authDomain: "iloc-16b8d.firebaseapp.com",
    databaseURL: "https://iloc-16b8d.firebaseio.com",
    projectId: "iloc-16b8d",
    storageBucket: "iloc-16b8d.appspot.com",
    messagingSenderId: "171996181525",
    timestampsInSnapshots: true,
    appId: "1:171996181525:web:5629b5f895f7e429"
  };
  const fire =  firebase.initializeApp(config);
  
export default fire;