import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Match from './IlocMatch';

const firestore = firebase.firestore();
class Scanner extends Component {
    state = {
        read: true
    };

    constructor(props) {
        super(props);
        console.log(this.props);
    }

    handleClickGetId(e) {
        this.setState({ action_type: e.target.id });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleScan = (data) => {
        if (data) {
            firestore.collection('apparts').doc(data).get()
                .then(snap => {
                    const data = snap.data();
                    this.setState({ read: false });
                    // this.setState({
                    //   result: data
                    // })
                    console.log(this.props);
                    let { history } = this.props;
                    history.push({
                        pathname: "/profilAppart/" + snap.id
                    });
                })
                .catch(
                    this.setState({
                        result: "Appartement non reconnu"
                    })
                )
        }
    }
    handleError = err => {
        console.error(err)
    }
  
    render() {
      return (
          <div className="qr_code_container">
              {this.state.result}
              {
                  this.state.read ?
                      <QrReader
                          delay={300}
                          onError={this.handleError}
                          onScan={this.handleScan}
                          style={{ width: '322px' }}
                      /> : null
              }
          </div>                                        
      )
    }
}

class Matching extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
          <div className="matching_container">
              <div className="card_container">
                  <Match />
              </div>
          </div>
      )
    }
}


class ProfilLocataire extends Component {
    state = {
        read: true
    };

    constructor(props) {
        super();
        this.state = {
            action_type: "matching",
            action_edit: "close_edit"
        }
        this.handleClickGetId = this.handleClickGetId.bind(this);
        this.handleClickGetIdEdit = this.handleClickGetIdEdit.bind(this);
        this.handleClickCritere = this.handleClickCritere.bind(this);
    }
    
    componentDidMount() {
      firestore.collection('profils_locataires').doc(this.props.user.uid).get()
          .then(userDataSnapshot => {
              var userData = userDataSnapshot.data();
              console.log(userData);
              if (userData) {
                this.fillwWithData(userData);
              }          
        })

        const user = this.props.user;
        console.log(user);
        if (user) {
            this.setState({
                photo: user.photoURL,
                name: user.displayName,
            });
        }
    }

    fillwWithData = (userData) => {
      this.setState({
        ville: userData.ville,
        min_price: userData.min_price,
        max_price: userData.max_price,
        type_logement: userData.type_logement,
        has_animaux: userData.has_animaux,
        is_collocation: userData.is_collocation,
        duree: userData.duree,
        is_chauffe: userData.is_chauffe,
        is_eclaire: userData.is_eclaire,
        is_meubler: userData.is_meubler,
        has_parking: userData.has_parking,
        has_laveuse_secheuse: userData.has_laveuse_secheuse,
    })

    $("#ville_text").text(userData.ville);
    $("#prix_text").text(userData.min_price + "$ à " + userData.max_price + "$");
    $("#type_logement_text").text(userData.type_logement);

    $("#ville").val(userData.ville);
    $("#min_price").val(userData.min_price);
    $("#max_price").val(userData.max_price);
    $("#type_logement").val(userData.type_logement);


    if (userData.has_animaux === true)
    {
        $("#animaux_text").text("Oui");
        $("#has_animaux").prop( "checked", true );
    }
    else if (userData.has_animaux === false)
    {
        $("#animaux_text").text("Non");
    }

    if (userData.is_collocation === true)
    {
        $("#collocation_text").text("Oui");
        $("#is_collocation").prop( "checked", true );
    }
    else if (userData.is_collocation === false)
    {
        $("#collocation_text").text("Non");
    }

    $("#duree_text").text(userData.duree);

    if (userData.is_chauffe === true)
    {
        $("#chauffe_text").text("Oui");
        $("#is_chauffe").prop( "checked", true );
    }
    else if (userData.is_chauffe === false)
    {
        $("#chauffe_text").text("Non");
    }

    if (userData.is_eclaire === true)
    {
        $("#eclaire_text").text("Oui");
        $("#is_eclaire").prop( "checked", true );
    }
    else if (userData.is_eclaire === false)
    {
        $("#eclaire_text").text("Non");
    }

    if (userData.is_meubler === true)
    {
        $("#meuble_text").text("Oui");
        $("#is_meuble").prop( "checked", true );
    }
    else if (userData.is_meubler === false)
    {
        $("#meuble_text").text("Non");
    }

    if (userData.has_parking === true)
    {
        $("#parking_text").text("Oui");
        $("#has_parking").prop( "checked", true );
    }
    else if (userData.has_parking === false)
    {
        $("#parking_text").text("Non");
    }

    if (userData.has_laveuse_secheuse === true)
    {
        $("#laveuse_secheuse_text").text("Oui");
        $("#has_laveuse_secheuse").prop( "checked", true );
    }
    else if (userData.has_laveuse_secheuse === false)
    {
        $("#laveuse_secheuse_text").text("Non");
    }
}

    handleClickCritere(e) {
        e.preventDefault();
        console.log('saving firebase');

        var ville = $("#ville").val();
        var min_price = $('#min_price').val();
        var max_price = $('#max_price').val();
        var type_logement = $('#type_logement').val();
        var has_animaux = $('#has_animaux').prop('checked');
        var is_collocation = $('#is_collocation').prop('checked');
        var duree = $('#duree').val();
        var is_chauffe = $('#is_chauffe').prop('checked');
        var is_eclaire = $('#is_eclaire').prop('checked');
        var is_meubler = $('#is_meubler').prop('checked');
        var has_parking = $('#has_parking').prop('checked');
        var has_laveuse_secheuse = $('#has_laveuse_secheuse').prop('checked');

        console.log("user", this.props.user);
        const new_critere =
        {
            ville: ville,
            min_price: min_price,
            max_price: max_price,
            type_logement: type_logement,
            has_animaux: has_animaux,
            is_collocation: is_collocation,
            duree: duree,
            is_chauffe: is_chauffe,
            is_eclaire: is_eclaire,
            is_meubler: is_meubler,
            has_parking: has_parking,
            has_laveuse_secheuse: has_laveuse_secheuse,
            uid: this.props.user.uid
        }
        console.log('new critere', new_critere);

        this.fillwWithData(new_critere);
        firestore.collection('profils_locataires').doc(this.props.user.uid).set(new_critere, {merge: true});
    }

    handleClickGetId(e) {
        this.setState({ action_type: e.target.id });
    }

    handleClickGetIdEdit(e) {
        this.setState({ action_edit: e.target.id });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        var pageToLoad, action_type, action_edit;
        action_type = this.state.action_type;
        action_edit = this.state.action_edit;

        if (action_type == "scanner") {
            const {history}=this.props;
            pageToLoad = <Scanner history={history} />;
            $("#scanner").addClass("primary_btn_disabled");
            $("#matching").removeClass("primary_btn_disabled");
  
        } else if (action_type == "matching") {
            pageToLoad = <Matching />;
            $("#scanner").removeClass("primary_btn_disabled");
            $("#matching").addClass("primary_btn_disabled");
        }

        if (action_type == "looking") {
          $("#looking").addClass("clicked_statut");
          $("#locked").removeClass("clicked_statut");
          $(".current_statut").text("Vous êtes en recherche 🖐");

      } else if (action_type == "locked") {
          $("#locked").addClass("clicked_statut");
          $("#looking").removeClass("clicked_statut");
          $(".current_statut").text("Vous êtes en location ✌");
      }

        if (action_edit == "go_edit") {
            $(".hide_edit").hide();
            $(".show_edit").show();
            console.log("open");
        }

        else if (action_edit == "close_edit") {
            $(".hide_edit").show();
            $(".show_edit").hide();
            console.log("close");
        }
        return (
            <div>
                <header id="notification_header">
                    <div className="container">
                        <h1 className="critere_title"> Profil personnel </h1>
                    </div>
                </header>

                <div style={{ paddingTop: '50px'}} className="container">
                    <div className="all_notification_continer">
                        <div className="row text-center">
                            <div className="profil_container">
                                <img className="profil_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                                <p className="profil_name">{this.state.name}</p>
                                <div className="row">
                                    <div className="col-6 no-padding">
                                        <p onClick={this.handleClickGetId} id="looking" className="looking_statut clicked_statut">Je recherche</p>
                                    </div>
                                    <div className="col-6 no-padding">
                                        <p onClick={this.handleClickGetId} id="locked" className="locked_statut">J’ai trouvé</p>
                                    </div>
                                </div>
                                <br />
                                <span className="statut_title">Votre statut :</span>
                                <br />
                                <span className="current_statut">Vous êtes en recherche 🖐 </span>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                          <NavLink to="/fiche_locataire">
                            <img className="fiche_icon" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fficheiloc.svg?alt=media&token=fd2f0012-f244-4799-a261-f9665548c237" />
                          </NavLink>
                            <br />
                            <br />
                            <a href="/review_locataire" className="primary_btn" style={{fontSize: '12px'}}> Mes évaluations</a>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="notification_container">
                                <form onSubmit={this.handleClickCritere} style={{display: 'contents'}}>
                                    <div className="row text-center">
                                        <div className="col-lg-5 col-md-12 col-12 m-t-50">
                                            <span className="profil_section_title center_element">Mes critères de recherche</span>
                                            <br />
                                            <span id="go_edit" onClick={this.handleClickGetIdEdit} style={{cursor: 'pointer'}} className="center_element hide_edit">Modifier <img src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559692694/edit_fpvwsd.svg" style={{height: '15px'}} /></span>
                                            <div className="col-12 show_edit">
                                                <br />                                           
                                                <span id="close_edit" onClick={this.handleClickGetIdEdit} className="primary_btn">Retour</span> &nbsp;
                                                <button onClick={this.handleClickGetIdEdit} className="primary_btn" type="submit">Enregistrer</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-12">
                                            <div className="row align_left hide_edit">
                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Ville :</span>
                                                    <span className="data_content_profil" id="ville_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Prix :</span>
                                                    <span className="data_content_profil" id="prix_text"></span>
                                                </div>
                                                
                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Type de logement :</span>
                                                    <span className="data_content_profil" id="type_logement_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Animaux :</span>
                                                    <span className="data_content_profil" id="animaux_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Collocation :</span>
                                                    <span className="data_content_profil" id="collocation_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Durée :</span>
                                                    <span className="data_content_profil" id="duree_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                        

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Chauffé :</span>
                                                    <span className="data_content_profil" id="chauffe_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Éclairé :</span>
                                                    <span className="data_content_profil" id="eclaire_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                        

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Meublé :</span>
                                                    <span className="data_content_profil" id="meuble_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Stationnement :</span>
                                                    <span className="data_content_profil" id="parking_text"></span>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                        

                                                <div className="col-lg-12 col-md-12 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Entré laveuse et sécheuse :</span>
                                                    <span className="data_content_profil" id="laveuse_secheuse_text"></span>
                                                </div>
                                            </div>


                                            <div className="row align_left show_edit">
                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Ville :</span>
                                                    <input className="form_input_profil" id="ville" type="text" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Prix :</span>
                                                    <input style={{width: '90px'}} id="min_price" className="form_input_profil" placeholder="Min" type="number" />
                                                    <input style={{width: '90px'}} id="max_price" className="form_input_profil" placeholder="Max" type="number" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Type de logement :</span>
                                                    <select className="form_input_profil" id="type_logement">
                                                        <option selected>Choisir</option>
                                                        <option value={"1½"}>1½</option>
                                                        <option value={"2½"}>2½</option>
                                                        <option value={"3½"}>3½</option>
                                                        <option value={"4½"}>4½</option>
                                                        <option value={"4½"}>5½</option>
                                                        <option value={"6½"}>6½</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Animaux :</span>&nbsp;
                                                    <input type="checkbox" className="" id="has_animaux" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>
                                                
                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Collocation :</span>&nbsp;
                                                    <input type="checkbox" className="" id="is_collocation" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Durée :</span>
                                                    <select className="form_input_profil" id="duree">
                                                        <option selected>Choisir</option>
                                                        <option value="6 mois">6 mois</option>
                                                        <option value="1 année">1 année</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                 

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Chauffé :</span>&nbsp;
                                                    <input type="checkbox" className="" id="is_chauffe" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Éclairé :</span>&nbsp;
                                                    <input type="checkbox" className="" id="is_eclaire" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>                                 

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Meublé :</span>&nbsp;
                                                    <input type="checkbox" className="" id="is_meubler" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile show_mobile">
                                                    <hr className="fiche_hr show_mobile" />
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Stationnement :</span>&nbsp;
                                                    <input type="checkbox" className="" id="has_parking" />
                                                </div>

                                                <div className="col-12 m-b-15-mobile">
                                                    <hr className="fiche_hr" />
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Entré laveuse et sécheuse :</span>&nbsp;
                                                    <input type="checkbox" className="" id="has_laveuse_secheuse" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="notification_container">
                                <div className="row text-center">
                                    <div className="col-lg-5 col-md-6 col-12 text-center">
                                    <span className="profil_section_title">Numérisez le code QR</span>
                                    <br />
                                    <br />
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-6 text-center">
                                                <p onClick={this.handleClickGetId} id="scanner" className="primary_btn small_btn primary_btn_disabled">Scanner</p>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-6">
                                                <p onClick={this.handleClickGetId} id="matching" className="primary_btn small_btn">Matching</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-6 col-12">
                                        <div className="row text-right">
                                            <div className="profil_feature_container">
                                                {pageToLoad}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    if (state.user) {
      return {
        user: state.user,
      }
    }
  }
  
export default connect(mapStateToProps)(ProfilLocataire);






// import React, { Component } from 'react';
// import firebase from 'firebase';
// import { connect } from 'react-redux';
// import QrReader from 'react-qr-reader'
// import { NavLink } from 'react-router-dom';

// import google from 'google-maps';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// const firestore = firebase.firestore();
// class Star extends Component {


//   render() {
//     return (
//       <i className="fa fa-star blue" aria-hidden="true" />
//     )
//   }
// }

// class ScoreNumber extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     let rows = [];
//     console.log(this.props.score);
//     for (let i = 0; i < this.props.score / 4; i++) {
//       rows.push(<Star key={i} />)
//     }

//     return (
//       <span> {rows} </span>
//     )
//   }
// }

// class InfoPerso extends Component {
//   state = {
//     read: true
//   };
//   constructor(props) {
//     super(props);
//     this.toggle = this.toggle.bind(this);
//   }

//   toggle() {
//     this.setState(prevState => ({
//       modal: !prevState.modal
//     }));
//   }

//   componentDidMount() {
    
//     firestore.collection('profils_locataires').doc(this.props.uid).get()
//     .then(doc => {
//       if (doc.exists) {
//         console.log("QUERY uid" + firebase.auth().currentUser.uid);
//         const data = doc.data();
//         console.log(data);
//         this.setState({
//           last_name: data.nom,
//           first_name: data.prenom,
//           birth_date: data.birth_date,
//           email: data.email,
//           tel: data.tel,
//           country: data.country,
//           city: data.city,
//           postal_code: data.postal_code,
//           piece: data.piece,
//           is_single: data.is_single,
//           is_relationship: data.is_relationship,
//           is_conjoint_de_fait: data.is_conjoint_de_fait,
//           is_married: data.is_married,
//           nb_kids: data.nb_kids,
//           is_student:data.is_student,
//           is_employed: data.is_employed,
//           is_contractuel: data.is_contractuel,
//           is_statutTemp: data.is_statutTemp,
//           is_tourist: data.is_tourist,
//           nb_cats: data.nb_cats,
//           nb_dogs: data.nb_dogs,
//           is_animal_others: data.is_animal_others,
//           description: data.description,
//         })
//       } else {
//         console.log('no document');
//       }
//     })
//   }

//   handleScan = (data) => {
//     if (data) {
//       firestore.collection('apparts').doc(data).get()
//       .then (snap =>{
//         const data = snap.data();
//         this.setState({read:false});
//         // this.setState({
//         //   result: data
//         // })
//         console.log(this.props);
//         let { history } = this.props;
//         history.push({
//           pathname: "profilProp/"+ data.d.proprio
//         });
//       })
//       .catch (
//         this.setState({
//           result: "Appartement non reconnu"
//         })
//       )
//     }
//   }
//   handleError = err => {
//     console.error(err)
//   }

//   render() {
//     return (
//       <div>
//         <div className="card" id="profilRight">
//           <div className="card-header">
//             <h3 style={{ margin: '0 auto' }} className="text-left titreInfoPerso">CRITÈRES DE RECHERCHE <span onClick={this.toggle} style={{ float: 'right', cursor: 'pointer' }}><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span></h3>

//           </div>
//           <div className="card-body paddingAllMobile">
//             <div className="row text-left">
//               <div className="col-lg-6 col-12">
//                 <p><span id="infoPersoTitre"> Ville: </span> {this.state.city} </p>
//                 <p><span id="infoPersoTitre"> Type de logement: </span>   </p>
//                 <p><span id="infoPersoTitre"> Collocation: </span>   </p>
//               </div>


//               <div className="col-lg-6 col-12">
//                 <p><span id="infoPersoTitre"> Prix: </span>  </p>
//                 <p><span id="infoPersoTitre"> Animaux: </span> {this.state.Animaux}  </p>
//                 <p><span id="infoPersoTitre"> Hobbies: </span> </p>
//               </div>
//             </div>
//           </div>
//         </div>


//         {this.state.result}
//         {
//           this.state.read ?
//         <QrReader
//           delay={300}
//           onError={this.handleError}
//           onScan={this.handleScan}
//           style={{ width: '100%' }}
//         />: null
//         }



//         <div>
//           <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
//             <ModalHeader style={{ backgroundColor: '#205193', color: 'white' }} toggle={this.toggle}>Modification des critères de recherche </ModalHeader>
//             <ModalBody style={{ backgroundColor: '#205193' }}>
//                 <div className="row">
//                   <div className="col-12 formAjoutAppartement">
//                     <input id="titre" className="form-control" placeholder="Ville" />
//                   </div>
//                   <div className="col-12 formAjoutAppartement">
//                     <input id="titre" className="form-control" placeholder="Type de logement" />
//                   </div>
//                   <div className="col-12 formAjoutAppartement">
//                     <input id="titre" className="form-control" placeholder="Prix" />
//                   </div>
//                   <div className="col-12 formAjoutAppartement">
//                     <input id="titre" className="form-control" placeholder="Hobbies" />
//                   </div>
//                   <div className="col-6 formAjoutAppartement">
//                   <span style={{color: 'white'}}>Animaux</span> &nbsp;
//                     <select id="nb_chiens_ajout" className="custom-select">
//                       <option selected>Choisir</option>
//                       <option value={1}>Oui</option>
//                       <option value={2}>Non</option>
//                     </select>
//                   </div>
//                   <div className="col-6 formAjoutAppartement">
//                     <span style={{color: 'white'}}>Collocation</span> &nbsp;
//                     <select id="nb_chiens_ajout" className="custom-select">
//                       <option selected>Choisir</option>
//                       <option value={1}>Oui</option>
//                       <option value={2}>Non</option>
//                     </select>
//                   </div>
//                 </div>
//             </ModalBody>
//             <ModalFooter style={{ backgroundColor: '#205193' }}>
//               {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
//                   <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
//             </ModalFooter>
//           </Modal>
//         </div>
//       </div>
//     )
//   }
// }

// class ProfilInfo extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       statusIndex: 0
//     }
//   }
//   status = [
//     // {status:'En recherche', color:'green'},
//     // {status:'Cession/Transfert', color:'orange'},
//     // {status:'Iloc', color: 'red'}🖐 👊 ✌,
//     { status: 'En recherche', description: 'Vous recherchez un appartement', lien: '🖑', color: 'green' },
//     // { status: 'Cession/Transfert', description: 'Échanger / Transférer votre bail avec un autre locataire', lien: '👊', color: 'orange' },
//     { status: 'Iloc', description: 'Vous êtes en location', lien: '✌', color: 'red' },
//   ]

//   changeStatus(e) {
//     var db = firebase.firestore();
//     var actualIndex = this.state.statusIndex;
//     this.setState({
//       statusIndex: (this.state.statusIndex + 1) % (this.status.length)
//     });
//     actualIndex = (actualIndex + 1) % (this.status.length);
//     console.log('previous actual index', actualIndex);
//     const status = this.status[actualIndex].status;
//     if (this.props.user) {
//       db.collection('users').doc(this.props.user.uid).set({
//         status_locataire: status
//       }, { merge: true });
//       console.log('changing status_locataire ', status)
//     }
//   }

//   componentWillMount() {
//     var db = firebase.firestore();
//     db.collection('users').doc(this.props.user.uid).get().then((doc) => {
//       if (doc.exists) {
//         this.status.map((obj, index) => {
//           if (obj.status === doc.data().status_locataire) {
//             this.setState({
//               statusIndex: index
//             })
//           }
//         })
//       }
//     })
//   }

//   render() {
//     var color = this.status[this.state.statusIndex].color;
//     var lien = this.status[this.state.statusIndex].lien;
//     var description = this.status[this.state.statusIndex].description;
//     return (
//       <div className="row">
//         <div id="profilLeft" className="col-lg-2 col-md-12 col-sm-12">
//           <div>
//             <div className="cardInsideImage text-center" id="spaceAccount">
//               <div id="profile">
//                 <div>
//                   <img id="profilIMG" src={this.props.picture} />
//                   {/* <i class="fa fa-camera" aria-hidden="true"></i> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-1 col-0" />
//         <div className="col-lg-9 col-md-12 col-sm-12 alignCenter">
//           <span className="profilName">{this.props.prenom} {this.props.nom}</span>
//           <br />
//           <div className="scoreContainer">
//             {/* <ScoreNumber score={this.props.score} />
//           <br/>
//           <br/> */}
//             {/* <img onClick={this.changeStatus.bind(this)} id="status-image" style={{height: '40px'}} src={lien} /> */}
//             <span id="statut">Statut:</span> <span onClick={this.changeStatus.bind(this)} id="status-image" style={{ fontSize: '30px' }}>{lien}</span>
//             {/* <button onClick={this.changeStatus.bind(this)}  style={{backgroundColor: color}} className="statut-cercle" > </button> */}
//             <span className="text-statut">{this.status[this.state.statusIndex].status}</span>
//             <br />
//             <br />
//             <span style={{ fontSize: '18px', marginTop: '30px', color: color }}>{description}</span>
//           </div>
//           {/* <img id="starReview" src="images/stars.png" /> */}
//           {/* <h4 style={{marginBottom: '50px', color: 'white'}}>{this.props.score}</h4> */}

//            <div className="row">
//            <div style={{ marginBottom: '15px' }} className="col-lg-4 col-md-6 col-12">
//               <NavLink to="/reviewlocataire"><button className="btnProfil"><span className="lnr lnr-thumbs-up lnrIconBtn" /> &nbsp; Évaluation </button></NavLink>
//               &nbsp; <img src="https://img.icons8.com/color/25/00000/help.png"></img>
//             </div>

//             <div style={{ marginBottom: '15px' }} className="col-lg-4 col-md-6 col-12">
//               <NavLink to="/fichelocataire"><button className="btnProfil"><i style={{color: 'green'}} class="fa fa-download lnrIconBtn" aria-hidden="true"></i>&nbsp; Fiche &nbsp; <img className="ilocLogo" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542681974/IlocIconRadial_z9ijxj.png" /></button> </NavLink>
//               &nbsp; <img src="https://img.icons8.com/color/25/000000/help.png"></img>
//             </div>
//             <div  className="col-lg-4 col-md-6 col-12">
//               <NavLink to="/settings"><button className="btnProfil"><span className="lnr lnr-cog lnrIconBtn" /> &nbsp; Paramètres </button></NavLink>
//               &nbsp; <img src="https://img.icons8.com/color/25/000000/help.png"></img>
//             </div>
//           </div>

//           <br />
//           <br />
//           <InfoPerso uid={this.props.user.uid} history={this.props.history} dateNaissance={this.props.dateNaissance} email={this.props.email} phone={this.props.phone}
//             pays={this.props.pays} ville={this.props.ville} codePostal={this.props.codePostal}
//             situation_familliale={this.props.situation_familliale} statut_professionnel={this.props.statut_professionnel} Animaux={this.props.Animaux} />
//         </div>
//       </div>
//     )
//   }
// }

// class EmploiActuel extends Component {
//   render() {
//     return (
//       <div id="empActuel">
//         <h5>Emplois actuels</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//       </div>
//     )
//   }
// }

// class EmploiPrecedent extends Component {
//   render() {
//     return (
//       <div id="empPrecedent">
//         <h5>Emplois précédents</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>1ère Année</p>
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>2e Année</p>
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>3e Année</p>
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//       </div>
//     )
//   }
// }

// class InfoProf extends Component {
//   constructor() {
//     super();
//     this.state = {
//       typeUser: "empActuel",
//     }
//     this.handleClickForm = this.handleClickForm.bind(this);
//   }
//   handleClickForm(e) {
//     this.setState({ typeUser: e.target.id });
//     console.log(e.target.id);
//   }

//   render() {
//     var pageToLoad, typeUtilisateur;
//     typeUtilisateur = this.state.typeUser;

//     if (typeUtilisateur == "empActuel") {
//       pageToLoad = <EmploiActuel />;
//     } else if (typeUtilisateur == "empPrecedent") {
//       pageToLoad = <EmploiPrecedent />;
//     }

//     return (
//       <div className="card" id="profilRight">
//         <div className="card-header">
//           <h3 style={{ margin: '0 auto' }} className="text-left titreInfoPerso">Renseignements professionnels</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row">
//             <div style={{ margin: '15px 0' }} className="col-12">
//               <span onClick={this.handleClickForm} className="profil-option" id="empActuel">Emp. actuels</span> &nbsp; &nbsp;
//                 <span onClick={this.handleClickForm} className="profil-option" id="empPrecedent">Emp. précédents</span>
//             </div>
//           </div>
//           <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)', width: '100%' }} />
//           <div className="row text-left">
//             <div className="col-12">
//               {pageToLoad}
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// class HistoriqueLoc extends Component {
//   constructor(props) {
//       super(props);
//   }

//   render() {
//       return (
//         <div className="card" id="profilRight">
        
//       </div>
//       )
//   }
// }

// class Hobbies extends Component {
//   constructor(props) {
//       super(props);
//   }

//   render() {
//       return (
//         <div className="card" id="profilRight">
//         <div className="card-header">
//           <h3 style={{ margin: '0 auto' }} className="text-left titreInfoPerso">Mes hobbies</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row text-left">
//             <div className="col-12">
//               <div>
//                 <div className="text-center">
//                   <span style={{fontSize: '50px'}}>Texte + </span> 
//                   <span style={{fontSize: '50px'}}> Image</span>
//                   <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )
//   }
// }

// class RefActuel extends Component {
//   render() {
//     return (
//       <div>
//         <h5>Références actuelles</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>Nom du propriétaire</p>
//       </div>
//     )
//   }
// }

// class RefPrecedent extends Component {
//   render() {
//     return (
//       <div>
//         <h5>Emplois précédents</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>1ère Année</p>
//         <p>Nom du propriétaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>2e Année</p>
//         <p>Nom du propriétaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>3e Année</p>
//         <p>Nom du propriétaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//       </div>
//     )
//   }
// }

// class RefLocatives extends Component {
//   constructor() {
//     super();
//     this.state = {
//       typeUser: "RefActuel",
//     }
//     this.handleClickForm = this.handleClickForm.bind(this);
//   }
//   handleClickForm(e) {
//     this.setState({ typeUser: e.target.id });
//     console.log(e.target.id);
//   }

//   render() {
//     var pageToLoad, typeUtilisateur;
//     typeUtilisateur = this.state.typeUser;

//     if (typeUtilisateur == "RefActuel") {
//       pageToLoad = <EmploiActuel />;
//     } else if (typeUtilisateur == "RefPrecedent") {
//       pageToLoad = <EmploiPrecedent />;
//     }

//     return (
//       <div className="card" id="profilRight">
//         <div className="card-header">
//           <h3 style={{ margin: '0 auto' }} className="text-left titreInfoPerso">Références locatives</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row">
//             <div style={{ margin: '15px 0' }} className="col-12">
//               <span onClick={this.handleClickForm} className="profil-option" id="RefActuel">Réf. actuelles</span> &nbsp; &nbsp;
//                 <span onClick={this.handleClickForm} className="profil-option" id="RefPrecedent">Réf. précédents</span>
//             </div>
//           </div>
//           <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)', width: '100%' }} />
//           <div className="row text-left">
//             <div className="col-12">
//               {pageToLoad}
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }


// class MapProfil extends Component {
//   nb = 0;
//   circlesData = [];
//   constructor() {
//     super();
//     this.state = {

//     }

//     this.promiseReceivingGoogleMaps = this.promiseReceivingGoogleMaps.bind(this);
//     this.setMap = this.setMap.bind(this);
//     this.addCircle = this.addCircle.bind(this);
//     this.promiseReceivingGoogleMaps()
//       .then(google => {
//         this.google = google;
//         this.setMap(google);
//       });


//   }

//   promiseReceivingGoogleMaps = (maps) => {
//     return new Promise((maps) => {
//       return google.load(maps)
//     })
//   }

//   handleClickForm(e) {
//     this.setState({ typeUser: e.target.id });
//     console.log(e.target.id);
//   }


//   setMap(google) {
//     var map = new google.maps.Map(document.getElementById('mapProfil'), {
//       center: {
//         lat: 45.516136,
//         lng: -73.65683
//       },
//       zoom: 3,
//       mapTypeId: 'roadmap'
//     });

//     var input = document.getElementById('searchBox');

//     var searchBox = new google.maps.places.SearchBox(input);
//     searchBox.bindTo('bounds', map);

//     //click listener
//     map.addListener('click', this.addCircle)

//     this.map = map;
//     this.google = google;

//     //searchbox listener
//     searchBox.addListener('places_changed', function () {
//       var place = searchBox.getPlaces()[0];

//       if (place.length == 0) {
//         return;
//       }

//       var bounds = new google.maps.LatLngBounds();
//       if (place.geometry.viewport) {
//         // Only geocodes have viewport.
//         bounds.union(place.geometry.viewport);
//       } else {
//         bounds.extend(place.geometry.location);
//       }

//       map.fitBounds(bounds);
//     })

//     //add circles to map
//     const db = firebase.firestore();
//     const zonesRef = db.collection('zones_locataires').doc(firebase.auth().currentUser.uid).collection('zones');
//     zonesRef.get().then((data) => {
      
//      data.docs.map ((data) => {
//       var circleData = data.data();
//        circleData.id = data.id;
//        this.addCircle(circleData, false);
//      })
        

//         // for (var i = 0; i < this.nb; i++) {
//         //   zonesRef.collection('zones').get().then((data) => {
//         //     var circleData = data.data();
//         //     // this.circlesData.push({
//         //     //   latLng: circleData.latLng,
//         //     //   radius: circleData.radius
//         //     // });
//         //     console.log('IN CIRCLE', circleData);

//         //     this.addCircle(circleData, false);

//         //     // var newCircle = new google.maps.Circle({
//         //     //   strokeColor: '#FF0000',
//         //     //   strokeOpacity: 0.8,
//         //     //   strokeWeight: 2,
//         //     //   fillColor: '#FF0000',
//         //     //   fillOpacity: 0.35,
//         //     //   map: this.map,
//         //     //   center: circleData.latLng,
//         //     //   radius: 100,
//         //     //   draggable: true,
//         //     //   editable: true,
//         //     // })

//         //     // newCircle.addListener('click', (event) => {
//         //     //   newCircle.setMap(null);
//         //     // })

//     })
//   }


//   addCircle(event, isNewCircle = true) {
//     const db = firebase.firestore();

//     const latLng = event.latLng;
//     var newCircle = new this.google.maps.Circle({
//       strokeColor: '#FF0000',
//       strokeOpacity: 0.8,
//       strokeWeight: 2,
//       fillColor: '#FF0000',
//       fillOpacity: 0.35,
//       map: this.map,
//       center: latLng,
//       radius: 100,
//       draggable: true,
//       editable: true,
//     });

    

//     if (isNewCircle)
//     {
//       newCircle.setRadius(100)
//     }
//     else
//     {
//       newCircle.id = event.id;
//       newCircle.setRadius(event.radius);
//     }

//     const zonesRef = db.collection('zones_locataires').doc(firebase.auth().currentUser.uid);
//     if (isNewCircle) {  
//       //update counter
//       zonesRef.update({
//         nb: this.nb
//       })
//       //add new circle
//       var newZone = zonesRef.collection('zones').doc();
//       newCircle.id = newZone.id;
//       newZone.set({
//         latLng: { lat: latLng.lat(), lng: latLng.lng() },
//         radius: newCircle.radius
//       });
//     }

//     console.log('event id', newCircle.id);
    
//     newCircle.addListener('click', (e) => {
//       // var newLatLng = {
//       //   lat: e.latLng.lat(),
//       //   lng: e.latLng.lng()
//       // }
//       // this.map.panTo(newLatLng);
//       // this.map.setZoom(13);  
//        console.log('TRying to delete index', newCircle.id);
//       zonesRef.collection('zones').doc(newCircle.id.toString()).delete();
//       newCircle.setMap(null);

//     })

//     newCircle.addListener('radius_changed', (e) => {
//       console.log('radius changed', newCircle.getRadius());
//       var newRadius = newCircle.getRadius();
      
//       if (newRadius > 500)
//       {
//         newRadius = 500;
//       }
//       else if (newRadius < 100 )
//       {
//         newRadius = 100
//       }

//       // newCircle.radius = newRadius;

//       zonesRef.collection('zones').doc(newCircle.id.toString()).update({
//         radius: newRadius
//       });
//     })

//     newCircle.addListener('center_changed', (e) => {
//       console.log('center_changed', newCircle.getCenter());
//       var newCenter = newCircle.getCenter();
//       zonesRef.collection('zones').doc(newCircle.id.toString()).update({
        
//         latLng: {lat: newCenter.lat(), lng: newCenter.lng() }
//       });
//     })

//     this.nb = (this.nb + 1);

//   }

//   render() {
//     return (
//       <div style={{ marginBottom: '50px' }} className="row">
//         <h3 id="localisationTitle" className="text-left titreInfoPerso">Localisation recherchée</h3>
//         <div style={{ marginBottom: '20px' }} className="col-12">
//           <input id="searchBox" className="form-control" placeholder="Choississez une ville" />
//         </div>
//         {/* <div style={{marginBottom: '20px'}} className="col-3">
//           <input id="ville2" className="form-control" placeholder="Ville #2" />
//         </div>
//         <div style={{marginBottom: '20px'}} className="col-3">
//           <input id="ville3" className="form-control" placeholder="Ville #3" />
//         </div> */}
//         <div className="col-12">
//           <div id="mapProfil">

//           </div>
//         </div>
//       </div>
//     )
//   }
// }


// class ProfilLocataire extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       nom: "",
//       prenom: "",
//       score: 20,
//       picture: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",

//       dateNaissance: "",
//       email: "",
//       phone: "",
//       pays: "",
//       ville: "",
//       codePostal: "",
//       situation_familliale: "",
//       statut_professionnel: "",
//       Animaux: ""
//     }
//   }

//   componentDidMount() {
//     // fetch('https://randomuser.me/api/')
//     // .then(results => {
//     //   return results.json();
//     // }).then(data => {
//     //   data = data.results[0];
//     //   this.setState({
//     //     nom: data.name.last,
//     //     prenom: data.name.first,
//     //     score: data.dob.age,
//     //     picture: data.picture.large,

//     //     dateNaissance: data.dob.date,
//     //     email: data.email,
//     //     phone: data.cell,
//     //     pays: data.location.city,
//     //     ville: data.location.state,
//     //     codePostal: data.location.postcode,
//     //     situationFamilliale: data.gender,
//     //     statutCivil: data.nat,
//     //     Animaux: ""
//     //   })
//     // })

//     const user = this.props.user;
//     console.log(user);
//     if (user) {
//       this.setState({
//         email: user.email,
//         picture: user.photoURL,
//         nom: user.displayName,
//       });
//     }

//   }


//   render() {
//     return (
//       <section className="MargeTop" id="accountInfo">
//         <div className="container">
//           <div className="margeProfil">
//             <ProfilInfo history={this.props.history} user={this.props.user} nom={this.state.nom} prenom={this.state.prenom} score={this.state.score} picture={this.state.picture}
//               dateNaissance={this.state.dateNaissance} email={this.state.email} phone={this.state.phone}
//               pays={this.state.pays} ville={this.state.ville} codePostal={this.state.codePostal}
//               situation_familliale={this.state.situation_familliale} statut_professionnel={this.state.statut_professionnel} Animaux={this.state.Animaux} />
//           </div>

//           <div className="row">
//             <div className="col-lg-6 col-12">
//               <HistoriqueLoc />
//             </div>

//             <div className="col-lg-6 col-12">
//               <HistoriqueLoc />
//             </div>
//           </div>
         
//           {/* <Hobbies /> */}

//           {/* <div><InfoProf /></div>
        
//         <RefLocatives /> */}
//           {/* <MapProfil /> */}
//         </div>
//       </section>
//     )
//   }
// }

// function mapStateToProps(state) {
//   if (state.user) {
//     return {
//       user: state.user,
//     }
//   }
// }

// export default connect(mapStateToProps)(ProfilLocataire);