import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import firebase from 'firebase';
import { onUserChange, onUserTypeChange } from '../redux/actions/index';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Snippet } from 'react-instantsearch-dom';
import Dialog from '@material-ui/core/Dialog';


const searchClient = algoliasearch('58O0D8VX1I', '3ed5c82af499313741b62d4874e7c8c9');

class ScoreNav extends Component {
  render() {
    return (
      <span id="scoreNavbar">Score
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star-o" aria-hidden="true" />
        <i className="fa fa-star-o" aria-hidden="true" />
      </span>
    )
  }
}

class ModalSearchMobile extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress = (event) => {
    console.log("Pressed", event);
    if (event.key === "Enter") {
      console.log('pressed here');
    }
  }


  render() {
    return (
      <div class="modal" id="search_modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-full" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <i className="fas fa-arrow-left arrow_back_search" data-dismiss="modal"></i>
              <input id="search_bar_home_mobile" className="form-control" type="text" placeholder="Rechercher un ola" onClick={this.handleKeyPress} />
            </div>
            <div class="modal-body" id="result">
              <div className="row">
                <div className="col-12">
                  <InstantSearch searchClient={searchClient} indexName="demo_ecommerce">
                    <SearchBox />
                    <Hits />
                  </InstantSearch>
                  <span key={"recherche_recent"} className="recherche_recent" onClick={() => { alert('test') }}>Recherches click</span>
                  <hr />
                  <img className="profil_historique" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1537820821/blankProfil_ml3bke.jpg" />
                  <span className="nom_historique">Phanuel Randrianandraina</span>
                  <hr />
                  <img className="profil_historique" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1537820821/blankProfil_ml3bke.jpg" />
                  <span className="nom_historique">Elyeser Randrianandraina</span>
                  <hr />
                  <img className="profil_historique" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1537820821/blankProfil_ml3bke.jpg" />
                  <span className="nom_historique">Jean Marc</span>
                  <hr />
                  <img className="profil_historique" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1537820821/blankProfil_ml3bke.jpg" />
                  <span className="nom_historique">Marie Jeanne</span>
                  <hr />
                  <img className="profil_historique" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1537820821/blankProfil_ml3bke.jpg" />
                  <span className="nom_historique">Ricky Guy</span>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class ModalSearch extends Component {
  state = {
    results: [],
    search: ""
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Pressed Enter");
    }
  }

  handleChange = (event) => {
    this.setState({ search: event.target.value });
  }

  render() {
    const test = <div>test</div>
    return (
      <Dialog open={this.props.openSearchbar} onClose={this.props.onClose } >
        <InstantSearch resultsState={{}} searchClient={searchClient} indexName="profils_locataires">
          <SearchBox autoFocus={true} searchAsYouType={true} />
          <Hits hitComponent={({hit})=> <React.Fragment><span className="nom_historique">  {hit.first_name} {hit.last_name}</span> <hr/> </React.Fragment>} />
        </InstantSearch> 
       </Dialog>
          
    )
  }
}


class ProfilNav extends Component {
  constructor(props) {
    super(props);
    console.log('NAVBARMOBILE', props);
    this.state = {
      show: false
    }
  }

  toggle_dropdown = () => {
    const { show } = this.state;
    this.setState({ show: !show })
    console.log('touched');
  }

  logout() {
    const thisObject = this;
    firebase.auth().signOut();
  }

  componentDidUpdate(props) {
  }
  render() {
    var dropdownToLoad;

    if (this.props.type === 'loc') {
      dropdownToLoad = <DropDownMenuLocataire onBackdroClick={this.toggle_dropdown} />;
    }

    else {
      dropdownToLoad = <DropDownMenuProprietaire onBackdroClick={this.toggle_dropdown} />;
    }
    return (
      <div>
        <div className="navbar-brand">
          <span id="nameNavbar" className="hide_mobile">{this.props.nom} </span>
          <a href="#search_modal" data-toggle="modal"><span className="lnr lnr-magnifier lnrIcon search_icon_mobile hide_desktop"></span></a>
          <ModalSearchMobile />
          {/* <input id="search_bar_home" type="text" placeholder="Rechercher un profil" /> */}
          {/* <br />*/}
          {/* <span id="typeUser">{this.props.type === 'loc'?"Locataire" : "Propriétaire"}</span>  */}
        </div>
        {/* <a onClick={this.logout.bind(this)} className="logoutMobile hvr-grow"><span className="lnr lnr-alarm lnrIcon" /><span id="notifChat">8</span></a> */}
        <i onClick={this.toggle_dropdown} id="show" style={{ cursor: 'pointer' }} className="fas fa-chevron-down dropdown_user"></i>
        <NavLink to="/notification" className="logoutMobile hvr-grow"><span className="lnr lnr-alarm lnrIcon" />{this.props.nbNotifs > 0 ? <span id="notifChat">{this.props.nbNotifs}</span> : null}</NavLink>
        <img id="profilNav" src={this.props.picture} className="d-inline-block align-top" />
        {this.state.show && dropdownToLoad}
      </div>
    )
  }
}

class DropDownMenuProprietaire extends Component {
  render() {
    return (
      <div>
        <div className="dropdown_container">
          <a href="/profil" className="dropdown_text">Identifiant</a>
          <hr className="hr_dropdown" />
          <a href="/critere" className="dropdown_text">Gestion des proprietes</a>
          <hr className="hr_dropdown" />
          <a href="/reviewproprietaire" className="dropdown_text">Evaluations</a>
          <hr className="hr_dropdown" />
          <a href="settings" className="dropdown_text">Parametres</a>
          <hr className="hr_dropdown" />
          <a href="historique_payment" className="dropdown_text">Historique de paiments</a>
          <hr className="hr_dropdown" />
          <a href="/logout" className="dropdown_text"><i class="fas fa-power-off"></i> &nbsp; Deconnexion</a>
        </div>
        <button onClick={this.props.onBackdroClick} className="close_dropdown"></button>
      </div>
    )
  }
}

class DropDownMenuLocataire extends Component {
  render() {
    return (
      <div>
        <div className="dropdown_container">
          <a href="/profil" className="dropdown_text">Identifiant</a>
          <hr className="hr_dropdown" />
          <a href="/reviewlocataire" className="dropdown_text">Evaluations</a>
          <hr className="hr_dropdown" />
          <a href="settings" className="dropdown_text">Parametres</a>
          <hr className="hr_dropdown" />
          <a href="historique_payment" className="dropdown_text">Historique de paiments</a>
          <hr className="hr_dropdown" />
          <a href="/logout" className="dropdown_text"><i class="fas fa-power-off"></i> &nbsp; Deconnexion</a>
        </div>
        <button onClick={this.props.onBackdroClick} className="close_dropdown"></button>
      </div>
    )
  }
}

class ChatNav extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <NavLink to="/message-lists" style={{ color: '#fff !important', marginBottom: 10 }} className="mobileIcon margin-notif"><span className="lnr lnr-bubble lnrIcon" /><span id="notifChat" style={{ display: 'none' }}>{this.props.nbNotifs}</span></NavLink>
    )
  }
}

class PanierNav extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <NavLink to="/panier" style={{ color: '#fff !important', marginTop: '-8px !important;' }} className="mobileIcon margin-notif"><span className="lnr lnr-heart lnrIcon" /><span id="notifChat">{this.props.nbItemPanier}</span></NavLink>
    )
  }
}

class NotifNav extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <NavLink to="/notification" style={{ color: '#fff !important', marginBottom: 10 }} className="mobileIcon margin-notif"><span className="lnr lnr-alarm lnrIcon" />{this.props.nbNotification > 0 ? <span id="notifChat">{this.props.nbNotification}</span> : null}</NavLink>
    )
  }
}

class GeolocalisationLocataire extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <NavLink to="/critere" style={{ color: '#fff !important', marginTop: '-8px' }} className="nav-link hvr-grow nav-link-item"><span className="lnr lnr-map-marker lnrIcon" /></NavLink>
    )
  }
}

class GeolocalisationProprietaire extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <NavLink to="/critere" style={{ color: '#fff !important', marginTop: '-8px' }} className="nav-link hvr-grow nav-link-item"><span className="lnr lnr-map-marker lnrIcon" /></NavLink>
    )
  }
}

class SignUpBtn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <li style={{ paddingTop: '5px' }} className="nav-item active">
        <NavLink to="/signup">
          <button className="btn btn-default sinscrire"> S'inscrire </button>
        </NavLink>
      </li>
    )
  }
}

class LoginBtn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <li style={{ paddingTop: '5px' }} className="nav-item">
        <NavLink to="/login">
          <button className="btn btn-default login">
            <i className="fa fa-lock" aria-hidden="true" /> &nbsp; Se connecter </button>
        </NavLink>
      </li>
    )
  }
}

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",
      nbNotifsChat: 5,
      nbItemPanier: 2,
      nbNotification: 0,
      typeUser: "",
    }
  }



  async countNbNotifications() {
    const uid = firebase.auth().uid;
    const firestore = firebase.firestore();
    const notifications = await firestore.collection("users/" + uid + "/notifications/").get();
    const nbNotifications = notifications.size;
    return nbNotifications;
  }

  componentDidUpdate() {
    const user = this.props.user;
  }

  logout() {
    const thisObject = this;
    firebase.auth().signOut()//.then (() => thisObject.props.onUserChange(null) );
    thisObject.props.onUserChange(null);
  }

  render() {
    // console.log("LOGGED", this.props.user);
    var profilNav = (this.props.user.logged ?
      <ProfilNav nom={this.props.user.displayName} prenom={this.props.user.prenom} picture={this.props.user.photoURL} type={this.props.user.type} nbNotifs={this.props.nbNotifs} />
      : <ProfilNav nom="Inconnu" prenom="Inconnu" picture={this.state.picture} />);

    var pageToLoad, typeUtilisateur, LoginBtn = <span></span>, SignUpBtn = <span></span>;
    typeUtilisateur = this.props.user.type;
    // console.log(this.props.nbNotifsChat);

    if (typeUtilisateur == "loc") {
      pageToLoad = <GeolocalisationLocataire />;
    }
    else if (typeUtilisateur != "loc") {
      pageToLoad = <GeolocalisationProprietaire />;
    }

    //BUG
    if (this.props.user.logged) {
      // LoginBtn = <LoginBtn />;
      // SignUpBtn = <SignUpBtn />
    }

    return (
      <div>
        <nav className="navbar navbar-toggleable-md navbar-light" id="mainNav">
          <a class="navbar-brand js-scroll-trigger hide_mobile" href="/">
            <img src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Flogo_iloc.svg?alt=media&token=f59e2df8-26df-4565-8969-7d6f8f5393f5" class="d-inline-block align-top logoMain" alt="" />
          </a>
          <span className="show_mobile">{profilNav}</span>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item selection-nav">
                <NavLink to="/" style={{ color: '#fff !important', marginTop: '-8px' }} className="nav-link hvr-grow nav-link-item"><span className="lnr lnr-home lnrIcon" /></NavLink>
              </li>

              <li className="nav-item selection-nav">
                <NavLink to="/profil" style={{ color: '#fff !important', marginTop: '-8px' }} className="nav-link hvr-grow nav-link-item"><span className="lnr lnr-user lnrIcon" /></NavLink>
              </li>

              <li className="nav-item selection-nav">
                {pageToLoad}
              </li>

              <li className="nav-item selection-nav">
                <PanierNav nbItemPanier={this.state.nbItemPanier} />
              </li>

              <li style={{ display: 'none' }} className="nav-item selection-nav">
                <NavLink to="/settings" style={{ color: '#fff !important', marginTop: '-8px' }} className="nav-link hvr-grow nav-link-item"><span className="lnr lnr-cog lnrIcon" /></NavLink>
              </li>

              <li style={{ display: 'block' }} className="nav-item selection-nav">
                <ChatNav nbNotifsChat={this.props.nbNotifsChat} />
              </li>

              <li className="nav-item selection-nav notificationDesktop">
                <NotifNav nbNotification={this.props.nbNotifs} />
              </li>
              <li className="nav-item selection-nav notificationDesktop">
                <a href="#search_modal_desktop" onClick={()=>this.setState({openSearchbar: true})} style={{ color: '#fff !important', marginTop: '-8px', marginRight: '10px' }} className="nav-link hvr-grow nav-link-item"><span className="lnr lnr-magnifier lnrIcon" /></a>
                {/* <input id="search_bar_home" type="text" placeholder="Rechercher un profil" /> */}
              </li>
              {/* {SignUpBtn}
              {LoginBtn}*/}
              {profilNav}
              <ModalSearch openSearchbar={this.state.openSearchbar} onClose={()=>this.setState({openSearchbar:false})} />
            </ul>
          </div>
        </nav>

        <nav className="navbar fixed-bottom navbar-light" id="navMetallicMobile">
          <div className="row text-center">
            <div className="col-2 smallPaddingMobile">
              <NavLink to="/" style={{ color: '#fff !important' }} className="mobileIcon"><span className="lnr lnr-home lnrIcon" /></NavLink>
            </div>
            <div className="col-2 smallPaddingMobile">
              <NavLink to="/profil" style={{ color: '#fff !important' }} className="mobileIcon"><span className="lnr lnr-user lnrIcon" /></NavLink>
            </div>
            <div className="col-2 smallPaddingMobile">
              {/* <NavLink to="/geolocalisation" style={{ color: '#fff !important' }} className="mobileIcon"><span className="lnr lnr-map-marker lnrIcon" /></NavLink> */}
              {pageToLoad}
            </div>
            <div className="col-2 smallPaddingMobile">
              <PanierNav nbItemPanier={this.state.nbItemPanier} />
            </div>
            <div className="col-2 smallPaddingMobile">
              <ChatNav nbNotifs={this.props.nbNotifs} />
            </div>
          </div>
        </nav>


      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log("MAP TO STATE REFRESH", state);
  if (state.user) {
    return {
      user: state.user,
      logged: state.logged,
      nbNotifs: state.user.nbNotifs
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onUserChange, onUserTypeChange }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
