

export function initialize() {
  return {
    type:"INIT"
  }
}

export function onUserChange(user) {
   console.log("USERCHANGE", user);
  return {
    type: 'USER_CHANGE',
    user: {
      displayName: user.displayName,
      email: user.email,
      logged: user.logged,
      photoURL: user.photoURL,
      refreshToken: user.refreshToken,
      uid:user.uid
    }
  };
}

export function onUserTypeChange(userType) {
  console.log("USERTYPECHANGE", userType);
  return {
    type: 'USER_TYPE_CHANGE',
    payload: userType
  }
}

export function onRefreshNotifs(user) {
  // console.log("REFRESH_NOTIFIS", user)
  return{
    type: 'REFRESH_NOTIFS',
    user: user
  }
}

