import React, { Component } from 'react';
import {connect} from 'react-redux';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import fire from '../js/fire'
import {combineReducers, bindActionCreators} from 'redux';
import {onUserChange} from '../redux/actions/index';
import {firebaseUI} from './signup';


class Portail extends Component {

  render() {
    return (
      <header id="homeHeaderLogin">
        <div style={{top: '55%'}} className="header-content text-center">
          <div className="header-content-inner text-center">
            <p id="connexionTitleLogin">Portail Employeur</p>
            
            <div style={{cursor: 'default'}} id="loginBox">
              <div>
                <input id="inputLogin" type="text" placeholder="Courriel" />
                <i className="fa fa-arrow-circle-o-right loginIconLogin" aria-hidden="true" />
              </div>
              <div id="boxMdpLogin">
                <input id="inputLoginMdpLogin" placeholder="Mot de passe" type="password" />
                <a href="geolocalisation.html"><i className="fa fa-arrow-circle-o-right loginIconMdpLogin" aria-hidden="true" /></a>
              </div>
                <div style={{marginLeft: '30px'}} id="firebaseui-auth-container"> </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}


export default Portail;
