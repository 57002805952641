import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import fire from '../js/fire'
import { combineReducers, bindActionCreators } from 'redux';
import { onUserChange } from '../redux/actions/index';
import { firebaseUI } from './signup';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const functions = firebase.functions();
const firestore = firebase.firestore();
const getUserInfo = functions.httpsCallable('getUserInfo');

class ReviewProprietaire extends Component {

    state = {
        evaluationDataMap: new Map(),
        critereProp1: 0,
        critereProp2: 0,
        critereProp3: 0,
        critereProp4: 0,
        proprio: {},
        sendState: "before",
        appartData: {}
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        firestore.collection('prop_loc').where("prop", '==', this.props.user.uid).get()
            .then(async (docs) => {
                docs.forEach(async (propLocRef) => {
                    const propLocData = propLocRef.data();
                    let evaluationData = {}
                    evaluationData = {
                        locataire: { id: propLocData.loc },
                        appartment: { id: propLocData.locId }
                    }
                    console.log("DOCS", evaluationData);
                    await firestore.collection('apparts').doc(evaluationData.appartment.id).get()
                        .then(appartRef => {
                            const appartData = appartRef.data();
                            evaluationData.appartment.address = appartData.d.adresse
                        })

                    await getUserInfo(evaluationData.locataire.id).then(userRef => {
                        const userData = userRef.data;
                        evaluationData.locataire.displayName = userData.displayName;
                    })

                    const evaluationDataCopy = this.state.evaluationDataMap;
                    evaluationDataCopy.set(this.props.user.uid + '_' + evaluationData.locataire.id, evaluationData)
                    console.log("evaluationDataCopy ", evaluationDataCopy);
                    this.setState({
                        evaluationDataMap: evaluationDataCopy
                    })
                })
            })
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
      };
    
      sendEval = ()=> {
        this.setState({
          sendState: "sending"
        })
        const ratings = {
          critereProp1: this.state.critereProp1,
          critereProp2: this.state.critereProp2,
          critereProp3: this.state.critereProp3,
          critereProp4: this.state.critereProp4,
        }
        const data_to_send = {
          from : this.props.user.uid,
          to: this.state.proprio.uid,
          ratings,
          type: "prop"
        }
        const sendEval = functions.httpsCallable('receiveEval');
        sendEval(data_to_send)
        .then((message)=>{
          console.log(message);
          this.setState({
            sendState: "sent"
          })
        })
      }

    render() {
        let evals = [];
        {this.state.evaluationDataMap.forEach((value, key) => {
            evals.push( <div className="col-lg-6 col-md-6 col-12">
                <div className="notification_container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="name_favoris">{value.locataire.displayName}</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="name_favoris">{value.appartment.address}</p>
                        </div>
                        <div className="col-12 text-right">
                            <hr />
                            <br />
                            <span data-toggle="modal" data-target="#addEvaluation" className="primary_btn">Évaluer</span>
                        </div>
                    </div>
                </div>
            </div>)
        })}

        // $(function () {
        //     $('[data-toggle="popover"]').popover()
        // })
    
        // let modalButton =
        // <Button onClick={this.sendEval} variant="contained" component="span" color="primary">    
        //     Évaluer 
        // </Button> ;
        // if (this.state.sendState == "sending")
        // {
        //     modalButton = <CircularProgress/>
        // }
        // else if (this.state.sendState == "sent")
        // {
        // modalButton = <Button variant="contained" component="span" color="primary" data-dismiss="modal" aria-label="Close">    
        //     Fermer 
        // </Button>
        // }
        return (
            <div>
                <header id="notification_header">
                    <div className="container" style={{ zIndex: '500' }}>
                        <h1 className="critere_title"> Évaluation de mes locataires </h1>
                    </div>
                </header>

                <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                    <div className="all_notification_continer">
                        <div className="row">

                         {evals}

                        </div>
                    </div>
                </div>

                <div class="modal fade" id="addEvaluation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Laissez une évaluation</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{color: 'black'}}>×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <TextField
                                            label="Critère 1 (Communication)"
                                            value={this.state.critereProp1}
                                            onChange={this.handleChange('critereProp1')}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                        />
                                        <hr />
                                    </div>

                                    <div className="col-12">
                                        <TextField
                                            label="Critère 2 (État de l'appartement)"
                                            value={this.state.critereProp2}
                                            onChange={this.handleChange('critereProp2')}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                        />
                                        <hr />
                                    </div>

                                    <div className="col-12">
                                        <TextField
                                            label="Critère 3 (Respect du contrat)"
                                            value={this.state.critereProp3}
                                            onChange={this.handleChange('critereProp3')}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                        />
                                        <hr />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            label="Critère 4 (Disponibilité)"
                                            value={this.state.critereProp4}
                                            onChange={this.handleChange('critereProp4')}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                        />
                                        <hr />
                                    </div>

                                    <div className="col-12">
                                        <h4 className="titre-critere">Laissez votre commentaire</h4>
                                        <textarea rows="4" id="description" className="form-control" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {/* {modalButton} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    if (state.user) {
        return {
            user: state.user,
        }
    }
}
export default connect(mapStateToProps)(ReviewProprietaire);


