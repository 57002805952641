import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import google from 'google-maps';
import firebase from 'firebase';
import MarketClusterer from '@google/markerclusterer'
import $ from 'jquery';
import { connect } from 'react-redux';
import { GeoCollectionReference, GeoFirestore, GeoQuery, GeoQuerySnapshot } from 'geofirestore';
// import 'rc-slider/assets/index.css';
// import 'rc-tooltip/assets/bootstrap.css';
import ReactDOM from 'react-dom';
import Slider from 'react-rangeslider'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ButtonMaterial from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/EditOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteOutlined';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from '@material-ui/core';

const db = firebase.firestore();
const functions = firebase.functions();
const getUserInfo = functions.httpsCallable('getUserInfo');
const firestore = firebase.firestore();
const geofirestore = new GeoFirestore(firestore);
const batch = firestore.batch();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 0,
    flex: 0.5,
    color: 'white'
  },
};

const googleMapsStyle = [{
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#f5f5f5"
    }
  ]
},
{
  "elementType": "labels.icon",
  "stylers": [
    {
      "visibility": "off"
    }
  ]
},
{
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#616161"
    }
  ]
},
{
  "elementType": "labels.text.stroke",
  "stylers": [
    {
      "color": "#f5f5f5"
    }
  ]
},
{
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#bdbdbd"
    }
  ]
},
{
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#eeeeee"
    }
  ]
},
{
  "featureType": "poi",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#757575"
    }
  ]
},
{
  "featureType": "poi.park",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#e5e5e5"
    }
  ]
},
{
  "featureType": "poi.park",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#9e9e9e"
    }
  ]
},
{
  "featureType": "road",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#ffffff"
    }
  ]
},
{
  "featureType": "road.arterial",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#757575"
    }
  ]
},
{
  "featureType": "road.highway",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#dadada"
    }
  ]
},
{
  "featureType": "road.highway",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#616161"
    }
  ]
},
{
  "featureType": "road.local",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#9e9e9e"
    }
  ]
},
{
  "featureType": "transit.line",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#e5e5e5"
    }
  ]
},
{
  "featureType": "transit.station",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#eeeeee"
    }
  ]
},
{
  "featureType": "water",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#c9c9c9"
    }
  ]
},
{
  "featureType": "water",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#9e9e9e"
    }
  ]
}];

const is_studio = $("#is_appartement").prop('checked');
const is_condo = $('#is_condo').prop('checked');
const is_maison = $('#is_maison').prop('checked');
const is_chalet = $('#is_chalet').prop('checked');
const is_collocation = $('#is_collocation').prop('checked');
const is_3demi = $('#is_3demi').prop('checked');
const is_4demi = $('#is_4demi').prop('checked');
const is_5demi = $('#is_5demi').prop('checked');
const is_plus = $('#is_plus').prop('checked');

const is_student = $('#is_student').prop('checked');
const is_employed = $('#is_employed').prop('checked');
const is_contractuel = $('#is_contractuel').prop('checked');
const is_statutTemp = $('#is_statutTemp').prop('checked');
const is_tourist = $('#is_tourist').prop('checked');

const nb_chats = $('#nb_chats').prop('value');
const nb_chiens = $('#nb_chiens').prop('value');

const zoneRadius = $('#zoneradius').val();

const prixMin = $('#prixMin').val();
const prixMax = $('#prixMax').val();



class Horizontal extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      value: 10
    }
  }

  handleChangeStart = () => {
    console.log('Change event started')
  };

  handleChange = value => {
    this.setState({
      value: value
    })
  };

  handleChangeComplete = () => {
    console.log('Change event completed')
  };

  render() {
    const { value } = this.state
    return (
      <div className='slider'>
        <Slider
          min={0}
          max={50}
          value={value}
          onChangeStart={this.handleChangeStart}
          onChange={this.handleChange}
          onChangeComplete={this.handleChangeComplete}
        />
        <div className='value'><span id="zoneradius">{value}</span> <span>Km</span></div>
      </div>
    )
  }
}

class CritereProprietaire extends Component {

  state = {
    apparts: [],
    openProprioModal: false,
    mapsReady: true,
    map: {},
    searchBox: {},
    autocomplete: {},
    locataires: [],
    clusters: [],
    modal: false,
    markerData: {},
    addPropModal: false,
    geocoder: null
  }
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.toggle = this.toggle.bind(this);
    this.promiseReceivingGoogleMaps = this.promiseReceivingGoogleMaps.bind(this);
    this.setMapAndSearchBox = this.setMapAndSearchBox.bind(this);
    this.addMarker = this.addMarker.bind(this);

  }



  addPropControl = (controlDiv, map) => {

    // Set CSS for the control border.
    var controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginLeft = '13px';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Click to add a propery';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    var controlText = document.createElement('div');
    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML = 'Ajouter une propriété';
    controlUI.appendChild(controlText);


  }

  async componentDidMount() {
    this.google = await this.promiseReceivingGoogleMaps();
    this.setMapAndSearchBox(this.google);
    const uid = this.props.user.uid;
    firestore.collection('apparts').where('d.proprio', '==', uid).get()
      .then(querySnap => {
        querySnap.docs.map(appartDoc => {
          const data = appartDoc.data();
          const coord = data.d.coordinates;
          const address = data.d.adresse;
          const position = { lat: coord.latitude, lng: coord.longitude };
          const appartId = appartDoc.id;
          const markerData = {
            position,
            appartId,
            address
          }
          this.addMarker(markerData);
        })
      })
  }

  handleModalProprioClose = () => {
    this.setState({
      openProprioModal: false
    })

  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  promiseReceivingGoogleMaps = (maps) => {
    return new Promise((maps) => {
      return google.load(maps)
    })
  }

  addMarker(markerData) {
    const { position } = markerData;
    const marker = new this.google.maps.Marker({
      position,
      animation: this.google.maps.Animation.DROP,
      map: this.map,
      icon: "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fmapmarker_home.png?alt=media&token=57ba1e39-cb47-450e-85f5-eb004c19636e"
    });
    markerData.marker = marker;
    //Click listener with broken zoom animation
    marker.addListener('click', () => {
      this.map.setCenter(position);

      //animation
      let actualZoom = this.map.getZoom();
      const animate = setInterval(() => {
        this.map.setZoom(actualZoom);
        actualZoom += 2;
        if (actualZoom > 13) {
          clearInterval(animate);
          this.setState({
            openProprioModal: true,
            markerData: markerData
          })
        }

      }, 100);

      //Open Modal


    })
  }

  // geocodePromise = (address) => new Promise((resolve) => {
  //   return this.geocoder.geocode({ address: address }, (results, status) => {
  //     if (status === 'OK') {
  //       resolve({
  //         lat: results[0].geometry.location.lat(),
  //         lng: results[0].geometry.location.lng()
  //       });
  //     }
  //   })
  // }
  // )

  setMapAndSearchBox(google) {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: 45.516136,
        lng: -73.65683
      },
      zoom: 3,
      mapTypeId: 'roadmap',
      styles: googleMapsStyle
    });
    this.map = map;
    var geocoder = new google.maps.Geocoder();
    this.setState({
      geocoder: geocoder
    })

    let processedUsers = new Map();
    let promises = [];
    let newCircles = [];
    let marketCluster = new MarketClusterer(this.map, [], { imagePath: 'https://raw.githubusercontent.com/gmaps-marker-clusterer/gmaps-marker-clusterer/master/images/m' })




    var input = document.getElementById('map_input');

    var searchBox = new google.maps.places.SearchBox(input);
    searchBox.bindTo('bounds', map);
    this.setState({
      map: map,
      searchBox: searchBox,

    })

    map.addListener('bounds_changed', function () {
      //searchBox.setBounds(map.getBounds());
    }.bind(this));

    searchBox.addListener('places_changed', function () {
      var place = searchBox.getPlaces()[0];

      if (place.length == 0) {
        return;
      }

      var bounds = new google.maps.LatLngBounds();
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      map.fitBounds(bounds);
    })


    //Map controls
    var propAddControlDiv = document.createElement('div');
    var propAddControl = new this.addPropControl(propAddControlDiv, map);

    propAddControlDiv.index = 1;
    propAddControlDiv.addEventListener('click', () => {
      this.setState({
        addPropModal: true
      })
    })
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(propAddControlDiv);

  }

  handleClick() {
    const new_critere =
    {
      is_studio: is_studio,
      is_condo: is_condo,
      is_maison: is_maison,
      is_chalet: is_chalet,
      is_collocation: is_collocation,
      is_3demi: is_3demi,
      is_4demi: is_4demi,
      is_5demi: is_5demi,
      is_plus: is_plus,

      is_student: is_student,
      is_professional: is_employed,
      is_contractuel: is_contractuel,
      is_statutTemp: is_statutTemp,
      is_tourist: is_tourist,

      nb_chats: nb_chats,
      nb_chiens: nb_chiens,

      zoneRadius: zoneRadius,

      prixMin: prixMin,
      prixMax: prixMax,
    }

    db.collection('criteres_proprietaire').doc(firebase.auth().currentUser.uid).set(new_critere);
  }

  clickedVoirProfil = (userRecord) => {
    console.log("Clicked on ", userRecord);
    this.setState({
      clickedProfile: userRecord
    })
    this.toggle();
  }

  launchSearch = () => {
    console.log('searching');
  }

  render() {
    var locatairesInfo = Object.values(this.state.locataires).map((userRecord, index) => {
      return (
        <div key={userRecord.uid} className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
          <div className="row">
            <div className="col-12">
              <div class="critere_box">
                <div className="col-12 text-center">
                  <p className="nomLocataire">
                    <NavLink to={"/profilLoc/" + userRecord.uid}>{userRecord.displayName}</NavLink>
                  </p>
                </div>
                <div className="col-12 text-center">
                  <span style={{ cursor: 'pointer' }} className="text-right" onClick={() => this.clickedVoirProfil(userRecord)}>Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                  <br id="br-critere" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    });

    return (
      <div>
        <section style={{ paddingBottom: '50px' }} className="MargeTop" id="mapPageContainerP">
          {/* <NavLink to="/geolocalisation"><img id="backbtn" src="https://img.icons8.com/ios/30/000000/left.png" /></NavLink> */}
          <header id="critere_header">
            <div className="container" style={{ zIndex: '500' }}>
              <h1 className="critere_title"> Trouvez le locataire qui vous convient !</h1>
              <div className="critere_container">
                <input id="map_input" className="form-control input_critere" type="text" placeholder="Rechercher par région, ville, rue" />
                <br />
                <br />
                {/* <span className="critere_search_button"><span onClick={this.launchSearch} className="critere_search_text">Rechercher</span><i className="lnr lnr-magnifier search_icon" /></span> */}
                {/* <p style={{ margin: '20px auto' }}>Barre de prix</p> */}
                {/* <p className="critere_advanced">
                  Critères avancés
              <br />
                  <i className="fas fa-chevron-down" />
                </p> */}
              </div>
            </div>
          </header>

          <div className="container-fluid" style={{ padding: '0' }}>
            <div style={{ marginLeft: '0px', boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.4)', padding: '0px', borderRadius: '10px' }} className="col-12">
              {this.state.mapsReady && <div style={{ marginTop: 0 }} id="map"></div>}
            </div>
          </div>

          <div className="container">
            <div>
              <div style={{ paddingLeft: 0, marginTop: '50px' }} className="col-12">
                <p className="resultat_recherche_title">Voici les résultats de la recherche</p>
              </div>
            </div>
            <div>
              <div className="row">
                {/* {locatairesInfo} */}
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="row">
                    <div className="col-12">
                      <div class="critere_box">
                        <div className="col-12 text-center">
                          <p className="nomLocataire">
                            <NavLink>Phanuel Randrianandraina</NavLink>
                          </p>
                        </div>
                        <div className="col-12 text-center">
                          <span style={{ cursor: 'pointer' }} className="text-right">Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                          <br id="br-critere" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="row">
                    <div className="col-12">
                      <div class="critere_box">
                        <div className="col-12 text-center">
                          <p className="nomLocataire">
                            <NavLink>Elyeser Randrianandraina</NavLink>
                          </p>
                        </div>
                        <div className="col-12 text-center">
                          <span style={{ cursor: 'pointer' }} className="text-right">Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                          <br id="br-critere" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="row">
                    <div className="col-12">
                      <div class="critere_box">
                        <div className="col-12 text-center">
                          <p className="nomLocataire">
                            <NavLink>Marie Jeanne</NavLink>
                          </p>
                        </div>
                        <div className="col-12 text-center">
                          <span style={{ cursor: 'pointer' }} className="text-right">Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                          <br id="br-critere" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="row">
                    <div className="col-12">
                      <div class="critere_box">
                        <div className="col-12 text-center">
                          <p className="nomLocataire">
                            <NavLink>Claude Martin</NavLink>
                          </p>
                        </div>
                        <div className="col-12 text-center">
                          <span style={{ cursor: 'pointer' }} className="text-right">Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                          <br id="br-critere" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="row">
                    <div className="col-12">
                      <div class="critere_box">
                        <div className="col-12 text-center">
                          <p className="nomLocataire">
                            <NavLink>Pierre Eric</NavLink>
                          </p>
                        </div>
                        <div className="col-12 text-center">
                          <span style={{ cursor: 'pointer' }} className="text-right">Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                          <br id="br-critere" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="row">
                    <div className="col-12">
                      <div class="critere_box">
                        <div className="col-12 text-center">
                          <p className="nomLocataire">
                            <NavLink>Gustave Paul</NavLink>
                          </p>
                        </div>
                        <div className="col-12 text-center">
                          <span style={{ cursor: 'pointer' }} className="text-right">Aperçu du profil &nbsp; <img src="https://img.icons8.com/ios-glyphs/30/000000/user-location.png" /></span>
                          <br id="br-critere" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <NavLink to="/IlocMatch" className="matchBtn">Matches</NavLink>
              </div>
            </div> */}
          </div>
        </section>

        <div>
          <ProprioModal uid={this.props.user.uid} clickedProperty={this.state.markerData} onClose={this.handleModalProprioClose} classes={this.props.classes} open={this.state.openProprioModal} />
          <AddPropertyDialog addMarker={this.addMarker} uid={this.props.user.uid} open={this.state.addPropModal} handleClose={() => this.setState({ addPropModal: false })} geocoder={this.state.geocoder} />
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader style={{ backgroundColor: '#205193', color: 'white' }} toggle={this.toggle}>Profil de locataire</ModalHeader>
            <ModalBody style={{ backgroundColor: '#205193' }}>
              {this.state.clickedProfile &&

                <div className="row">
                  <div id="profil-container" className="col-12 no-padding-modal panier-profil-container-propri">
                    <img className="panier-profil-img" src={this.state.clickedProfile.photoURL} />
                    <span className="profil-panier-name">{this.state.clickedProfile.displayName}</span>
                    <br />
                    <br />
                    <div className="card" id="profilRight">
                      <div className="card-header">
                        <h3 style={{ margin: '0 auto' }} className="text-center titreInfoPerso">CRITÈRES DE RECHERCHE</h3>
                      </div>
                      <div className="card-body paddingAllMobile">
                        <div className="row text-left">
                          <div className="col-lg-6 col-12">
                            <p><span id="infoPersoTitre"> Ville: </span> {this.state.city} </p>
                            <p><span id="infoPersoTitre"> Type de logement: </span>   </p>
                            <p><span id="infoPersoTitre"> Collocation: </span>   </p>
                          </div>

                          <div className="col-lg-6 col-12">
                            <p><span id="infoPersoTitre"> Prix: </span>  </p>
                            <p><span id="infoPersoTitre"> Animaux: </span> {this.state.Animaux}  </p>
                            <p><span id="infoPersoTitre"> Hobbies: </span> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </ModalBody>
            <ModalFooter style={{ backgroundColor: '#205193' }}>
              <div className="col-12 no-padding-modal panier-choix-container">
                <div className="row">
                  <div className="col-6 text-center">
                    <span id="closeBtn">
                      {/* <span className="pulse"></span> */}
                      <span className="lnr lnr-cross"></span>
                    </span>
                  </div>
                  <div className="col-6 text-center">
                    <span id="addFavorite">
                      {/* <span className="pulse"></span> */}
                      <span className="lnr lnr-heart">
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}


class ProprioModal extends Component {

  state = {
    nbPotLocataires: 0,
    potLocataires: [],
    potLocatairesUid: [],
    favorites: new Map()
  }

  constructor(props) {
    super(props);
  }

  async componentWillReceiveProps(props) {

    const geoCollection = geofirestore.collection('zones_locataires');
    // console.log('clicked', props)
    if (props.clickedProperty && props.open) { //onopen
      //Getting interested profiles
      const query = geoCollection.near({
        center: new firebase.firestore.GeoPoint(props.clickedProperty.position.lat, props.clickedProperty.position.lng),
        radius: 3
      })

      const potLocatairesTemp = this.state.potLocataires.slice();
      const potLocatairesUidTemp = new Set();

      await query.get().then(async (value) => {
        const nbPotentialLocataires = value.size;
        value.docs.map(async (snap) => {
          const data = snap.data();
          const uid = data.uid;
          potLocatairesUidTemp.add(uid);
        })
      });
      this.setState({
        potLocataires: potLocatairesTemp,
        potLocatairesUid: potLocatairesUidTemp
      });
      potLocatairesUidTemp.forEach(uid => {
        getUserInfo(uid).then(dataSnap => {
          const data = dataSnap.data;
          potLocatairesTemp.push(data);
          this.setState({
            potLocataires: potLocatairesTemp,
          })
        })
      });

      //getting favorites
      firestore.collection('favorites_prop').doc(this.props.uid).get()
        .then(snap => {
          const data = snap.data();
          data.favorites.map(uid => {
            const favoritesState = this.state.favorites;
            this.setState({
              favorites: favoritesState.set(uid, true)
            })
          })
        })

    }

    //Closing the modal
    if (!props.open) {
      this.setState({
        potLocataires: [],
        potLocatairesUid: []
      })
      await firestore.collection('favorites_prop').doc(this.props.uid).get()
        .then(async (snap) => {
          if (!snap.exists) {
            await snap.ref.set({
              favorites: []
            })
          }
          this.state.favorites.forEach(async (value, uid, map) => {
            if (value) { //add favorite
              await snap.ref.update({
                favorites: firebase.firestore.FieldValue.arrayUnion(uid)
              })
            }
            else {
              await snap.ref.update({
                favorites: firebase.firestore.FieldValue.arrayRemove(uid)
              })
            }
          })
        })
    }

  }

  componentWillUnmount() {
    this.setState({
      potLocataires: [],
      potLocatairesUid: [],
      deleteAlertOpen: false
    })
  }

  handleFavoriteChange = (uid, status) => {
    const favoritesTemp = this.state.favorites;

    // favoritesTemp.set(uid,status);

    this.setState({
      favorites: favoritesTemp.set(uid, status)
    })
  }

  handleDelete = () => {
    const { appartId, marker } = this.props.clickedProperty;
    firestore.collection('apparts').doc(appartId).delete().then(() => {
      console.log('AppartDeleted');
      marker.setMap(null);
      this.setState({ deleteAlertOpen: false });
      this.props.onClose();
    })
      .catch((e) => {
        console.error('Error deleting appart', e);
      })
  }

  render() {
    const potLocatairesComp = this.state.potLocataires.map(potLocataire => {
      return <Fragment key={potLocataire.uid}>
        <ListItem button>
          <ListItemAvatar>
            <Avatar alt={potLocataire.displayName} src={potLocataire.photoURL} />
          </ListItemAvatar>
          <NavLink to={"/profilLoc/"+potLocataire.uid}><ListItemText primary={potLocataire.displayName} secondary={potLocataire.uid} /></NavLink>
          <ListItemIcon >
            <FavoriteIconButton favorite={this.state.favorites.get(potLocataire.uid)} handleFavoriteChange={this.handleFavoriteChange} from={this.props.uid} to={potLocataire.uid} />
          </ListItemIcon>
        </ListItem>
        <Divider />
      </Fragment>
    })

    return (
      <Fragment>
        <Dialog fullScreen onClose={this.props.onClose} open={this.props.open} TransitionComponent={Transition}>
          <DialogTitle style={{ textAlign: 'center' }} id="scroll-dialog-title">Profils intéréssés <br /> <span style={{ fontWeight: 400, fontSize: 12 }}>{this.props.clickedProperty.address || ""}</span></DialogTitle>
          <IconButton style={{ textAlign: 'center' }} className="editBtn text-center" href={`profilAppart/${this.props.clickedProperty.appartId}`} > <EditIcon className="text-center">Edit</EditIcon> </IconButton>
          <br />
          <DialogContent dividers={true}>
            <List>
              {potLocatairesComp}
            </List>
            <div style={{ textAlign: 'right', marginTop: '50px' }}>
              <ButtonMaterial onClick={() => { this.setState({ deleteAlertOpen: true }) }} style={{ backgroundColor: 'red', color: 'white', padding: '10px 15px', marginRight: '5px' }}>
                Supprimer propriété
          </ButtonMaterial>
              <ButtonMaterial onClick={() => { this.props.onClose() }} style={{ backgroundColor: '#999', color: 'white', padding: '10px 15px' }}>
                Fermer
          </ButtonMaterial>

            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.deleteAlertOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Supprimer l'appartement?"}</DialogTitle>

          <DialogActions>
            <ButtonMaterial onClick={() => this.setState({ deleteAlertOpen: false })} style={{ backgroundColor: 'white', color: 'black' }}>
              Non
              </ButtonMaterial>
            <ButtonMaterial onClick={this.handleDelete} style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
              Oui
              </ButtonMaterial>
          </DialogActions>
        </Dialog>
      </Fragment>
    )

  }
}

class FavoriteIconButton extends Component {
  state = {
    favorite: this.props.favorite
  }

  constructor(props) {
    super(props);
  }

  handleClick = () => {

    this.setState(prevState => {
      this.props.handleFavoriteChange(this.props.to, !prevState.favorite);
      return this.setState({
        favorite: !prevState.favorite
      })
    }
    );
  }


  render() {
    const style = this.state.favorite ? { color: 'red' } : { color: 'black' };
    return (<Fragment>
      <IconButton onClick={this.handleClick}>
        <FavoriteBorderIcon
          style={style} />
      </IconButton>

    </Fragment>)
  }
}

const initialState = {
  chauffe: false,
  animaux: false,
  eau_chaude: false,
  laveuse_secheuse: false,
  eclairee: false,
  lave_vaisselle: false,
  address: "",
  type: "Chambre",
  loyer: 0,
  descriptif: ""
}

class AddPropertyDialog extends Component {

  state = initialState;

  constructor(props) {
    super(props);
  }

  handleSubmit = () => {
    console.log('submit');
    this.props.geocoder.geocode({ 'address': this.state.address }, (results, status) => {
      if (status === 'OK') {
        const latLng = results[0].geometry.location;
        console.log(latLng);

        let {chauffe, animaux, eau_chaude, lave_vaisselle, laveuse_secheuse, eclairee, type, loyer, descriptif} = this.state;

        const criteres = {
          chauffe,
          animaux,
          eau_chaude,
          lave_vaisselle,
          laveuse_secheuse,
          eclairee,
        }
        
        // if (chauffe) criteres.push('is_chauffe');
        // if (animaux) criteres.push('is_animaux');
        // if (eau_chaude) criteres.push('is_eau_chaude');
        // if (lave_vaisselle) criteres.push('is_lave_vaisselle');
        // if (laveuse_secheuse) criteres.push('is_laveuse_secheuse');
        // if (eclairee) criteres.push('is_eclairee');


        loyer = Number(loyer);
        const position = { lat: latLng.lat(), lng: latLng.lng() };
        const dataToAdd = {
          coordinates: new firebase.firestore.GeoPoint(latLng.lat(), latLng.lng()),
          adresse: this.state.address,
          proprio: this.props.uid,
          type,
          loyer,
          criteres,
          descriptif
        };
        geofirestore.collection('apparts').add(dataToAdd)
          .then(docref => {
            const markerData = {
              position,
              appartId: docref.id,
            }
            this.props.addMarker(markerData);
            this.setState(initialState);
            this.props.handleClose();
          })
          .catch(e => {
            console.log('error', e);
            alert('error', e);
          })

      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} >
        <DialogTitle id="form-dialog-title">Ajout de propriété</DialogTitle>
        <DialogContent>
          <TextField
              id="descriptif"
              label="Descriptif"
              type="text"
              fullWidth
              style={{ marginBottom: 30 }}
              value={this.state.descriptif}
              onChange={this.handleTextChange('descriptif')}
            />
          <TextField
            id="address"
            label="Adresse"
            type="text"
            fullWidth
            style={{ marginBottom: 30 }}
            value={this.state.address}
            onChange={this.handleTextChange('address')}
          />
          <FormControl component="fieldset">

            <InputLabel htmlFor="demo-controlled-open-select">Type</InputLabel>
            <Select
              value={this.state.type}
              onChange={this.handleTextChange('type')}
              input={<Input name="type" id="type-helper" />}
              style={{ marginBottom: 30 }}
            >
              <MenuItem value={"Chambre"}>Chambre</MenuItem>
              <MenuItem value={"1½"}>1½</MenuItem>
              <MenuItem value={"2½"}>2½</MenuItem>
              <MenuItem value={"3½"}>3½</MenuItem>
              <MenuItem value={"4½"}>4½</MenuItem>
              <MenuItem value={"5½"}>5½</MenuItem>
              <MenuItem value={"6½+"}>6½+</MenuItem>
            </Select>
         
          <TextField
            id="LoyerMensuel"
            label="Loyer Mensuel"
            type="text"
            style={{ marginBottom: 25 }}
            value={this.state.loyer}
            onChange={this.handleTextChange('loyer')}
          />
          
            <FormControlLabel
              control={<Checkbox checked={this.state.chauffe} onChange={this.handleChange('chauffe')} value="chauffe" />}
              label="Chauffée"
            />

            <FormControlLabel
              control={<Checkbox checked={this.state.eau_chaude} onChange={this.handleChange('eau_chaude')} value="eau_chaude" />}
              label="Eau chaude"
            />

            <FormControlLabel
              control={<Checkbox checked={this.state.lave_vaisselle} onChange={this.handleChange('lave_vaisselle')} value="lave_vaisselle" />}
              label="Lave-vaisselle"
            />

            <FormControlLabel
              control={<Checkbox checked={this.state.laveuse_secheuse} onChange={this.handleChange('laveuse_secheuse')} value="laveuse_secheuse" />}
              label="Laveuse/sécheuse"
            />

            <FormControlLabel
              control={<Checkbox checked={this.state.animaux} onChange={this.handleChange('animaux')} value="animaux" />}
              label="Accepte animaux"
            />

          </FormControl>

          <DialogActions>
            <ButtonMaterial onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>
              Annuler
          </ButtonMaterial>
            <ButtonMaterial onClick={this.handleSubmit} style={{ backgroundColor: 'green', color: 'white' }}>
              Ajouter
          </ButtonMaterial>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  if (state.user) {
    return {
      user: state.user,
    }
  }
}
export default connect(mapStateToProps)(withStyles(styles)(CritereProprietaire))