import React, { Component } from 'react';
import {connect} from 'react-redux';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import fire from '../js/fire'
import {combineReducers, bindActionCreators} from 'redux';
import {onUserChange} from '../redux/actions/index';
import {firebaseUI} from './signup';
import $ from 'jquery';

class PortailEmployeur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type_proprietaire: "hide",
        }
        this.handleClickGetId = this.handleClickGetId.bind(this);
    }

    handleClickGetId(e) {
        this.setState({ type_proprietaire: e.target.id });
    }
  render() {
    var pageToLoad, type_proprietaire;
    type_proprietaire = this.state.type_proprietaire;

    if (type_proprietaire == "show") {
      $("#show").hide();
      $("#hide").show();
      $("#password_login").attr('type', 'text');
    } else if (type_proprietaire == "hide") {
      $("#show").show();
      $("#hide").hide();
      $("#password_login").attr('type', 'password');
    }
    return (
      <header id="signup_header">
        <div className="container">
          <div className="signup_container">
            <h1 className="signup_title"> Portail employeur</h1>
            <input className="form-control signup_input" type="text" placeholder="Courriel ou nom d'utilisateur" />
            <br />
            <input id="password_login" className="form-control signup_input" type="password" placeholder="Token ID" />
            <i onClick={this.handleClickGetId} id="show" className="fas fa-eye see_password"></i> <i onClick={this.handleClickGetId} id="hide" style={{display: 'none'}} className="fas fa-eye-slash see_password"></i>
            <br />
            <span className="primary_btn mobile_login">Accéder</span>
            <br />
          </div>
        </div>
      </header>
    )
  }
}

export default (PortailEmployeur);
