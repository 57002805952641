import React, { Component } from 'react';
import ProfilLocataire from './profil_locataire';
import ProfilProprietaire from './profil_proprietaire';
import {connect} from 'react-redux';


class Profil extends Component {
  constructor(props) {
    super(props);
    console.log("IN PROFIL HISTORY", this.props)
  }

  componentDidMount() {
  }
  
  render() {
    let pageToLoad, typeUtilisateur;
    typeUtilisateur = this.props.user.type;

    if (typeUtilisateur == "loc")  {
      pageToLoad = <ProfilLocataire history={this.props.history} {...this.props}/>;
    } else {
      pageToLoad = <ProfilProprietaire {...this.props}/>;
    }
    return (
        <div>
         
           {pageToLoad}
        </div>
    )
  }
}
function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps)(Profil);