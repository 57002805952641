import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase';
import fire from '../js/fire';
import $ from 'jquery';
import Chat from 'twilio-chat'
import '../style/chat.css'
import {connect} from 'react-redux';

class MessageItem extends Component {
  constructor(props){
    super(props);
   
  }

  render() {
    return (
        // <li className="me ow"> 
        //     <span id="name">{this.props.message.author}</span>
        //     <span id="">{this.props.message.body}</span>
        // </li>
        <p className="msg ow me">
          {this.props.message.body}
        </p>
    )
  }
}

class Message extends Component {
  constructor(props){
    super(props);
    this.state = {
      message:"",
      messages: [],
      username: null,
      token: "",
      list:[]
    }

    this.listenMessages=this.listenMessages.bind(this);
    this.getToken = this.getToken.bind(this);
    this.initializeTwilioChat = this.initializeTwilioChat.bind(this);
    this.joinChannel = this.joinChannel.bind(this);
    this.listenToNewChannel = this.listenToNewChannel.bind(this);
  }
   

  getToken(firebaseToken) {

    return new Promise((resolve, reject) => {
      var user = this.state.user;
      if (!user)
      {
        user ="RandomGuy"
      }
      console.log(firebaseToken)
      $.getJSON('https://turquoise-mole-8927.twil.io/chat-token', {identity: firebaseToken }, (token) => {
        this.setState({ username: token.identity });
        resolve(token);
        console.log(token);
        this.setState({token: token.token, username: token.identity})
        this.initializeTwilioChat(token.token);
      }).fail(() => {
        reject(Error("Failed to connect."))
      })
    })
      
      
  }

  initializeTwilioChat(token) {
    var Twilio = Chat.create(token)
    .then(client => {
      this.messagingClient = client;
      this.messagingClient.getSubscribedChannels().then(this.joinChannel());
    })
    .catch(error =>
    console.log(error)
  )
  }

  joinChannel(channelToJoin="general")
  {
 
    this.messagingClient.getChannelByUniqueName(channelToJoin)
    .then(channel => {
      this.channel = channel;
      this.listenToNewChannel (channel) ;
      console.log('found channel', channelToJoin);
      if (channel.state.status !== "joined") {
        this.channel.join();
      }
      console.log('getting messages');
      this.channel.getMessages(50).then(messages=>{
        console.log('ALL of them', messages);
        var newList = this.state.list.slice(0);
        for (var i=0; i< messages.items.length; i++)
        {
          newList.push(messages.items[i].state);
        }
        this.setState({
          list: newList
        })
        
      })
    })
    .catch( () => { //channel doesn't exist
      console.log('creating the new channel');
      this.messagingClient.createChannel({
        uniqueName: channelToJoin,
        friendlyName: channelToJoin
      })
      .then(channel =>{
        console.log('created new channel');
        this.channel = channel;
        this.listenToNewChannel (channel) ;
        if (channel.state.status !== "joined") {
        this.channel.join();

        }
      })
      .catch(channel => {
        console.log("can't create channel", channel);
      })
    })
  }

  listenToNewChannel (iNewChannel) {
    iNewChannel.on('messageAdded', this.receiveNewMessage.bind(this));
  }

  receiveNewMessage(iNewMessage){
    var messageList = this.state.list.slice(0);
    messageList.push (iNewMessage);
    this.setState({list: messageList});
  }

  componentDidMount() {
    console.log(fire.auth())
    console.log(this.props.user)
    if (fire.auth().currentUser){
    fire.auth().currentUser.getIdToken(true).then((firebaseToken) =>{
      return (firebaseToken);
    }).then((firebaseToken)=> this.getToken(firebaseToken))
    .catch(error => {
      console.log(error);
    })
  }

  $("#chat-input").keyup(function(){
    $( "#send-icon" ).css( "color", "#1F5193" );
  });

  $("#chat-input").on('input', function() {
    var scroll_height = $("#chat-input").get(0).scrollHeight;
  
    if (scroll_height <= 128) {
      $("#chat-input").css('height', scroll_height + 'px');
      $("#msg-container").css('height', 21 + scroll_height + 'px');
      $("#chat-input").css('overflow', 'hidden');
    }

    else {
      $("#chat-input").css('overflow', 'scroll');
    }
   
  });
}


  listenMessages() {
        this.setState({

        });
  }

  handleChange(event) {
    this.setState({message: event.target.value});
  }
  handleSend() {
    if (this.state.message) {
        this.channel.sendMessage(this.state.message);
        this.setState({message:""});
    }
  }
  handleKeyPress(event) {
    if (event.key !== 'Enter') return;
    this.handleSend();
  }

  render() {
      
      var messages = this.state.list.map((value, index)=>{
        return <MessageItem message={value} key={index} />
      });
    return (
      <div className="container-fluid">
          <nav className="navbar navbar-light bg-light fixed-top" id="navTop">
            <div className="row">
                <div style={{marginBottom: '10px'}} className="col-9">
                <NavLink to="/message-lists"><p id="username-nav"><span id="arrowBack" className="lnr lnr-arrow-left"></span><span id="UsernameNav">Patrick</span></p></NavLink>
                </div>
                <div className="col-3 text-right col-details">
                    <NavLink to="/message-details"><span id="details-nav">Details</span></NavLink>
                </div>
              </div>
        </nav>

        <div className="msg-wrapper">            
            <div id="messages">
            {messages}
              {/* <ul>
                {messages}
              </ul>     */}          
            </div>
        </div>
        {/* <div className="row" id="msg-container">
          <div className="col-12">
            <img src="https://png.icons8.com/ios/50/000000/document.png" />
          </div>
        </div> */}
        <div className="row" id="msg-container">
              <div className="col-lg-1 col-2 text-left">             
                <p id="uplaod-container"><i className="fa fa-plus-circle" id="upload-btn" aria-hidden="true"></i></p>
              </div>
              <div style={{paddingLeft: '5px'}} className="col-lg-11 col-10 text-left">
                <textarea value={this.state.message} onChange={this.handleChange.bind(this)} id="chat-input" type="text" rows="1" data-min-rows='1' placeholder="Write something" autoFocus></textarea>
                <p className="text-right" id="send-container" onClick={this.handleSend.bind(this)}><i className="fa fa-paper-plane" id="send-icon" aria-hidden="true"></i></p>
              </div>

          {/* <div className="col-12">
            <img id="icon-height" className="icons-chat" src="https://png.icons8.com/ios/50/a9a9a9/document.png" />
            <img className="icons-chat" style={{ height: '32px', marginLeft: '50px', marginBottom: '8px' }} src="https://png.icons8.com/windows/50/a9a9a9/picture.png" />
          </div> */}
          </div>        
      </div>
    )

  }
}
function mapStateToProps(state) {
  console.log("IN REDUXMESSAGE", state.user);
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
  export default connect (mapStateToProps) (Message);