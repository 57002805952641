import { stat } from "fs";

const initialState ={
  user:null,
  logged:false
}

export default (state=initialState, action) => {
  switch(action.type){
    case 'USER_CHANGE':
      return {...state, ...action.user};
    case 'USER_TYPE_CHANGE':
      // console.log("USER_TYPE_CHANGE REDUCER", {...state, type:action.payload})
      return {...state, type:action.payload}
    case 'CHANGE_USER_TYPE':
      state.type = action.payload
    case 'NB_NOTIFICATION_FETCHED':
    state = { ...state, ...action.payload, nbNotifs:action.nbNotifs}
    // console.log("FETCHED", state )
      return state; 
    case 'FETCH_NOTIFICATIONS':
      return {...state, ...action.payload };
    case 'REFRESH_NOTIFS':
    // console.log("REDUCER REFRESH", state)
      return {...state, ...action.payload}
    default:
      return state;
  }
}
 