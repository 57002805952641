import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import Calendar from 'react-calendar';

class Calendrier extends Component {
    state = {
        date: new Date(),
    }

    onChange = date => this.setState({ date })

    render() {
        return (
            <div className="text-center">
                <Calendar
                    onChange={this.onChange}
                    value={this.state.date}
                />
            </div>
        );
    }
}

class MessageDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="container-fluid details-container">
                    <br />
                    <div className="row">
                        <div className="col-12">
                            <NavLink to="/settings"><p id="username-nav"><span id="arrowBack" className="lnr lnr-arrow-left"></span></p></NavLink>
                        </div>
                        <br />
                        <br />
                    </div>
                    <div className="col-12">
                        <div className="col-12 text-center">
                            <h1 style={{ margin: '0 auto 20px auto' }} id="contracts-title">Contacts</h1>
                        </div>
                        <div className="row">
                            <div className="col-6 text-center">
                                <div className="btn-container">
                                    <img style={{ width: 30, marginTop: '5px' }} src="https://png.icons8.com/metro/64/ffffff/email.png" />
                                    <p className="text-details">E-Mail</p>
                                </div>
                            </div>

                            <div className="col-6 text-center">
                                <div className="btn-container">
                                    <img style={{ width: 30, marginTop: '5px' }} src="https://png.icons8.com/metro/64/ffffff/phone.png" />
                                    <p className="text-details">Téléphone</p>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                    {/* <hr style={{ width: '75%' }} />
                    <div className="col-12">
                        <div className="col-12 text-center">
                            <h1 style={{ margin: '0 auto 20px auto' }} id="contracts-title">Visite</h1>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="btn-container-question">
                                    <p className="text-question">Les photos représentent elles bien l'appartement ?</p>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <input type="radio" className="checkList" defaultValue="individuel" name="titre" defaultChecked /> <span className="text-left" id="info-question">Oui</span>
                                        </div>
                                        <div className="form-group col-6">
                                            <input type="radio" className="checkList" defaultValue="commercial" name="titre" /> <span className="text-left" id="info-question">Non</span>
                                        </div>
                                    </div>
                                    <br />
                                    <span id="soumettre-question">Soumettre</span>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <br />
                    </div> */}
                    <hr style={{ width: '75%' }} />
                    <br />
                    <div className="row">
                        <div className="col-12">

                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1 style={{ margin: '0 auto 20px auto' }} id="contracts-title">Calendrier</h1>
                                    <Calendrier />
                                    <br />
                                    <p>Du <span id="dateDebut">sam. 30 oct 2018 </span> au <span id="dateFin">sam. 30 oct 2019 </span></p>
                                </div>
                            </div>
                            <hr style={{ width: '75%' }} />
                        </div>
                        <div className="col-12">
                        <div className="row">
                                <div className="col-12 text-center">
                                    <h1 id="contracts-title">Contracts</h1>
                                </div>

                                <div className="col-12 text-center">
                                    <br />
                                    <div className="btn-container">
                                        <img style={{ width: 30, marginTop: '5px' }} src="https://png.icons8.com/wired/64/ffffff/paste.png" />
                                        <p className="text-details">Bail</p>
                                    </div>
                                </div>

                                {/* <div className="col-6 text-center">
                                <div className="btn-container">
                                    <img style={{ width: 30, marginTop: '5px' }} src="https://png.icons8.com/metro/64/ffffff/autograph.png" />
                                    <p className="text-details">E-Sign</p>
                                </div>
                            </div>
                            <div className="col-12 text-center">
                            <br/>
                                <div className="btn-container">
                                    <img style={{ width: 40, marginTop: '5px' }}  src="https://png.icons8.com/ios/65/ffffff/overtime.png" />
                                    <p className="text-details">Prologation du Bail</p>
                                </div>
                            </div> */}
                            </div>
                            <br />
                            <hr style={{ width: '75%' }} />
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1 style={{ margin: '0 auto 20px auto' }} id="contracts-title">Paiements</h1>
                                </div>
                                <div className="col-8">
                                    <select id="test" className="custom-select">
                                        <option defaultValue>Mensuel</option>
                                        <option value={1}>Bi-Mensuel</option>
                                        <option value={2}>Hebdomadaire</option>
                                    </select>
                                </div>
                                <div className="col-4 text-right">
                                    <p>500$</p>
                                    <br />
                                </div>
                            </div>

                            <hr style={{ width: '75%' }} />
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1 style={{ margin: '0 auto 20px auto' }} id="contracts-title">Louer</h1>
                                </div>
                                <div className="col-6 text-center">
                                    <div className="btn-container refuse">
                                        <img style={{ width: 30, marginTop: '5px' }} src="https://png.icons8.com/metro/64/ffffff/cancel.png" />
                                        <p className="text-details">Non</p>
                                    </div>
                                </div>
                                <div className="col-6 text-center">
                                    <div className="btn-container accepte">
                                        <img style={{ width: 30, marginTop: '5px' }} src="https://png.icons8.com/metro/64/ffffff/ok.png" />
                                        <p className="text-details">Oui</p>
                                    </div>
                                    <br />
                                </div>
                                <hr style={{ width: '75%' }} />

                                <div className="col-12">
                                    <div className="col-12 text-center">
                                        <h1 style={{ margin: '0 auto 20px auto' }} id="contracts-title">Aide</h1>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>Numéro de référence</p>
                                        </div>
                                        <div className="col-6">
                                            <p>Contact: Regie des logements</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MessageDetails;
