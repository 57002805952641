import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer id="home_footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 text-center-mobile">
            <img className="footer_logo" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Filoc%20footer.svg?alt=media&token=1616948c-b742-495d-b61b-0454b0527d39" />
          </div>
          <div className="col-lg-3 text-right text-center-mobile">
            <p className="footer_text">Politique de confidentialité </p>
          </div>
          <div className="col-lg-3 text-right text-center-mobile">
            <p className="footer_text">Conditions d'utilisation </p>
          </div>
          <div className="col-lg-3 text-right text-center-mobile">
            <p className="footer_text">Support technique</p>
          </div>
        </div>
        <br />
        <div className="row text-center">
          <div className="col-lg-6 col-12 text-left text-center-mobile">
            <p className="copyright_text">© 2019 Copyright | Tous droits réservés par ILOC | v0.35</p>
          </div>
          <div className="col-lg-6 col-12 text-right text-center-mobile">
            <i className="fab fa-facebook footer_icon" />
            <i className="fab fa-instagram footer_icon" />
            <i className="fab fa-snapchat footer_icon" />
            <i className="fab fa-youtube footer_icon" />
          </div>
        </div>
      </div>
    </footer>
    )
  }
}

export default Footer;
