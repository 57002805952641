import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { GeoCollectionReference, GeoFirestore, GeoQuery, GeoQuerySnapshot } from 'geofirestore';
import google from 'google-maps';
import QRCode from 'qrcode.react'
import Button from '@material-ui/core/Button';

const functions = firebase.functions();
const getUser = functions.httpsCallable('getUserInfo');
class AjoutLogement extends Component {
  constructor(props) {
    super(props);
    this.promiseReceivingGoogleMaps()
    .then(google => {
      this.google = google;
    });    
  }

  promiseReceivingGoogleMaps = (maps) => {
    return new Promise((maps) => {
      return google.load(maps)
    })
  };


  geocodePromise = (address) => new Promise((resolve) => {
    const geocoder = new this.google.maps.Geocoder();
    return geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        });
      }
    })
  }
  );


    ajoutAppart = async () => {
    const titre = document.getElementById('titre').value;
    const adresse = document.getElementById('adresse').value;
    const noAppartement = document.getElementById('adresse').value;
    const pays = document.getElementById('pays').value;
    const ville = document.getElementById('ville').value;
    const codePostal = document.getElementById('codePostal').value;
    const description = document.getElementById('description').value;

    const is_studio_ajout = $('#is_studio_ajout').prop('checked');
    const is_condo_ajout = $('#is_condo_ajout').prop('checked');
    const is_maison_ajout = $('#is_maison_ajout').prop('checked');
    const is_chalet_ajout = $('#is_chalet_ajout').prop('checked');
    const is_collocation_ajout = $('#is_collocation_ajout').prop('checked');
    const is_3demi_ajout = $('#is_3demi_ajout').prop('checked');
    const is_4demi_ajout = $('#is_4demi_ajout').prop('checked');
    const is_5demi_ajout = $('#is_5demi_ajout').prop('checked');
    const is_plus_ajout = $('#is_plus_ajout').prop('checked');

    const is_student_ajout = $('#is_student').prop('checked');
    const is_employed_ajout = $('#is_employed').prop('checked');
    const is_contractuel_ajout = $('#is_contractuel').prop('checked');
    const is_statutTemp_ajout = $('#is_statutTemp').prop('checked');
    const is_tourist_ajout = $('#is_tourist').prop('checked');

    const nb_chats_ajout = $('#nb_chats').prop('value');
    const nb_chiens_ajout = $('#nb_chiens').prop('value');

    const nb_chambres_ajout = $('#nb_chambres').prop('value');
    const nb_stationnements_ajout = $('#nb_stationnements').prop('value');
    const nb_sallebain_ajout = $('#nb_sallebain').prop('value');
    const nb_garage_ajout = $('#nb_garage').prop('value');

    const prixFixe_ajout = $('#prixFixe').val();
    const prixMin_ajout = $('#prixMin').val();
    const prixMax_ajout = $('#prixMax').val();

    console.log(adresse);
    console.log ("GOOGLE", google);
    if (adresse) {
      const latLng = await this.geocodePromise(adresse);
      console.log("Lat long from geocode", latLng);
      let coordinates = new firebase.firestore.GeoPoint(latLng.lat, latLng.lng);
      this.setState({
        apparts: []
      })

      const apparts = geofirestore.collection("apparts");

      apparts
        .add({
          proprio: this.props.user.uid,
          adresse: adresse,
          coordinates: coordinates
          // titre: titre,
          // noAppartement: noAppartement,
          // pays: pays,
          // ville: ville,
          // codePostal: codePostal,
          // description: description,

          // is_studio_ajout: is_studio_ajout,
          // is_condo_ajout: is_condo_ajout,
          // is_maison_ajout: is_maison_ajout,
          // is_chalet_ajout: is_chalet_ajout,
          // is_collocation_ajout: is_collocation_ajout,
          // is_3demi_ajout: is_3demi_ajout,
          // is_4demi_ajout: is_4demi_ajout,
          // is_5demi_ajout: is_5demi_ajout,
          // is_plus_ajout: is_plus_ajout,

          // is_student_ajout: is_student_ajout,
          // is_professional_ajout: is_employed_ajout,
          // is_contractuel_ajout: is_contractuel_ajout,
          // is_statutTemp_ajout: is_statutTemp_ajout,
          // is_tourist_ajout: is_tourist_ajout,

          // nb_chats_ajout: nb_chats_ajout,
          // nb_chiens_ajout: nb_chiens_ajout,


          // nb_chambres_ajout: nb_chambres_ajout,
          // nb_stationnements_ajout: nb_stationnements_ajout,
          // nb_sallebain_ajout: nb_sallebain_ajout,
          // nb_garage_ajout: nb_garage_ajout,

          // prixFixe_ajout: prixFixe_ajout,
          // prixMin_ajout: prixMin_ajout,
          // prixMax_ajout: prixMax_ajout,
        })
        .then(() => this.forceUpdate())
        .catch(e => console.log("Erreur d'ajout ", e))
    }
  }  

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <input id="titre" className="form-control" placeholder="Titre" />
          </div>
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <input id="adresse" className="form-control" placeholder="Adresse" />
          </div>
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <input id="noAppartement" className="form-control" placeholder="No Appartement" />
          </div>
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <input id="pays" className="form-control" placeholder="Pays" />
          </div>
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <input id="ville" className="form-control" placeholder="Ville" />
          </div>
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <input id="codePostal" className="form-control" placeholder="Code Postal" />
          </div>
          <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
            <textarea rows="4" id="description" className="form-control" placeholder="Description"></textarea>
          </div>
        </div>

        <div className="container-filter">
          <p className="titreFilter">Type de logement</p>
          <hr />
          <div id="residentiel" className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <input type="checkbox" id="is_studio_ajout" /> <span>Studio</span>
              <br />
              <input type="checkbox" id="is_condo_ajout" /> <span>Condo</span>
              <br />
              <input type="checkbox" id="is_maison_ajout" /> <span>Maison</span>
              <br />
              <input type="checkbox" id="is_chalet_ajout" /> <span>Chalet</span>
              <br />
              <input type="checkbox" id="is_collocation_ajout" /> <span>Collocation (Chambres)</span>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <input type="checkbox" id="is_3demi_ajout" /> <span>3 <sup>1/2</sup></span>
              <br />
              <input type="checkbox" id="is_4demi_ajout" /> <span>4 <sup>1/2</sup></span>
              <br />
              <input type="checkbox" id="is_5demi_ajout" /> <span>5 <sup>1/2</sup></span>
              <br />
              <input type="checkbox" id="is_plus_ajout" /> <span>Plus</span>
            </div>
          </div>
          <hr />
          <div className="row">
            <div id="howBox" className="col-lg-6 col-12">
              <div className="form-group">
                <p id="sousCategoriesFilter">Statut Professionnel</p>
                <input id="is_student_ajout" type="radio" name="sfamillialee" />
                <span> Étudiant</span>
                <br />
                <input id="is_employed_ajout" type="radio" name="sfamillialee" />
                <span> En Emploi (Permanent)</span>
                <br />
                <input id="is_contractuel_ajout" type="radio" name="sfamillialee" />
                <span> Contractuel</span>
                <br />
                <input id="is_statutTemp_ajout" type="radio" name="sfamillialee" />
                <span> Statut Temporaire (Visa travail)</span>
                <br />
                <input id="is_tourist_ajout" type="radio" name="sfamillialee" />
                <span> Touriste</span>
              </div>
            </div>
            <div id="howBox" className="col-lg-6 col-12">
              <p id="sousCategoriesFilter">Animaux</p>
              <span>Chats</span> &nbsp;
<select id="nb_chats_ajout" className="custom-select">
                <option selected>Choisir</option>
                <option value={1}>Oui</option>
                <option value={2}>Non</option>
              </select>
              <br />
              <br />
              <span>Chiens</span> &nbsp;
<select id="nb_chiens_ajout" className="custom-select">
                <option selected>Choisir</option>
                <option value={1}>Oui</option>
                <option value={2}>Non</option>
              </select>
            </div>
          </div>
          <hr />
          <p className="titreFilter">Caractéristiques</p>
          <hr />
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <select style={{ width: '100%' }} id="nb_chambres_ajout" className="custom-select">
                <option selected>Nombre de chambres </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
              <br />
              <br />
              <select style={{ width: '100%' }} id="nb_stationnements_ajout" className="custom-select">
                <option selected>Nombre de stationnements</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <select style={{ width: '100%' }} id="nb_sallebain_ajout" className="custom-select">
                <option selected>Nombre de salles de bain</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
              <br />
              <br />
              <select style={{ width: '100%' }} id="nb_garage_ajout" className="custom-select">
                <option selected>Nombre de garages</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
            </div>
          </div>
          <hr />
          <p className="titreFilter">Prix</p>
          <hr />
          <input id="prixFixe_ajout" className="form-control" placeholder="Prix" />
          <p style={{ fontWeight: '600', marginTop: '10px' }} className="text-center">Ou</p>
          <div className="row">
            <div className="col-6">
              <input id="prixMin_ajout" className="form-control" placeholder="Min" />
            </div>
            <div className="col-6">
              <input id="prixMax_ajout" className="form-control" placeholder="Max" />
            </div>
          </div>
        </div>
        <hr />
        <a><Button onClick={this.ajoutAppart} variant="contained" color="primary" type="button" id="post" className="btn btn-primary">Ajouter</Button></a>
      </div>
      )
  }
}

const firestore = firebase.firestore();
const geofirestore = new GeoFirestore(firestore);


class Gestion extends Component {
  state = {
    apparts: [],
    fetchedData: false,
    clicked_window: "listeLogement",
    locataires: []
  }
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.handleClickForm = this.handleClickForm.bind(this);
    // this.geocodePromise = this.geocodePromise.bind(this);
    this.promiseReceivingGoogleMaps = this.promiseReceivingGoogleMaps.bind(this);
    this.promiseReceivingGoogleMaps()
    .then(google => {
      this.google = google;
    });    
  }

  handleClickForm(e) {
    this.setState({ clicked_window: e.target.id });
    console.log(e.target.id);
  }

  componentDidMount() {
    $("#propriete").click(function () {
      $("#propriete_page").show();
      $("#locataire_page").hide();
    });

    $("#locatai").click(function () {
      $("#propriete_page").hide();
      $("#locataire_page").show();
    });

    if (!this.state.fetchedData) {
      this.forceUpdate();
    }

    //get Locataires
    console.log("AVANT_LOCATAIRE",this.props.user);
    firestore.collection("prop_loc").doc(this.props.user.uid).get()
    .then(snap => {
      const data = snap.data();
      console.log("LOCATAIRES",data);
      data.locs.map(locataire => {
        let tempLocataires = this.state.locataires;
        getUser(locataire).then(userdata => {
          let data = userdata.data;
          tempLocataires.push(data);
          this.setState({
            locataires: tempLocataires
          })
        })
      })
    })

  }


  componentWillUnmount() {
    console.log('component unmounting');
    if (this.state.fetchedData) {
      this.setState({
        fetchedData: false
      })
    }
  }

  componentDidUpdate(prevProps) {
    var db = firebase.firestore();
    //console.log('componentDIDupdate', this.props);
    if (this.props.user.uid) {

      db.collection('apparts')
        .where('d.proprio', '==', this.props.user.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(doc => {

            var appart = doc.data().d;
            console.log("APPARTS",appart);
            this.state.apparts.push({
              id: doc.id,
              address: appart.adresse
            });
          });

          if (!this.state.fetchedData) {
            this.setState({
              fetchedData: true
            });
            this.forceUpdate();
          }
        })
    }
  }

  geocodePromise = (address) => new Promise((resolve) => {
    const geocoder = new this.google.maps.Geocoder();
    return geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        });
      }
    })
  }
  );


  promiseReceivingGoogleMaps = (maps) => {
    return new Promise((maps) => {
      return google.load(maps)
    })
  };

  async  ajoutAppart() {
    const titre = document.getElementById('titre').value;
    const adresse = document.getElementById('adresse').value;
    const noAppartement = document.getElementById('adresse').value;
    const pays = document.getElementById('pays').value;
    const ville = document.getElementById('ville').value;
    const codePostal = document.getElementById('codePostal').value;
    const description = document.getElementById('description').value;

    const is_studio_ajout = $('#is_studio_ajout').prop('checked');
    const is_condo_ajout = $('#is_condo_ajout').prop('checked');
    const is_maison_ajout = $('#is_maison_ajout').prop('checked');
    const is_chalet_ajout = $('#is_chalet_ajout').prop('checked');
    const is_collocation_ajout = $('#is_collocation_ajout').prop('checked');
    const is_3demi_ajout = $('#is_3demi_ajout').prop('checked');
    const is_4demi_ajout = $('#is_4demi_ajout').prop('checked');
    const is_5demi_ajout = $('#is_5demi_ajout').prop('checked');
    const is_plus_ajout = $('#is_plus_ajout').prop('checked');

    const is_student_ajout = $('#is_student').prop('checked');
    const is_employed_ajout = $('#is_employed').prop('checked');
    const is_contractuel_ajout = $('#is_contractuel').prop('checked');
    const is_statutTemp_ajout = $('#is_statutTemp').prop('checked');
    const is_tourist_ajout = $('#is_tourist').prop('checked');

    const nb_chats_ajout = $('#nb_chats').prop('value');
    const nb_chiens_ajout = $('#nb_chiens').prop('value');

    const nb_chambres_ajout = $('#nb_chambres').prop('value');
    const nb_stationnements_ajout = $('#nb_stationnements').prop('value');
    const nb_sallebain_ajout = $('#nb_sallebain').prop('value');
    const nb_garage_ajout = $('#nb_garage').prop('value');

    const prixFixe_ajout = $('#prixFixe').val();
    const prixMin_ajout = $('#prixMin').val();
    const prixMax_ajout = $('#prixMax').val();

    console.log(adresse);
    console.log ("GOOGLE", google);
    if (adresse) {
      const latLng = await this.geocodePromise(adresse);
      console.log("Lat long from geocode", latLng);
      let coordinates = new firebase.firestore.GeoPoint(latLng.lat, latLng.lng);
      this.setState({
        apparts: []
      })

      const apparts = geofirestore.collection("apparts");

      apparts
        .add({
          proprio: this.props.user.uid,
          adresse: adresse,
          coordinates: coordinates
          // titre: titre,
          // noAppartement: noAppartement,
          // pays: pays,
          // ville: ville,
          // codePostal: codePostal,
          // description: description,

          // is_studio_ajout: is_studio_ajout,
          // is_condo_ajout: is_condo_ajout,
          // is_maison_ajout: is_maison_ajout,
          // is_chalet_ajout: is_chalet_ajout,
          // is_collocation_ajout: is_collocation_ajout,
          // is_3demi_ajout: is_3demi_ajout,
          // is_4demi_ajout: is_4demi_ajout,
          // is_5demi_ajout: is_5demi_ajout,
          // is_plus_ajout: is_plus_ajout,

          // is_student_ajout: is_student_ajout,
          // is_professional_ajout: is_employed_ajout,
          // is_contractuel_ajout: is_contractuel_ajout,
          // is_statutTemp_ajout: is_statutTemp_ajout,
          // is_tourist_ajout: is_tourist_ajout,

          // nb_chats_ajout: nb_chats_ajout,
          // nb_chiens_ajout: nb_chiens_ajout,


          // nb_chambres_ajout: nb_chambres_ajout,
          // nb_stationnements_ajout: nb_stationnements_ajout,
          // nb_sallebain_ajout: nb_sallebain_ajout,
          // nb_garage_ajout: nb_garage_ajout,

          // prixFixe_ajout: prixFixe_ajout,
          // prixMin_ajout: prixMin_ajout,
          // prixMax_ajout: prixMax_ajout,
        })
        .then(() => this.forceUpdate())
        .catch(e => console.log("Erreur d'ajout ", e))
    }
  


  }

  handleClick() {
    var db = firebase.firestore();
    console.log('saving firebase');

    const is_studio = $("#is_appartement").prop('checked');
    const is_condo = $('#is_condo').prop('checked');
    const is_maison = $('#is_maison').prop('checked');
    const is_chalet = $('#is_chalet').prop('checked');
    const is_collocation = $('#is_collocation').prop('checked');
    const is_3demi = $('#is_3demi').prop('checked');
    const is_4demi = $('#is_4demi').prop('checked');
    const is_5demi = $('#is_5demi').prop('checked');
    const is_plus = $('#is_plus').prop('checked');

    const is_student = $('#is_student').prop('checked');
    const is_employed = $('#is_employed').prop('checked');
    const is_contractuel = $('#is_contractuel').prop('checked');
    const is_statutTemp = $('#is_statutTemp').prop('checked');
    const is_tourist = $('#is_tourist').prop('checked');

    const nb_chats = $('#nb_chats').prop('value');
    const nb_chiens = $('#nb_chiens').prop('value');

    const prixFixe = $('#prixFixe').val();
    const prixMin = $('#prixMin').val();
    const prixMax = $('#prixMax').val();

    const new_filter =
    {
      is_studio: is_studio,
      is_condo: is_condo,
      is_maison: is_maison,
      is_chalet: is_chalet,
      is_collocation: is_collocation,
      is_3demi: is_3demi,
      is_4demi: is_4demi,
      is_5demi: is_5demi,
      is_plus: is_plus,

      is_student: is_student,
      is_professional: is_employed,
      is_contractuel: is_contractuel,
      is_statutTemp: is_statutTemp,
      is_tourist: is_tourist,

      nb_chats: nb_chats,
      nb_chiens: nb_chiens,

      prixFixe: prixFixe,
      prixMin: prixMin,
      prixMax: prixMax,
    }

    db.collection('criteres_proprietaire').doc(firebase.auth().currentUser.uid).set(new_filter);
  }

  render() {

    var stateApparts = this.state.apparts;
    console.log("STATE", stateApparts);
    var apparts = stateApparts.map((appart) => {

      return (
        <Fragment>
          <div className="row">
            <div className="col-12">
              <input type="checkbox" id="subscribeNews" name="subscribe" value="newsletter" />
              <img className="thumbnail-appartement" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" />
              <span className="nom-appartement">{appart.address}</span>
              <QRCode value={appart.id} />
            </div>
          </div>
          <hr />
        </Fragment>
      )
    })

    var pageToLoad, clicked_btn;
    clicked_btn = this.state.clicked_window;

    if (clicked_btn == "listeLogement") {
      pageToLoad = apparts;
    }

    else if (clicked_btn == "ajoutLogement") {
      pageToLoad = <AjoutLogement user={this.props.user}/>;
    }

    
    var locataires =  this.state.locataires.map(locataire =>{
    console.log("ALLO",locataire);
      return  <React.Fragment>
        <div className="row">
        <div className="col-8">
          <img className="thumbnail-locataire" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" />
          <span className="nom-locataire">{locataire.displayName}</span>
          <br />
          <span className="etoile-locataire"><i className="fa fa-star blue" aria-hidden="true" /><i className="fa fa-star blue" aria-hidden="true" /><i className="fa fa-star blue" aria-hidden="true" /></span>
        </div>
        <div className="col-4">
          <span className="chatLogo" className="lnr lnr-bubble lnrIcon locataire-chat" />
        </div>
      </div>
      </React.Fragment>
      })

    console.log("IN LOCATAIRE", locataires);
    return (
      <div className="container-fluid container-gestion">
        <NavLink to="/settings"><img style={{ margin: 0 }} id="backbtn" src="https://img.icons8.com/ios/30/000000/left.png" /></NavLink>
        <br />
        <div className="row">
          <div className="col-lg-2 col-12">
            <div className="container-menu">
              <h1 className="titre-gestion">Menu</h1>
              <hr />
              <p className="menu-item" id="propriete">Mes propriétés</p>
              <hr />
              <p className="menu-item" id="locatai">Mes locataires</p>
            </div>
          </div>
          <div className="col-lg-7 col-12">
            <div id="propriete_page" className="container-appartement">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <h1 className="titre-gestion">Mes propriétés</h1>
                </div>
                <div className="col-lg-8 col-md-6 col-12">
                  {/* <span data-toggle="modal" data-target="#addAppart" className="ajouter-propriete"> &nbsp;<img id="img-add" src="https://png.icons8.com/ios/30/000000/plus-filled.png" /> Ajouter une propriété</span> */}
                
                  <span onClick={this.handleClickForm} id="ajoutLogement" className="ajouter-propriete"> &nbsp;<img id="img-add" src="https://png.icons8.com/ios/30/000000/plus-filled.png" /> Ajouter une propriété</span>
                  <span onClick={this.handleClickForm} style={{marginRight: '15px'}} id="listeLogement" className="ajouter-propriete"> &nbsp;<img id="img-add" src="https://png.icons8.com/dotty/30/000000/skyscrapers.png" /> Liste des propriétés</span>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-2">
                  <input type="checkbox" id="subscribeNews" name="subscribe" value="newsletter" />
                </div>
                <div className="col-10 text-right">
                  <span>Publier</span>&nbsp; &nbsp; &nbsp;
                    <span>Masquer</span>&nbsp; &nbsp; &nbsp;
                    <span><img src="https://png.icons8.com/ios/25/000000/waste-filled.png" /></span>
                    
                </div>
              </div>
              <hr />
              {pageToLoad}
            </div>


            <div id="locataire_page" className="container-appartement" style={{ display: 'none' }}>
              <div className="row">
                <div className="col-12">
                  <h1 className="titre-gestion">Mes locataires</h1>
                </div>
              </div>
              <hr />
              {locataires}
              <hr />
            </div>
          </div>


          <div className="col-lg-3 col-12">
            <div className="container-filter">
              <h1 className="titre-gestion">Filtres</h1>
              <hr />
              <p className="titreFilter">Type de logement</p>
              {/* <hr />
              <div className="row">
                <div className="col-4">
                  <input type="radio" name="typeProprio" className="checkList" defaultValue="court" defaultChecked /> <span>Court terme</span>
                </div>
                <div className="col-4">
                  <input type="radio" name="typeProprio" className="checkList" defaultValue="moyen" /> <span>Moyen terme</span>
                </div>
                <div className="col-4">
                  <input type="radio" name="typeProprio" className="checkList" defaultValue="long" /> <span>Long terme</span>
                </div>
              </div>
              <hr />
              <p className="tousTypes">À la recherche de :</p> */}
              <hr />
              <div id="residentiel_form" className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="checkbox" id="is_studio" /> <span>Studio</span>
                  <br />
                  <input type="checkbox" id="is_condo" /> <span>Condo</span>
                  <br />
                  <input type="checkbox" id="is_maison" /> <span>Maison</span>
                  <br />
                  <input type="checkbox" id="is_chalet" /> <span>Chalet</span>
                  <br />
                  <input type="checkbox" id="is_collocation" /> <span>Collocation (Chambres)</span>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="checkbox" id="is_3demi" /> <span>3 <sup>1/2</sup></span>
                  <br />
                  <input type="checkbox" id="is_4demi" /> <span>4 <sup>1/2</sup></span>
                  <br />
                  <input type="checkbox" id="is_5demi" /> <span>5 <sup>1/2</sup></span>
                  <br />
                  <input type="checkbox" id="is_plus" /> <span>Plus</span>
                </div>
              </div>
              <hr />
              <p className="titreFilter">Caractéristiques de locataires</p>
              <hr />
              <div className="row">
                <div id="howBox" className="col-lg-6 col-12">
                  <div className="form-group">
                    <p id="sousCategoriesFilter">Statut Professionnel</p>
                    <input id="is_student" type="radio" name="sfamillialee" />
                    <span> Étudiant</span>
                    <br />
                    <input id="is_employed" type="radio" name="sfamillialee" />
                    <span> En Emploi (Permanent)</span>
                    <br />
                    <input id="is_contractuel" type="radio" name="sfamillialee" />
                    <span> Contractuel</span>
                    <br />
                    <input id="is_statutTemp" type="radio" name="sfamillialee" />
                    <span> Statut Temporaire (Visa travail)</span>
                    <br />
                    <input id="is_tourist" type="radio" name="sfamillialee" />
                    <span> Touriste</span>
                  </div>
                </div>
                <div id="howBox" className="col-lg-6 col-12">
                  <p id="sousCategoriesFilter">Animaux</p>
                  <span>Chats</span> &nbsp;
                  <select id="nb_chats" className="custom-select">
                    <option selected>Choisir</option>
                    <option value={1}>Oui</option>
                    <option value={2}>Non</option>
                  </select>
                  <br />
                  <br />
                  <span>Chiens</span> &nbsp;
                  <select id="nb_chiens" className="custom-select">
                    <option selected>Choisir</option>
                    <option value={1}>Oui</option>
                    <option value={2}>Non</option>
                  </select>
                </div>
              </div>
              <hr />
              <p className="titreFilter">Prix</p>
              <hr />
              <input id="prixFixe" className="form-control" placeholder="Prix" />
              <p style={{ fontWeight: '600', marginTop: '10px' }} className="text-center">Ou</p>
              <div className="row">
                <div className="col-6">
                  <input id="prixMin" className="form-control" placeholder="Min" />
                </div>
                <div className="col-6">
                  <input id="prixMax" className="form-control" placeholder="Max" />
                </div>
                <div className="col-12 text-right">
                  <br />
                  <br />
                  <button type="button" className="btn btn-primary"> Filtrer <img src="https://png.icons8.com/ios-glyphs/20/ffffff/sorting-answers.png" /></button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="addAppart" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Ajout de propriété</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <input id="titre" className="form-control" placeholder="Titre" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <input id="adresse" className="form-control" placeholder="Adresse" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <input id="noAppartement" className="form-control" placeholder="No Appartement" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <input id="pays" className="form-control" placeholder="Pays" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <input id="ville" className="form-control" placeholder="Ville" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <input id="codePostal" className="form-control" placeholder="Code Postal" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 formAjoutAppartement">
                      <textarea rows="4" id="description" className="form-control" placeholder="Description"></textarea>
                    </div>
                  </div>

                  <div className="container-filter">
                    <p className="titreFilter">Type de logement</p>
                    <hr />
                    <div id="residentiel" className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <input type="checkbox" id="is_studio_ajout" /> <span>Studio</span>
                        <br />
                        <input type="checkbox" id="is_condo_ajout" /> <span>Condo</span>
                        <br />
                        <input type="checkbox" id="is_maison_ajout" /> <span>Maison</span>
                        <br />
                        <input type="checkbox" id="is_chalet_ajout" /> <span>Chalet</span>
                        <br />
                        <input type="checkbox" id="is_collocation_ajout" /> <span>Collocation (Chambres)</span>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <input type="checkbox" id="is_3demi_ajout" /> <span>3 <sup>1/2</sup></span>
                        <br />
                        <input type="checkbox" id="is_4demi_ajout" /> <span>4 <sup>1/2</sup></span>
                        <br />
                        <input type="checkbox" id="is_5demi_ajout" /> <span>5 <sup>1/2</sup></span>
                        <br />
                        <input type="checkbox" id="is_plus_ajout" /> <span>Plus</span>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div id="howBox" className="col-lg-6 col-12">
                        <div className="form-group">
                          <p id="sousCategoriesFilter">Statut Professionnel</p>
                          <input id="is_student_ajout" type="radio" name="sfamillialee" />
                          <span> Étudiant</span>
                          <br />
                          <input id="is_employed_ajout" type="radio" name="sfamillialee" />
                          <span> En Emploi (Permanent)</span>
                          <br />
                          <input id="is_contractuel_ajout" type="radio" name="sfamillialee" />
                          <span> Contractuel</span>
                          <br />
                          <input id="is_statutTemp_ajout" type="radio" name="sfamillialee" />
                          <span> Statut Temporaire (Visa travail)</span>
                          <br />
                          <input id="is_tourist_ajout" type="radio" name="sfamillialee" />
                          <span> Touriste</span>
                        </div>
                      </div>
                      <div id="howBox" className="col-lg-6 col-12">
                        <p id="sousCategoriesFilter">Animaux</p>
                        <span>Chats</span> &nbsp;
                  <select id="nb_chats_ajout" className="custom-select">
                          <option selected>Choisir</option>
                          <option value={1}>Oui</option>
                          <option value={2}>Non</option>
                        </select>
                        <br />
                        <br />
                        <span>Chiens</span> &nbsp;
                  <select id="nb_chiens_ajout" className="custom-select">
                          <option selected>Choisir</option>
                          <option value={1}>Oui</option>
                          <option value={2}>Non</option>
                        </select>
                      </div>
                    </div>
                    <hr />
                    <p className="titreFilter">Caractéristiques</p>
                    <hr />
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <select style={{ width: '100%' }} id="nb_chambres_ajout" className="custom-select">
                          <option selected>Nombre de chambres </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                        <br />
                        <br />
                        <select style={{ width: '100%' }} id="nb_stationnements_ajout" className="custom-select">
                          <option selected>Nombre de stationnements</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <select style={{ width: '100%' }} id="nb_sallebain_ajout" className="custom-select">
                          <option selected>Nombre de salles de bain</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                        <br />
                        <br />
                        <select style={{ width: '100%' }} id="nb_garage_ajout" className="custom-select">
                          <option selected>Nombre de garages</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                    <hr />
                    <p className="titreFilter">Prix</p>
                    <hr />
                    <input id="prixFixe_ajout" className="form-control" placeholder="Prix" />
                    <p style={{ fontWeight: '600', marginTop: '10px' }} className="text-center">Ou</p>
                    <div className="row">
                      <div className="col-6">
                        <input id="prixMin_ajout" className="form-control" placeholder="Min" />
                      </div>
                      <div className="col-6">
                        <input id="prixMax_ajout" className="form-control" placeholder="Max" />
                      </div>
                    </div>
                    {/* <div className="col-12 text-right">
              <br />
              <br />
              <button type="button" className="btn btn-primary"> Filtrer <img src="https://png.icons8.com/ios-glyphs/20/ffffff/sorting-answers.png" /></button>            
              </div> */}
                  </div>
                </div>
                <div >
                  <a><button onClick={this.ajoutAppart.bind(this)} type="button" id="post" className="btn btn-primary">Ajouter</button></a>
                  {/* <a onClick={this.handleClick}><button type="button" className="btn btn-secondary">Fermer</button></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  if (state.user) {
    return {
      user: state.user,
    }
  }
}
export default connect(mapStateToProps)(Gestion);
