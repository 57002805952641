import React, { Component } from 'react';
import firebase from 'firebase';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ButtonMaterial from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteOutlined';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { file, returnStatement } from '@babel/types';
import QRCode from 'qrcode.react'
import CircularProgress from '@material-ui/core/CircularProgress';
registerPlugin(FilePondPluginImagePreview);

const functions = firebase.functions();
const getUser = functions.httpsCallable('getUserInfo');

const storageRef = firebase.storage().ref();
const firestore = firebase.firestore();

class FicheAppartement extends Component {
    state = {
        userRecord: {},
        appartData: {},
        action_edit: "close_edit",
        uid_proprio: undefined,
        images: [],
        uploadTasks: [],
        progresses: new Map(),
        filePondState : "init"
    }

    constructor(props) {
        super(props);
        this.handleClickGetIdEdit = this.handleClickGetIdEdit.bind(this);
        this.handleClickAppartInfo = this.handleClickAppartInfo.bind(this);
    }

    componentDidMount() {
        const locid = this.props.match.params.uid;
        console.log()
        firestore.collection('apparts').doc(locid).get()
            .then(snap => {
                if (snap.exists) {
                    const data = snap.data();
                    console.log("APPARTS", data);
                    const {criteres} = data.d;
                    this.setState({
                        appartData: data.d,
                        uid_proprio: data.d.proprio,
                        descriptif: data.d.descriptif,
                        adresse: data.d.adresse,
                        loyer: data.d.loyer,
                        criteres
                    })

                    getUser(data.d.proprio).then((result) => {
                        const userRecord = result.data;
                        this.setState({
                            name: userRecord.displayName,
                            photo: userRecord.photoURL,
                        })
                    })
                }
            })

        firestore.collection('apparts').doc(locid).collection('photos').get()
        .then(docs => {
            docs.forEach(snap => {
                const data = snap.data() 
                const images = this.state.images;
                images.push(data);
                this.setState({
                    images
                })
            })
            })

        console.log("UID", locid);
    }

    fillwWithData = (appartData) => {
        this.setState({
            descriptif: appartData.descriptif,
            adresse: appartData.adresse,
        })
        console.log(appartData);

    }

    handleClickAppartInfo(e) {
        const locid = this.props.match.params.uid;
        e.preventDefault();
        console.log('saving firebase');

        const appartData = this.state.appartData;
        appartData.adresse = this.state.adresse;
        appartData.descriptif = this.state.descriptif;
        appartData.loyer = this.state.loyer;

        console.log('apparts', this.state.appartData);
        firestore.collection('apparts').doc(locid).update("d", appartData);
    }

    handleClickGetIdEdit(e) {
        this.setState({ action_edit: e.target.id });
    }

    handleTextChange = name => event => {
        this.setState({ [name]: event.target.value });
    }


    onUploadTaskStateChanged = (task, filename ) => {

        if (this.state.filePondState === "init") return;

        var progress = (task.bytesTransferred / task.totalBytes) * 100;

        
        const progressesCopy = this.state.progresses;
        if (progressesCopy.get(filename) > progress) return;

        progressesCopy.set(filename, progress);
        this.setState({
            progresses: progressesCopy
        })
        switch (task.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
        }
    }

    handleInit = async () => {
        console.log("INITPOND1", this.state.image)
        this.setState({
            filePondState : "init"
        })
        const thePond = this.pond;
        
        if (this.state.images.length === 0)
        this.setState({
            filePondState : "done"
        })

        this.state.images.map((imageData, index) => {
            console.log("INITPOND2", imageData)
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function(event) {
              var blob = xhr.response;
              //download
              thePond.addFile(
                blob
                 )
              .then((file)=> {
                  if (index > this.state.images.length-1)
                  {
                    this.setState({
                        filePondState : "done"
                    })
                  }
              })
              .catch(error => console.log("error", imageData.downloadUrl))
            };
            xhr.open('GET', imageData.downloadUrl);
            xhr.send();
            
        })
    }

    onUploadTaskCompleted = (task, filename) => {
        const locid = this.props.match.params.uid; 
        console.log(task)
        task.snapshot.ref.getDownloadURL().then(url => {
            firestore.collection('apparts').doc(locid).collection('photos').doc(filename).set({
                photosPath: task.snapshot.ref.fullPath,
                downloadUrl: url
            })
            const progressesCopy = this.state.progresses;
            progressesCopy.delete(filename);
            this.setState({
                progresses: progressesCopy
            })

        })
            .catch(error => console.log(error))
    }

    requestBeILoc = (uid) => {   
        const locId = this.props.match.params.uid;
        firestore.collection("requests_be_iloc").add(
          {
            from: this.props.user.uid,
            to: uid,
            status: "REQUEST",
            locId
          }
        ).then(() =>{
          this.setState({onGoingIlocRequest:true})
        })
      }

    render() {


        let descriptif = "";
        const chips = new Array();
        if (this.state.criteres)
        {
            const {
                chauffe,
                animaux,
                eau_chaude,
                laveuse_secheuse,
                eclairee,
                lave_vaisselle,
            } = this.state.criteres
    
           
    
            if (chauffe) chips.push(<Chip label="Chauffé" variant="outlined" style={{marginRight: '5px'}} />)
            if (animaux) chips.push(<Chip label="Accepte animaux" variant="outlined" style={{marginRight: '5px'}} />)
            if (eau_chaude) chips.push(<Chip label="Eau chaude" variant="outlined" style={{marginRight: '5px'}} />)
            if (laveuse_secheuse) chips.push(<Chip label="Laveuse sécheuse" variant="outlined" style={{marginRight: '5px'}} />)
            if (eclairee) chips.push(<Chip label="Éclairée" variant="outlined" style={{marginRight: '5px'}} />)
            if (lave_vaisselle) chips.push(<Chip label="Lave vaisselle" variant="outlined" />)
        }

        var action_edit;
        action_edit = this.state.action_edit;
        // if (this.props.user.uid === this.state.uid_proprio){
        //     $(".hide_edit_not_proprio").hide();
        // }

        // else {
        //     $(".hide_edit_not_proprio").show();
        // }

        if (action_edit == "go_edit") {
            $(".hide_edit").hide();
            $(".show_edit").show();
            // console.log("open");
        }

        else if (action_edit == "close_edit") {
            $(".hide_edit").show();
            $(".show_edit").hide();
        }

        const progressBars= new Array;
         this.state.progresses.forEach((value, key, map)=> {
            progressBars.push(<CircularProgress  variant="determinate" value={value} />)
        })

        let carouselImages;
        if (this.state.images.length == 0)
        {
            carouselImages = <div className="carousel-item active">
            <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Second slide" />
            </div>
        } else  {
            carouselImages = this.state.images.map((imageData, index) => {
                let className = "carousel-item"
              if (index === 0 )
              {
                className = "carousel-item active"
              }
                return <div className={className}>
                <img className="d-block w-100 image_carousel" src={imageData.downloadUrl} alt="Second slide" />
                </div>
            })
        }

        return (
            <div>
                <header id="notification_header">
                    <div className="container">
                        <h1 className="critere_title"> {this.state.descriptif} </h1>
                        <div className="row text-center" style={{margin: '0 auto'}}>
                            <div className="col-12">
                                <span className="fas fa-star star_icon"></span>
                                <span className="fas fa-star star_icon"></span>
                                <span className="fas fa-star star_icon"></span>
                                <span className="fas fa-star star_icon"></span>
                                <span className="fas fa-star-half-alt star_icon"></span>
                            </div>     
                        </div>
                    </div>
                </header>

                <div style={{ paddingTop: '50px' }} className="container">
                    <div className="all_notification_continer">
                        <div className="row text-center">
                            <div className="profil_container">
                                <img className="profil_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                                <p className="profil_name">{this.state.name}</p>
                                {/* <ButtonMaterial onClick={()=>{this.requestBeILoc(this.state.appartData.proprio)}} style={{textAlign: "center", backgroundColor: "#F16B7D", color: 'white'}} variant="contained">Demande ILOC</ButtonMaterial>
                                <br /> */}
                                <br />
                                <QRCode value={this.props.match.params.uid} />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="notification_container">
                                <form onSubmit={this.handleClickAppartInfo} style={{ display: 'contents' }}>
                                    <div className="row">
                                        <div className="col-12 text-right">
                                            {this.props.user.uid === this.state.uid_proprio ? <div className="col-12 hide_edit_not_proprio" style={{ display: 'none !important;' }}>
                                                <span id="go_edit" onClick={this.handleClickGetIdEdit} style={{ cursor: 'pointer' }} className="center_element hide_edit">Modifier <img src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559692694/edit_fpvwsd.svg" style={{ height: '15px' }} /></span>
                                            </div> : ""
                                            }
                                            <div className="col-12 show_edit" style={{ display: 'none' }}>
                                                <br />
                                                <span id="close_edit" onClick={this.handleClickGetIdEdit} className="primary_btn">Retour</span> &nbsp;
                                                    <button onClick={this.handleClickGetIdEdit} className="primary_btn" type="submit">Enregistrer</button>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row text-center hide_edit">
                                        <div className="col-lg-5 col-md-12 col-12 m-t-50">
                                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                <ol className="carousel-indicators">
                                                    {
                                                        this.state.images.map((image,index)=> {
                                                            if (index === 0)
                                                                return  <li data-target="#carouselExampleIndicators" data-slide-to={index} className="active" />
                                                            else
                                                                return <li data-target="#carouselExampleIndicators" data-slide-to={index} />
                                                    })
                                                    }
                                                </ol>
                                                <div className="carousel-inner">
                                                    {carouselImages}
                                                </div>
                                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true" />
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-12">
                                            <div className="row align_left">
                                                <div className="col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Adresse :</span>
                                                    <span className="data_content_profil"> {this.state.adresse}</span>
                                                    <hr />
                                                </div>
                                            </div>

                                            <div className="row align_left">
                                                <div className="col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Loyer :</span>
                                                    <span className="data_content_profil"> {this.state.loyer + "$ / mois"}</span>
                                                    <hr />
                                                </div>
                                            </div>

                                            <div className="row align_left">
                                                <div className="col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Attributs :</span>
                                                    <span className="data_content_profil"> {chips} </span>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row show_edit" style={{ display: 'none' }}>
                                        <div className="col-lg-5 col-md-12 col-12">
                                        {progressBars}
                                            <FilePond  
                                                oninit={() => this.handleInit() }
                                                ref={ref => this.pond = ref} 
                                                onupdatefiles={(files) => {
                                                const locid = this.props.match.params.uid;

                                                files.map((fileItem) => {
                                                    const ref = storageRef.child(`apparts/${locid}/${fileItem.filename}`);
                                                    const uploadTasksCopy = this.state.uploadTasks.slice();
                                                    const uploadTask = ref.put(fileItem.file);
                                                    uploadTask.on('state_changed', (task) => this.onUploadTaskStateChanged(task, fileItem.filename), null, () => this.onUploadTaskCompleted(uploadTask, fileItem.filename));
                                                    uploadTasksCopy.push(uploadTask);
                                                    this.setState({ uploadTasks: uploadTasksCopy });
                                                }
                                                )
                                            }} allowMultiple={true} />

                                           
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-12">
                                            <div className="row align_left">
                                                <div className="col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Description:</span>
                                                    <input className="form_input_profil" style={{ width: '200px' }} id="descriptif" type="text" value={this.state.descriptif} onChange={this.handleTextChange('descriptif')} />
                                                    <hr />
                                                </div>
                                            </div>

                                            <div className="row align_left">
                                                <div className="col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Adresse :</span>
                                                    <input className="form_input_profil" style={{ width: '200px' }} id="adresse" type="text" value={this.state.adresse} onChange={this.handleTextChange('adresse')} />
                                                    <hr />
                                                </div>
                                            </div>

                                            <div className="row align_left">
                                                <div className="col-12 m-b-15-mobile">
                                                    <span className="data_title_profil">Attributs :</span>
                                                        <TextField
                                                            id="LoyerMensuel"
                                                            label="Loyer Mensuel"
                                                            type="text"
                                                            style={{ marginBottom: 25 }}
                                                            value={this.state.loyer}
                                                            onChange={this.handleTextChange('loyer')}
                                                        />
                                                    <FormControl component="fieldset">

                                                        <InputLabel htmlFor="demo-controlled-open-select">Type</InputLabel>
                                                        <Select
                                                            value={this.state.type}
                                                            // onChange={this.handleTextChange('type')}
                                                            input={<Input name="type" id="type-helper" />}
                                                            style={{ marginBottom: 30 }}
                                                        >
                                                            <MenuItem value={"Chambre"}>Chambre</MenuItem>
                                                            <MenuItem value={"1½"}>1½</MenuItem>
                                                            <MenuItem value={"2½"}>2½</MenuItem>
                                                            <MenuItem value={"3½"}>3½</MenuItem>
                                                            <MenuItem value={"4½"}>4½</MenuItem>
                                                            <MenuItem value={"5½"}>5½</MenuItem>
                                                            <MenuItem value={"6½+"}>6½+</MenuItem>
                                                        </Select>


                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.chauffe} value="chauffe" />}
                                                            label="Chauffée"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.eau_chaude} value="eau_chaude" />}
                                                            label="Eau chaude"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.lave_vaisselle} value="lave_vaisselle" />}
                                                            label="Lave-vaisselle"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.laveuse_secheuse} value="laveuse_secheuse" />}
                                                            label="Laveuse/sécheuse"
                                                        />

                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.animaux} value="animaux" />}
                                                            label="Accepte animaux"
                                                        />

                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row text-left">
                        <div className="col-12">
                            <div className="notification_container">
                                <h3>Commentaires</h3>
                                <hr />
                                <p><b>Phanuel Randrianandraina:</b> Appartement agréable avec plein de petites intention a l arrivé, avec café, thé, beurre, confiture ect....
                                    Pas très loin du centre ville et de la gare.</p>
                                <hr />
                                <p><b>Elyeser:</b> Appartement nickel je conseil et les petites attentions au top</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    if (state.user) {
        return {
            user: state.user,
        }
    }
}

export default connect(mapStateToProps)(FicheAppartement);

