import React, { Component } from 'react';
import {connect} from 'react-redux';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import fire from '../js/fire'
import {combineReducers, bindActionCreators} from 'redux';
import {onUserChange} from '../redux/actions/index';
import {firebaseUI} from './signup';


class Historique extends Component {

  render() {
    return (
        <div>
            <header id="notification_header">
                <div className="container" style={{ zIndex: '500' }}>
                    <h1 className="critere_title"> Gestion des loyers et frais de ménage + historique de paiments</h1>
                </div>
            </header>

            <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="all_notification_continer">
                            <div className="notification_container">
                                <div className="row text-center">
                                    
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <img className="fiche_icon" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fficheiloc.svg?alt=media&token=fd2f0012-f244-4799-a261-f9665548c237" />
                                        <p>Phanuel Randrianandraina</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6">
                                        <p>2019-06-01</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6">
                                        <p>16,99$</p>
                                    </div>
                                </div>
                            </div>

                            <div className="notification_container">
                                <div className="row text-center">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <img className="fiche_icon" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fficheiloc.svg?alt=media&token=fd2f0012-f244-4799-a261-f9665548c237" />
                                        <p>Phanuel Randrianandraina</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6">
                                        <p>2019-06-01</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6">
                                        <p>16,99$</p>
                                    </div>
                                </div>
                            </div>

                            <div className="notification_container">
                                <div className="row text-center">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <img className="fiche_icon" src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fficheiloc.svg?alt=media&token=fd2f0012-f244-4799-a261-f9665548c237" />
                                        <p>Phanuel Randrianandraina</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6">
                                        <p>2019-06-01</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-6">
                                        <p>16,99$</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Historique;
