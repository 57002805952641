import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { NavLink } from 'react-router-dom';
import { UserBindingInstance } from 'twilio/lib/rest/ipMessaging/v2/service/user/userBinding';
const firestore = firebase.firestore();


class FormIndividuel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="row text-center">
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="last_name_personnel" type="text" placeholder="Nom du gérant" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="first_name_personnel" type="text" placeholder="Prénom du gérant" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="date_personnel" type="date" placeholder="Date de naissance" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="email_personnel" type="text" placeholder="Courriel" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="tel_personnel" type="tel" placeholder="Numéro de téléphone" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="country_personnel" type="text" placeholder="Pays" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="city_personnel" type="text" placeholder="Ville" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="postal_code_personnel" type="text" placeholder="Code postal" required />
            </div>
        </div>
    )
  }
}

class FormCommercial extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="row text-center">
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="name_entreprise" type="text" placeholder="Nom de l'entreprise" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="neq_entreprise" type="text" placeholder="No de NEQ" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="date_creation_entreprise" type="date" placeholder="Date de création de l'entreprise" required />
            </div>
            <div className="col-lg-6 col-md-6 col-6">
                <input className="form-control form_input" id="email_entreprise" type="text" placeholder="Courriel" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="tel_entreprise" type="tel" placeholder="Numéro de téléphone" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="fax_entreprise" type="text" placeholder="Fax" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="country_entreprise" type="text" placeholder="Pays" required />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                <input className="form-control form_input" id="postal_code_entreprise" type="text" placeholder="Code postal" required />
            </div>
        </div>
    )
  }
}

class FormProprietaire extends Component {
  constructor(props){
    super();
    this.state = {
      typeUser: "individuel",
    }
    this.handleClickForm = this.handleClickForm.bind(this);
    this.handleClickGetId = this.handleClickGetId.bind(this);
  }


  componentDidMount() {
    firestore.collection('profils_proprietaires').doc(this.props.user.uid).get()
      .then(userDataSnapshot => {
        var userData = userDataSnapshot.data();
        console.log(userData);
        if (userData) {
          this.setState({
            last_name_personnel: userData.last_name_personnel,
            first_name_personnel: userData.first_name_personnel,
            date_personnel: userData.date_personnel,
            email_personnel: userData.email_personnel,
            tel_personnel: userData.tel_personnel,
            country_personnel: userData.country_personnel,
            city_personnel: userData.city_personnel,
            postal_code_personnel: userData.postal_code_personnel,
          })

          if (!userData.email)
          {
            userData.email = this.props.user.email;
          }

          $("#last_name_personnel").val(userData.last_name_personnel);
          $('#first_name_personnel').val(userData.first_name_personnel);
          $('#date_personnel').val(userData.date_personnel);
          $('#email_personnel').val(userData.email_personnel);
          $('#tel_personnel').val(userData.tel_personnel);
          $('#country_personnel').val(userData.country_personnel);
          $('#city_personnel').val(userData.city_personnel);
          $('#postal_code_personnel').val(userData.postal_code_personnel);
        }
      })
  }

  handleClickForm(e) {
    e.preventDefault();
    if (this.state.typeUser === 'individuel') {
      const type_user = 'individuel';
      const last_name_personnel = $("#last_name_personnel").val();
      const first_name_personnel = $('#first_name_personnel').val();
      const date_personnel = $('#date_personnel').val();
      const email_personnel = $('#email_personnel').val();
      const tel_personnel = $('#tel_personnel').val();
      const country_personnel = $('#country_personnel').val();
      const city_personnel = $('#city_personnel').val();
      const postal_code_personnel = $('#postal_code_personnel').val();
      const new_profil =
      {
        type_user: type_user,
        last_name_personnel:last_name_personnel,
        first_name_personnel:first_name_personnel,
        date_personnel: date_personnel,
        email_personnel: email_personnel,
        tel_personnel:tel_personnel,
        country_personnel: country_personnel,
        city_personnel:city_personnel,
        postal_code_personnel:postal_code_personnel,
      }
      console.log('saving firebase individuel', new_profil);
      firestore.collection('profils_proprietaires').doc(this.props.user.uid).set(new_profil);
    }

    else if (this.state.typeUser === 'commercial') {
      const type_user = 'commercial';
      const name_entreprise = $("#name_entreprise").val();
      const neq_entreprise = $('#neq_entreprise').val();
      const date_creation_entreprise = $('#date_creation_entreprise').val();
      const email_entreprise = $('#email_entreprise').val();
      const tel_entreprise = $('#tel_entreprise').val();
      const fax_entreprise = $('#fax_entreprise').val();
      const country_entreprise = $('#country_entreprise').val();
      const postal_code_entreprise = $('#postal_code_entreprise').val();

      const new_profil =
      {
        type_user: type_user,
        name_entreprise:name_entreprise,
        neq_entreprise:neq_entreprise,
        date_creation_entreprise: date_creation_entreprise,
        email_entreprise: email_entreprise,
        tel_entreprise:tel_entreprise,
        fax_entreprise:fax_entreprise,
        country_entreprise: country_entreprise,
        postal_code_entreprise:postal_code_entreprise,
        
      }
      console.log('saving firebase commercial', new_profil);
      firestore.collection('profils_proprietaires').doc(this.props.user.uid).set(new_profil);
    }
  }

  handleClickGetId(e) {
    this.setState({ typeUser: e.target.id });
    console.log(e.target.id);
  }

  disconnect() {
    firebase.auth().signOut();
  }

  render() {
     var pageToLoad, typeUtilisateur, sectionToLoad, typeBtn;
     typeUtilisateur = this.state.typeUser;
     typeBtn = this.state.typeMenu;

    if (typeUtilisateur == "individuel") {
      pageToLoad = <FormIndividuel uid={this.props.user.uid} />;

    } else if (typeUtilisateur == "commercial"){
      pageToLoad = <FormCommercial uid={this.props.user.uid} />;
    }
    return (
      <div>
        <header id="notification_header">
          <div className="container">
            <h1 className="critere_title"> Renseignements personnels</h1>
          </div>
        </header>

        <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
          <div className="row">
            <div className="col-12">
              <div className="all_notification_continer">
                <div className="notification_container">
                  <div className="col-12" style={{ marginBottom: '35px' }}>
                    <span onClick={this.handleClickGetId} id="individuel" className="primary_btn"> Personnel</span> &nbsp;
                    <span onClick={this.handleClickGetId} id="commercial" className="primary_btn"> Société</span>
                  </div>
                  <form onSubmit={this.handleClickForm}>
                    <div style={{ marginTop: '15px;' }}>
                      {pageToLoad}
                    </div>
                    <div>
                      <div className="col-lg-12 col-md-12 col-12 text-right" style={{ marginTop: '15px' }}>
                        <button className="primary_btn" type="submit"> Enregistrer </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps)(FormProprietaire);
