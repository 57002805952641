import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import {onContinentChange} from '../redux/actions/actions_geolocalisation';
class Geolocalisation extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillReceiveProps(props) {
        
    }

    handleClick(e) {
        var code = e.target.className[0];
        var continent = "test";
        
        if (code == 1)
            continent = "AFRIQUE";
        else if (code == 2)
            continent = "EUROPE";
        else if (code == 3)
            continent = "AMERIQUE";
        else if (code == 4)
            continent = "ASIE";
        else if (code == 5)
            continent = "OCEANIE";

        console.log('SENDING',continent);
        this.props.onContinentChange({continent:continent});
    }
  
    render() {



        return (
            <div className="container-fluid">
            <div id="globe_container" className="row text-center">
            <div className="col-12">
                <h1 className="critere_title">Choississez votre continent</h1>
            </div>
                <div className="col-lg-4 col-6 hvr-grow" id='Afrique'>
                <NavLink to="/critere"><img id="mini-globe" onClick={this.handleClick}  className="1 hvr-grow" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1541021559/Afrique_p8qsuk.png"/>
                    <p id="continent-name">Afrique</p>
                    </NavLink>
                </div>
                <div className="col-lg-4 col-6">
                     <NavLink to="/critere"><img id="mini-globe" onClick={this.handleClick} className="2 hvr-grow" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542671981/Europe_n_vxlkps.png"/>
                    <p id="continent-name">Europe</p>
                    </NavLink>
                </div>
                <div className="col-lg-4 col-6">
                     <NavLink to="/critere"><img id="mini-globe" onClick={this.handleClick} className="3 hvr-grow" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542671981/Amerique_n_k1bku7.png"/>
                    <p id="continent-name">Amerique</p>
                    </NavLink>
                </div>
                <div className="col-lg-2 col-0 noneMobile"></div>
                <div className="col-lg-4 col-6">
                     <NavLink to="/critere"><img id="mini-globe" onClick={this.handleClick} className="4 hvr-grow" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542672220/Asie_n_l7xpxt.png"/>
                    <p id="continent-name">Asie</p>
                    </NavLink>
                </div>
                <div className="col-lg-4 col-12">
                     <NavLink to="/critere"><img id="mini-globe" onClick={this.handleClick} className="5 hvr-grow" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1542672329/Oceanie_n_ymckzn.png"/>
                    <p id="continent-name">Oceanie</p>
                    </NavLink>
                <div className="col-lg-2 col-0 noneMobile"></div>
                </div>
                    {/* <div className="globePays" style={{ marginBottom: 24 }}>
                        <NavLink to="/critere"><button className="btn-pays"> Montréal </button></NavLink>
                        <NavLink to="/critere"><button className="btn-pays"> New-York </button></NavLink>
                        <NavLink to="/critere"><button className="btn-pays"> Paris </button></NavLink>
                        <NavLink to="/critere"><button className="btn-pays"> Londres </button></NavLink>
                    </div>
                    <div id="pooch_stage" className="pooch-stage" style={{ height: 500 }} />
                    <div className="pooch-button-container">
                    <br /> */}
                {/* <button className="pooch-button hvr-float continentBtn" onClick="spinToLocation (1.325, 0.425, 'India')">india</button>
                <button className="pooch-button hvr-float continentBtn" onClick="spinToLocation (2.315, -0.465, 'Australia')">Afrique</button>
                <button className="pooch-button hvr-float continentBtn" onClick="spinToLocation (-1.634, 0.35, 'Mexico')">mexico</button>
                <button className="pooch-button hvr-float continentBtn" onClick="spinToLocation (0.03, 0.7, 'France')">france</button> */}
                {/* </div>
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: ".gist-syntax .err { color:#000; background-color: #FFF; } " }} />
                </div> */}
                
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({onContinentChange}, dispatch);
  }
  
export default connect(null, mapDispatchToProps)(Geolocalisation);
  
