import ScoreIloc from './score'
import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Chart from './chart';

const firestore = firebase.firestore();
const functions = firebase.functions();

var getProfilLocataire = functions.httpsCallable('getProfilLocataire');


class FicheLocataire extends Component {
    constructor(props) {
      super(props);
    }
    state = {
      score: 0,
      nom: "",
      prenom: "",
      photo: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",
    }

    componentDidMount() {
      //Score
      firestore.collection('score_prop').doc(this.props.user.uid).get()
        .then(snap => {
          if (snap.exists) {
            const data = snap.data();
            const score = data.score;

            this.setState({
              score: Math.trunc(score)
            }
            )
          }
        })

      firestore.collection('professionnal_info_locataires').doc(this.props.user.uid).get()
        .then(snap => {
          if (snap.exists) {
            const userData = snap.data();
            console.log(userData);
            this.setState({
              referent_first_name: userData.last_name,
              referent_last_name: userData.first_name,
              referent_poste: userData.referent_poste,
              referent_email: userData.referent_email,
              referent_phone: userData.referent_phone,
              name_entreprise: userData.name_entreprise,
              name_employe: userData.name_employe,
              date_embauche: userData.date_embauche,
              poste: userData.poste,
              salaire: userData.salaire,
              is_temporaire: userData.is_temporaire,
              is_permanent: userData.is_permanent,
              }
            )
          }
        })

        getProfilLocataire(this.props.user.uid).then(result => {
            const user = result.data;
            console.log("PROFIL", user);
            this.setState({ uid: this.props.user.uid });
            if (user.profil) {
              // this.fillwWithData(user.profil);
              this.setState({
                name: user.userRecord.displayName,
                photo: user.userRecord.photoURL,
                email: user.userRecord.email,
                birth_date: user.profil.birth_date,
                city: user.profil.city,
                postal_code: user.profil.postal_code,
                tel: user.profil.tel,
                country: user.profil.country,
                is_professional: user.profil.is_professional,
                is_relationship: user.profil.is_relationship,
                is_single: user.profil.is_single,
                is_student: user.profil.is_student,
              })
            }
        })
    }

    render() {
        return (
            <div>
                <header id="fiche_header">             
                    <div className="container">
                        <div className="row center_mobile">
                            <div className="col-lg-6 col-12">
                                <img className="fiche_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                                <br className="show_mobile" />
                                <span className="fiche_name">{this.state.name}</span>
                                <br className="show_mobile" />
                                <span className="member_date">Membre depuis 2019</span>
                            </div>
                            <div className="col-lg-6 col-12">
                                <img className="fiche_approuved" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559610844/fiche_approved_ps6lie.svg" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Renseignements Personnels </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <div className="personnal_data_container">
                        <div className="row align_left">
                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Date de naissance :</span>
                                <span className="data_content">{this.state.birth_date}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Ville :</span>
                                <span className="data_content">{this.state.city}</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Courriel :</span>
                                <span className="data_content">{this.state.email}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Code Postal :</span>
                                <span className="data_content">{this.state.postal_code}</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Téléphone :</span>
                                <span className="data_content">{this.state.tel}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Pays :</span>
                                <span className="data_content">{this.state.country}</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Statut professionnel :</span>
                                <span className="data_content" id="statut_professionnel">{this.state.is_professional ? 'Employé' : ' Étudiant'}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Statut civil  :</span>
                                <span className="data_content">{this.state.is_single ? 'Célibataire' : ' En couple'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Score de location </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="score_container">
                                <ScoreIloc score={this.state.score}></ScoreIloc>
                            </div>
                            <div>
                                <Chart />
                            </div>
                        </div>
                    </div>
                </div>

                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Renseignements professionnels </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-lg-3 col-md-5 col-12 text-center">
                                <p className="primary_btn small_btn_fiche">Emploi(s) actuel(s)</p>
                                <p className="primary_btn small_btn_fiche">Emploi(s) précédent(s</p>
                            </div>
                            <div className="col-lg-9 col-md-7 col-12 margin_top_fiche">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white"> Nom de l’entreprise :</span>
                                        <span className="data_content white">{this.state.name_entreprise}</span>
                                    </div>

                                    <div className="col-12 show_mobile">
                                        <hr className="fiche_hr_white" />
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white">Poste occupé :</span>
                                        <span className="data_content white">{this.state.poste}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="fiche_hr_white" />
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white"> Salaire brut :</span>
                                        <span className="data_content white">{this.state.salaire}</span>
                                    </div>

                                    <div className="col-12 show_mobile">
                                        <hr className="fiche_hr_white" />
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white">Date d'embauche :</span>
                                        <span className="data_content white">{this.state.date_embauche}</span>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <br />
                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> références locatives </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-5 col-12 text-center">
                            <p className="primary_btn small_btn_fiche">Références(s) actuel(s)</p>
                            <p className="primary_btn small_btn_fiche">Références(s) précédent(s</p>
                        </div>
                        <div className="col-lg-9 col-md-7 col-12 margin_top_fiche">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <span className="data_title black">Adresse :</span>
                                    <span className="data_content black">546 Rue Henri</span>
                                </div>

                                <div className="col-12 show_mobile">
                                    <hr className="fiche_hr_black" />
                                </div>

                                <div className="col-lg-6 col-md-12 col-12">
                                    <span className="data_title black">Nom du propriétaire :</span>
                                    <span className="data_content black">Phanuel Rand</span>
                                </div>

                                <div className="col-12">
                                    <hr className="fiche_hr_black" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Casier judiciaire ou dossier de la régie du logement </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}



function mapStateToProps(state) {
    if (state.user) {
        return {
            user: state.user,
        }
    }
}
export default connect(mapStateToProps)(FicheLocataire);