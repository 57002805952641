import React, { Component } from 'react';
import {connect} from 'react-redux';
import firebaseui from 'firebaseui';
import firebase from 'firebase';
import fire from '../js/fire'
import {combineReducers, bindActionCreators} from 'redux';
import {onUserChange} from '../redux/actions/index';
import {firebaseUI} from './signup';

class FormIndividuel extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
          <div className="row text-center">
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Nom du gérant" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Prénom du gérant" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="date" placeholder="Date de naissance" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Courriel" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="tel" placeholder="Numéro de téléphone" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Pays" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Ville" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Code postal" />
              </div>
          </div>
      )
    }
}

class FormCommercial extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
          <div className="row text-center">
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Nom de l'entreprise" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="No de NEQ" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="date" placeholder="Date de création de l'entreprise" />
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                  <input className="form-control form_input" type="text" placeholder="Courriel" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="tel" placeholder="Numéro de téléphone" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Fax" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Pays" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <input className="form-control form_input" type="text" placeholder="Code postal" />
              </div>
          </div>
      )
    }
}


class FormulaireProprietaire extends Component {
    constructor(props) {
        super();
        this.state = {
            type_proprietaire: "individuel",
        }
        this.handleClickGetId = this.handleClickGetId.bind(this);
    }

    handleClickGetId(e) {
        this.setState({ type_proprietaire: e.target.id });
    }

    render() {
      var pageToLoad, type_proprietaire;
      type_proprietaire = this.state.type_proprietaire;

      if (type_proprietaire == "individuel") {
          pageToLoad = <FormIndividuel />;

      } else if (type_proprietaire == "commercial") {
          pageToLoad = <FormCommercial />;
      }
    return (
        <div>
            <header id="notification_header">
                <div className="container">
                    <h1 className="critere_title"> Renseignements personnels</h1>
                </div>
            </header>

            <div style={{ paddingTop: '50px', minHeight: '100vh' }} className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="all_notification_continer">
                            <div className="notification_container">
                                <div className="col-12" style={{marginBottom: '35px'}}>
                                    <span onClick={this.handleClickGetId} id="individuel" className="primary_btn"> Personnel</span> &nbsp;
                                    <span onClick={this.handleClickGetId} id="commercial" className="primary_btn"> Société</span>
                                </div>
                                <div style={{marginTop: '15px;'}}>
                                    {pageToLoad}
                                </div>
                                <div>
                                    <div className="col-lg-12 col-md-12 col-12 text-right" style={{marginTop: '15px'}}>
                                        <span className="primary_btn"> Enregistrer </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default FormulaireProprietaire;
