import React, {useState, useEffect} from 'react'
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      position: 'absolute',
      bottom: '0px'
    },
  });

const SnackbarBlock = (props) => {
    const [nbNotifications, setNbNotifications] = useState(props.nbNotifs);

    useEffect(()=>{
        setNbNotifications(props.nbNotifs);
    },[])

    useEffect(()=>{
      setNbNotifications(props.nbNotifs);
      // console.log(`${nbNotifications} < ${props.nbNotifs} ? `);
      if (nbNotifications < props.nbNotifs)
      {
        // console.log("enqueuing");
        props.enqueueSnackbar('Nouvelle notification');
      }
    },[props.nbNotifs])

    
    const classes = useStyles();
    return (
        <div className={classes.root}></div>
    )
}


function mapStateToProps(state) {
    // console.log("MAP TO STATE REFRESH", state);
      if (state.user) {
        return {
          user: state.user,
          logged: state.logged,
          nbNotifs: state.user.nbNotifs
        }
      }
    }

export default connect(mapStateToProps)(withSnackbar(SnackbarBlock));