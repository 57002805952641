import ScoreIloc from './score'
import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Chart from './chart';

const firestore = firebase.firestore();
const functions = firebase.functions();

var getProfilProprietaire = functions.httpsCallable('getProfilProprietaire');


class FicheProprietaire extends Component {
    constructor(props) {
      super(props);
    }
    state = {
      score: 0,
      nom: "",
      prenom: "",
      photo: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",
    }

    componentDidMount() {
      //Score
      firestore.collection('score_prop').doc(this.props.user.uid).get()
        .then(snap => {
          if (snap.exists) {
            const data = snap.data();
            const score = data.score;

            this.setState({
              score: Math.trunc(score)
            }
            )
          }
        })

        getProfilProprietaire(this.props.user.uid).then(result => {
            const user = result.data;
            console.log("PROFIL", user);
            this.setState({ uid: this.props.user.uid });
            if (user.profil) {
              if(user.profil.type_user === 'individuel') {
                this.setState({
                    type_user: user.profil.type_user,
                    name: user.userRecord.displayName,
                    photo: user.userRecord.photoURL,
                    email: user.userRecord.email,
                    birth_date: user.profil.birth_date,
                    city: user.profil.city,
                    postal_code: user.profil.postal_code,
                    tel: user.profil.tel,
                    country: user.profil.country,
                    })
                }
                else if(user.profil.type_user === 'commercial') {
                    this.setState({
                        type_user: user.profil.type_user,
                        name: user.profil.name_entreprise,
                        photo: user.userRecord.photoURL,
                        country_entreprise: user.profil.country_entreprise,
                        date_creation_entreprise: user.profil.date_creation_entreprise,
                        email_entreprise: user.profil.email_entreprise,
                        fax_entreprise: user.profil.fax_entreprise,
                        name_entreprise: user.profil.name_entreprise,
                        neq_entreprise: user.profil.neq_entreprise,
                        postal_code_entreprise: user.profil.postal_code_entreprise,
                        tel_entreprise: user.profil.tel_entreprise,
                        })
                    }
            }
        })
    }

    render() {

        const individual_profil = <div className="row individuel_profil align_left">
            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Date de naissance :</span>
                <span className="data_content">{this.state.birth_date}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Ville :</span>
                <span className="data_content">{this.state.city}</span>
            </div>

            <div className="col-12">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Courriel :</span>
                <span className="data_content">{this.state.email}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Code Postal :</span>
                <span className="data_content">{this.state.postal_code}</span>
            </div>

            <div className="col-12">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Téléphone :</span>
                <span className="data_content">{this.state.tel}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Pays :</span>
                <span className="data_content">{this.state.country}</span>
            </div>
        </div>;

        const commercial_profil = <div className="row commercial_profil align_left">
            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Nom de l'entreprise :</span>
                <span className="data_content">{this.state.name_entreprise}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">NEQ :</span>
                <span className="data_content">{this.state.neq_entreprise}</span>
            </div>

            <div className="col-12">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Date de création :</span>
                <span className="data_content">{this.state.date_creation_entreprise}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Courriel :</span>
                <span className="data_content">{this.state.email_entreprise}</span>
            </div>

            <div className="col-12">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Téléphone :</span>
                <span className="data_content">{this.state.tel_entreprise}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">FAX :</span>
                <span className="data_content">{this.state.fax_entreprise}</span>
            </div>

            <div className="col-12">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Pays :</span>
                <span className="data_content">{this.state.country_entreprise}</span>
            </div>

            <div className="col-12 show_mobile">
                <hr className="fiche_hr" />
            </div>

            <div className="col-lg-6 col-md-12 col-12">
                <span className="data_title">Code postal :</span>
                <span className="data_content">{this.state.postal_code_entreprise}</span>
            </div>
        </div>;

        return (
            <div>
                <header id="fiche_header">             
                    <div className="container">
                        <div className="row center_mobile">
                            <div className="col-lg-6 col-12">
                                <img className="fiche_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                                <br className="show_mobile" />
                                <span className="fiche_name">{this.state.name}</span>
                                <br className="show_mobile" />
                                <span className="member_date">Membre depuis 2019</span>
                            </div>
                            <div className="col-lg-6 col-12">
                                <img className="fiche_approuved" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559610844/fiche_approved_ps6lie.svg" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Renseignements Personnels </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <div className="personnal_data_container">
                        {this.state.type_user === 'commercial' ? commercial_profil : individual_profil}
                    </div> 
                </div>
                
                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Score de location </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="score_container">
                                <ScoreIloc score={this.state.score}></ScoreIloc>
                            </div>
                            <div>
                                <Chart />
                            </div>
                        </div>
                    </div>
                </div>

                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Références locatives </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-12">
                                <span className="data_title white">Phanuel Randrianandraina: </span>
                                <span className="data_content white">Appartement et chambre clean, service à la clientèle parfait et tarif abordable ! Je recommande les services de Losmose à tous.</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr_white" />
                            </div>

                            <div className="col-12">
                                <span className="data_title white">Elyeser Randrianandraina: </span>
                                <span className="data_content white">Appartement propre, avec une très belle déco. L'équipe a su répondre à mes attentes et la qualité du service est irréprochable.</span>
                            </div>
                        </div> 
                        <br />
                        <br />           
                    </div>
                </header>
                <br />
                <br />
                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Casier judiciaire ou dossier de la régie du logement </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}



function mapStateToProps(state) {
    if (state.user) {
        return {
            user: state.user,
        }
    }
}
export default connect(mapStateToProps)(FicheProprietaire);

