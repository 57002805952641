import ScoreIloc from './score'
import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader'
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import google from 'google-maps';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Chart from './chart';

const firestore = firebase.firestore();
const functions = firebase.functions();

var getProfilLocataire = functions.httpsCallable('getProfilLocataire');


class FicheLocataire extends Component {
    constructor(props) {
      super(props);
    }
    state = {
      score: 0,
      nom: "",
      prenom: "",
      photo: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",
      // dateNaissance: "",
      // email: "",
      // phone: "",
      // pays: "",
      // ville: "",
      // codePostal: "",
      // situation_familliale: "",
      // statut_professionnel: "",
      // Animaux: "",
      onGoingRequest: false
    }

    componentDidMount() {
      //Score
      firestore.collection('score_prop').doc(this.props.user.uid).get()
        .then(snap => {
          if (snap.exists) {
            const data = snap.data();
            const score = data.score;

            this.setState({
              score: Math.trunc(score)
            }
            )
          }
        })

      firestore.collection('professionnal_info_locataires').doc(this.props.user.uid).get()
        .then(snap => {
          if (snap.exists) {
            const userData = snap.data();
            console.log(userData);
            this.setState({
              referent_first_name: userData.last_name,
              referent_last_name: userData.first_name,
              referent_poste: userData.referent_poste,
              referent_email: userData.referent_email,
              referent_phone: userData.referent_phone,
              name_entreprise: userData.name_entreprise,
              name_employe: userData.name_employe,
              date_embauche: userData.date_embauche,
              poste: userData.poste,
              salaire: userData.salaire,
              is_temporaire: userData.is_temporaire,
              is_permanent: userData.is_permanent,
              }
            )
          }
        })

      //Si une demande de fiche a deja été faite
      const uid = this.props.match.params.uid;
      console.log("UID", uid);
      firestore.collection("requests_iloc_loc")
        .where("from", "==", this.props.user.uid)
        .where("to", "==", uid)
        .where("status", "==", "REQUEST")
        .get()
        .then(data => {
          if (data.size > 0) {
            this.setState({
              onGoingRequest: true
            })
          }
        })

      //Fetch les données du locatire
      getProfilLocataire(uid).then(result => {
        const user = result.data;
        console.log("PROFIL", user);
        this.setState({ uid: uid });
        if (user.profil) {
          // this.fillwWithData(user.profil);
          this.setState({
            name: user.userRecord.displayName,
            photo: user.userRecord.photoURL,
            email: user.userRecord.email,
            birth_date: user.profil.birth_date,
            city: user.profil.city,
            postal_code: user.profil.postal_code,
            tel: user.profil.tel,
            country: user.profil.country,
            is_professional: user.profil.is_professional,
            is_relationship: user.profil.is_relationship,
            is_single: user.profil.is_single,
            is_student: user.profil.is_student,
          })
        }
      })
    }

    requestSent = () => {
      this.setState({
        onGoingRequest: true
      })
    }

    render() {
        return (
            <div>
                <header id="fiche_header">             
                    <div className="container">
                        <div className="row center_mobile">
                            <div className="col-lg-6 col-12">
                                <img className="fiche_image" src={this.state.photo || "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"} />
                                <br className="show_mobile" />
                                <span className="fiche_name">{this.state.name}</span>
                                <br className="show_mobile" />
                                <span className="member_date">Membre depuis 2019</span>
                            </div>
                            <div className="col-lg-6 col-12">
                                <img className="fiche_approuved" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559610844/fiche_approved_ps6lie.svg" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Renseignements Personnels </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <div className="personnal_data_container">
                        <div className="row align_left">
                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Date de naissance :</span>
                                <span className="data_content">{this.state.birth_date}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Ville :</span>
                                <span className="data_content">{this.state.city}</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Courriel :</span>
                                <span className="data_content">{this.state.email}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Code Postal :</span>
                                <span className="data_content">{this.state.postal_code}</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Téléphone :</span>
                                <span className="data_content">{this.state.tel}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Pays :</span>
                                <span className="data_content">{this.state.country}</span>
                            </div>

                            <div className="col-12">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Statut professionnel :</span>
                                <span className="data_content" id="statut_professionnel">{this.state.is_professional ? 'Employé' : ' Étudiant'}</span>
                            </div>

                            <div className="col-12 show_mobile">
                                <hr className="fiche_hr" />
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">
                                <span className="data_title">Statut civil  :</span>
                                <span className="data_content">{this.state.is_single ? 'Célibataire' : ' En couple'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Score de location </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="score_container">
                                <ScoreIloc score={this.state.score}></ScoreIloc>
                            </div>
                            <div>
                                <Chart />
                            </div>
                        </div>
                    </div>
                </div>

                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Renseignements professionnels </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-lg-3 col-md-5 col-12 text-center">
                                <p className="primary_btn small_btn_fiche">Emploi(s) actuel(s)</p>
                                <p className="primary_btn small_btn_fiche">Emploi(s) précédent(s</p>
                            </div>
                            <div className="col-lg-9 col-md-7 col-12 margin_top_fiche">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white"> Nom de l’entreprise :</span>
                                        <span className="data_content white">{this.state.name_entreprise}</span>
                                    </div>

                                    <div className="col-12 show_mobile">
                                        <hr className="fiche_hr_white" />
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white">Poste occupé :</span>
                                        <span className="data_content white">{this.state.poste}</span>
                                    </div>

                                    <div className="col-12">
                                        <hr className="fiche_hr_white" />
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white"> Salaire brut :</span>
                                        <span className="data_content white">{this.state.salaire}</span>
                                    </div>

                                    <div className="col-12 show_mobile">
                                        <hr className="fiche_hr_white" />
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12">
                                        <span className="data_title white">Date d'embauche :</span>
                                        <span className="data_content white">{this.state.date_embauche}</span>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <br />
                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> références locatives </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-5 col-12 text-center">
                            <p className="primary_btn small_btn_fiche">Références(s) actuel(s)</p>
                            <p className="primary_btn small_btn_fiche">Références(s) précédent(s</p>
                        </div>
                        <div className="col-lg-9 col-md-7 col-12 margin_top_fiche">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <span className="data_title black">Adresse :</span>
                                    <span className="data_content black">546 Rue Henri</span>
                                </div>

                                <div className="col-12 show_mobile">
                                    <hr className="fiche_hr_black" />
                                </div>

                                <div className="col-lg-6 col-md-12 col-12">
                                    <span className="data_title black">Nom du propriétaire :</span>
                                    <span className="data_content black">Phanuel Rand</span>
                                </div>

                                <div className="col-12">
                                    <hr className="fiche_hr_black" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <header id="fiche_header">   
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fiche_title"> Casier judiciaire ou dossier de la régie du logement </h1>
                                <p className="text-center">
                                    <img className="fiche_verified" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1559611538/verified_fcwqm4.svg" />
                                    <span className="fiche_verified_text">Informations vérifiées</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}



function mapStateToProps(state) {
    if (state.user) {
        return {
            user: state.user,
        }
    }
}
export default connect(mapStateToProps)(FicheLocataire);
























// import React, { Component } from 'react';
// import firebase from 'firebase';
// import {connect} from 'react-redux';

// import { NavLink } from 'react-router-dom';

// const functions = firebase.functions();
// const firestore = firebase.firestore();
// var getFicheIlocLocataire = functions.httpsCallable('getFicheIlocLocataire');


// class Star extends Component {


//   render() {
//     return (
//       <i className="fa fa-star startFiche" aria-hidden="true" />
//     )
//   }
// }

// class ScoreNumber extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     let rows =[];
//     console.log(this.props.score);
//     for (let i=0; i < this.props.score /4; i++)
//     {
//       rows.push(<Star key={i} />)
//     }

//     return (
//       <span> {rows} </span>
//     )
//   }
// }

// class InfoPerso extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     return (
//       <div className="card" id="profilRight">
//         <div className="card-header-fiche text-left">
//         <h3 style={{ margin: '0 auto', textAlign: 'left' }} className="text-left titreInfoPerso">Renseignements personnels <span style={{fontSize: '15px', color: '#29AE59', marginLeft: '10px'}}><img src="https://img.icons8.com/color/30/000000/verified-account.png"/>Verifié</span></h3>
//       </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row text-left">
//             <div className="col-lg-6 col-12">
//               <p><span id="infoPersoTitre"> Date de Naissance: </span> {this.props.dateNaissance} </p>
//               <p><span id="infoPersoTitre"> E-mail: </span> {this.props.email}  </p>
//               <p><span id="infoPersoTitre"> Téléphone: </span> {this.props.phone}  </p>
//               <p><span id="infoPersoTitre"> Pays: </span> {this.props.pays}  </p>
//             </div>

//             <div className="col-lg-6 col-12">
//               <p><span id="infoPersoTitre"> Ville: </span> {this.props.ville}  </p>
//               <p><span id="infoPersoTitre"> Code Postal: </span> {this.props.codePostal}  </p>
//               <p><span id="infoPersoTitre"> Statut professionnel: </span> {this.props.situationFamilliale} </p>
//               <p><span id="infoPersoTitre"> Statut Civil: </span> {this.props.statutCivil} </p>
//               {/* <p><span id="infoPersoTitre"> Animaux: </span> {this.props.Animaux} </p> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// class FicheIlocLocataire extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       statusIndex : 0
//     }
//   }
//   status = [
//     // {status:'En recherche', color:'green'},
//     // {status:'Cession/Transfert', color:'orange'},
//     // {status:'Iloc', color: 'red'},
//     {status:'En recherche', description: '(Vous recherchez un appartement)', lien:'🖐', color: 'green'},
//     // {status:'Cession/Transfert',  description: '(Échanger / Transférer votre bail avec un autre locataire)', lien:'👊', color: 'orange'},
//     {status:'Iloc',  description: '(Vous êtes en location)', lien: '✌', color: 'red'},
//   ]

//   changeStatus (e) {
  
//   }

//   componentWillMount(){
 
//   }
  
//   render() {
//     var color = this.status[this.state.statusIndex].color;
//     var lien = this.status[this.state.statusIndex].lien;
//     var description = this.status[this.state.statusIndex].description;
//     return (
//       <div className="row">
//         {/* <div id="profilLeft" className="col-lg-2 col-md-12 col-sm-12">
//           <div>
//             <div className="cardInsideImage text-center" id="spaceAccount">
//               <div id="profile">
//                 <div>
//                   <img id="profilIMG" src={this.props.picture} />                
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-1 col-0" /> */}
//         <div className="col-lg-12 col-md-12 col-sm-12 alignCenter">
//           <div className="row">
//             <div className="col=lg-6 col-md-6 col-12">
//                <p className="profilName">{this.props.prenom} {this.props.nom}</p> 
//             </div>

//             <div className="col=lg-6 col-md-6 col-12 text-right">
//               <span className="fiche-titre">Fiche ILOC</span>
//               <div className="scoreContainer text-right">       
//                 <ScoreNumber score={this.props.score} />
//               </div>
//             </div>
//           </div>        
//           <br />
//           <InfoPerso dateNaissance={this.props.dateNaissance} email={this.props.email} phone={this.props.phone} 
//         pays={this.props.pays} ville={this.props.ville} codePostal={this.props.codePostal} 
//         situationFamilliale={this.props.situationFamilliale} statutCivil={this.props.statutCivil} />
//         </div>
//       </div>
//     )
//   }
// }

// class EmploiActuel extends Component {
//   render() {
//     return (
//       <div id="empActuel">
//         <h5>Emplois actuels</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>Nom de l'entreprise</p>
//         <p>Titre de l'emploi</p>
//         <p>Salaire</p>
//       </div>
//     )
//   }
// }

// class EmploiPrecedent extends Component {
//   render() {
//     return (
//       <div id="empPrecedent">
//         <h5>Emplois précédents</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//           <p>1ère Année</p>
//           <p>Nom de l'entreprise</p>
//           <p>Titre de l'emploi</p>
//           <p>Salaire</p>
//           <hr style={{borderColor: 'rgba(99, 99, 99, 0.165)'}} />
//           <p>2e Année</p>
//           <p>Nom de l'entreprise</p>
//           <p>Titre de l'emploi</p>
//           <p>Salaire</p>
//           <hr style={{borderColor: 'rgba(99, 99, 99, 0.165)'}} />
//           <p>3e Année</p>
//           <p>Nom de l'entreprise</p>
//           <p>Titre de l'emploi</p>
//           <p>Salaire</p>
//           <hr style={{borderColor: 'rgba(99, 99, 99, 0.165)'}} />
//         </div>
//     )
//   }
// }


// class InfoProf extends Component {
//   constructor() {
//     super();
//     this.state = {
//       typeUser: "empActuel",
//     }
//     this.handleClickForm = this.handleClickForm.bind(this);
//   }
//   handleClickForm(e) {
//     this.setState({typeUser: e.target.id});
//     console.log(e.target.id); 
//   }

//   render() {
//      var pageToLoad, typeUtilisateur;
//      typeUtilisateur = this.state.typeUser;

//     if (typeUtilisateur == "empActuel") {
//       pageToLoad = <EmploiActuel />;
//     } else if (typeUtilisateur == "empPrecedent"){
//       pageToLoad = <EmploiPrecedent />;
//     }

//     return (
//       <div className="card" id="profilRight">
//         <div className="card-header-fiche">
//           <h3 style={{ margin: '0 auto', backgroundColor: '#000' }} className="text-left titreInfoPerso">Renseignements professionnels</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row">
//             <div style={{ margin: '15px 0' }} className="col-12">
//               <span onClick={this.handleClickForm} className="profil-option" id="empActuel">Emp. actuels</span> &nbsp; &nbsp;
//                 <span onClick={this.handleClickForm} className="profil-option" id="empPrecedent">Emp. précédents</span>
//             </div>
//           </div>
//           <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)', width: '100%' }} />
//           <div className="row text-left">
//             <div className="col-12">
//               {pageToLoad}
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// class RefActuel extends Component {
//   render() {
//     return (
//       <div>
//          <h5>Références actuelles</h5>
//             <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//             <p>Nom du propriétaire</p>
//       </div>
//     )
//   }
// }

// class RefPrecedent extends Component {
//   render() {
//     return (
//       <div>
//         <h5>Emplois précédents</h5>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>1ère Année</p>
//         <p>Nom du propriétaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>2e Année</p>
//         <p>Nom du propriétaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         <p>3e Année</p>
//         <p>Nom du propriétaire</p>
//         <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)' }} />
//         </div>
//     )
//   }
// }

// class RefLocatives extends Component {
//   constructor() {
//     super();
//     this.state = {
//       typeUser: "RefActuel",
//     }
//     this.handleClickForm = this.handleClickForm.bind(this);
//   }
//   handleClickForm(e) {
//     this.setState({typeUser: e.target.id});
//     console.log(e.target.id); 
//   }

//   render() {
//      var pageToLoad, typeUtilisateur;
//      typeUtilisateur = this.state.typeUser;

//     if (typeUtilisateur == "RefActuel") {
//       pageToLoad = <EmploiActuel />;
//     } else if (typeUtilisateur == "RefPrecedent"){
//       pageToLoad = <EmploiPrecedent />;
//     }

//     return ( 
//       <div className="card" id="profilRight">
//         <div className="card-header-fiche">
//           <h3 style={{ margin: '0 auto', backgroundColor: '#000' }} className="text-left titreInfoPerso">Références locatives</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           <div className="row">
//             <div style={{ margin: '15px 0' }} className="col-12">
//               <span onClick={this.handleClickForm}  className="profil-option" id="RefActuel">Réf. actuelles</span> &nbsp; &nbsp;
//                 <span onClick={this.handleClickForm}  className="profil-option" id="RefPrecedent">Réf. précédents</span>
//             </div>
//           </div>
//           <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)', width: '100%' }} />
//           <div className="row text-left">
//             <div className="col-12">
//               {pageToLoad}
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }


// class CasierJudiciere extends Component {
//   constructor() {
//     super();
//     this.state = {
//       typeUser: "RefActuel",
//     }
//     this.handleClickForm = this.handleClickForm.bind(this);
//   }
//   handleClickForm(e) {
//     this.setState({typeUser: e.target.id});
//     console.log(e.target.id); 
//   }

//   render() {
//      var pageToLoad, typeUtilisateur;
//      typeUtilisateur = this.state.typeUser;

//     if (typeUtilisateur == "RefActuel") {
//       pageToLoad = <EmploiActuel />;
//     } else if (typeUtilisateur == "RefPrecedent"){
//       pageToLoad = <EmploiPrecedent />;
//     }

//     return ( 
//       <div className="card" id="profilRight">
//         <div className="card-header-fiche">
//           <h3 style={{ margin: '0 auto', backgroundColor: '#000' }} className="text-left titreInfoPerso">Casier Jucidiaire ou Dossier de la régie du logement</h3>
//         </div>
//         <div className="card-body paddingAllMobile">
//           {/* <div className="row">
//             <div style={{ margin: '15px 0' }} className="col-12">
//               <span onClick={this.handleClickForm}  className="profil-option" id="RefActuel">Réf. actuelles</span> &nbsp; &nbsp;
//                 <span onClick={this.handleClickForm}  className="profil-option" id="RefPrecedent">Réf. précédents</span>
//             </div>
//           </div> */}
//           {/* <hr style={{ borderColor: 'rgba(99, 99, 99, 0.165)', width: '100%' }} /> */}
//           <div className="row text-left">
//             <div className="col-12">
//               À venir
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }


// class ProfilLocataire extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       nom: "",
//       prenom: "",
//       score: 20,
//       picture: "https://res.cloudinary.com/dqoxbvata/image/upload/v1539120591/blankProfil_iije9x.jpg",

//       dateNaissance: "",
//       email: "",
//       phone: "",
//       pays: "",
//       ville: "",
//       codePostal: "",
//       situationFamilliale: "",
//       statutCivil: "",
//       // Animaux: ""
//     }
//   }

//   componentDidMount() {
//     // fetch('https://randomuser.me/api/')
//     // .then(results => {
//     //   return results.json();
//     // }).then(data => {
//     //   data = data.results[0];
//     //   this.setState({
//     //     nom: data.name.last,
//     //     prenom: data.name.first,
//     //     score: data.dob.age,
//     //     picture: data.picture.large,

//     //     dateNaissance: data.dob.date,
//     //     email: data.email,
//     //     phone: data.cell,
//     //     pays: data.location.city,
//     //     ville: data.location.state,
//     //     codePostal: data.location.postcode,
//     //     situationFamilliale: data.gender,
//     //     statutCivil: data.nat,
//     //     Animaux: ""
//     //   })
//     // })
//     const uid = this.props.match.params.uid;
//     const user= this.props.user;
//     getFicheIlocLocataire(uid).then(result=>{
//       const user= result.data;
//       console.log("USER", user);
//       if (user){
//         this.setState({
//           email: user.email,
//           picture: user.photoURL,
//           nom: user.displayName,
//           score: 20,
//         });
//       }
//   })    
    

    
//   }


//   render() {
//     return (
//       <section className="MargeTop" id="accountInfo">
//        <NavLink to="/profil"><img id="backbtn" src="https://img.icons8.com/ios/30/000000/left.png" /></NavLink>
//         <div className="container">
//         <div className="margeProfil">
//         <FicheIlocLocataire user={this.props.user} nom={this.state.nom} prenom={this.state.prenom} score={this.state.score} picture={this.state.picture} 
//         dateNaissance={this.state.dateNaissance} email={this.state.email} phone={this.state.phone} 
//         pays={this.state.pays} ville={this.state.ville} codePostal={this.state.codePostal} 
//         situationFamilliale={this.state.situationFamilliale} statutCivil={this.state.statutCivil} />
//         </div>
        
//         <div><InfoProf /></div>
        
//         <RefLocatives />
//         {/* <MapProfil /> */}
//         <CasierJudiciere />
//         </div>
//       </section>
//     )
//   }
// }

// function mapStateToProps(state) {
//   if (state.user)
//   {
//     return {
//       user: state.user,
//     }
//   }
// }

// export default connect (mapStateToProps)(ProfilLocataire);