import React, { Component } from 'react';
import CritereLocataire from './critere_locataire';
import {connect} from 'react-redux';
import CritereProprietaire from './critere_proprietaire';

class Critere extends Component {
  constructor() {
    super();
    this.state = {
      typeUser: "",
    }
  //  this.initMap = this.initMap.bind(this);
  }

  // initMap() {
  //   var myLatLng = { lat: 45.593880, lng: -73.335436 };

  //   var map = new google.maps.Map(document.getElementById('map'), {
  //       zoom: 18,
  //       center: myLatLng
  //   });

  //   var marker = new google.maps.Marker({
  //       position: myLatLng,
  //       map: map,
  //       title: ''
  //   });
  // }
  componentDidMount() {
   
  }
  
  render() {
    var pageToLoad, typeUtilisateur;
    typeUtilisateur = this.props.user.type;
    if (typeUtilisateur == "loc") {
      pageToLoad = <CritereLocataire />;
    } else {
      pageToLoad = <CritereProprietaire />;
    }
    return (
        <div>        
           {pageToLoad}
        </div>
    )
  }
}
function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,    
    }
  }
}

export default connect(mapStateToProps)(Critere);
