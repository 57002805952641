import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import google from 'google-maps';
import MarkerClusterer from '@google/markerclusterer'
import { GeoCollectionReference, GeoFirestore, GeoQuery, GeoQuerySnapshot } from 'geofirestore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import $ from 'jquery';
import { merge } from 'popmotion';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Slider from '@material-ui/lab/Slider';
import firebase from 'firebase';
// import * as functions from 'firebase/functions'
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

const firestore = firebase.firestore();
const geofirestore = new GeoFirestore(firestore);
const functions = firebase.functions();
const styleMap = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]

const mode = {
  DEFAULT: "default",
  ADD: "add",
  MODIFY: "modify",
  DELETE: "delete"
}

// var searchBox = new google.maps.places.SearchBox(input);
class CritereLocataire extends Component {
  nb = 0;
  prevCenter = 0;

  state = {
    action_type: "",
    mapsReady: true,
    map: {},
    searchBox: {},
    autocomplete: {},
    proprietaires: [],
    clusters: [],
    priceRange: [200,1500],
    chauffe: false,
    animaux: false,
    eau_chaude: false,
    laveuse_secheuse: false,
    eclairee: false,
    lave_vaisselle: false,
    address: "",
    type: "Chambre",
    loyer: 0,
    mode: mode.DEFAULT,
    circles: new Map(),
  }

  constructor(props) {
    super(props);
    this.handleClickGetId = this.handleClickGetId.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.toggle = this.toggle.bind(this);
    this.triggerNotification = true;
    this.promiseReceivingGoogleMaps = this.promiseReceivingGoogleMaps.bind(this);
    this.setMapAndSearchBox = this.setMapAndSearchBox.bind(this);
    this.addMarker = this.addMarker.bind(this);
    this.promiseReceivingGoogleMaps()
      .then(google => {
        this.google = google;
        this.setMapAndSearchBox(google);
      });
    this.addCircle = this.addCircle.bind(this);
    this.onZoneChanged = this.onZoneChanged.bind(this);
    // this.addModeRef = React.createRef();
    // this.modifyModeRef = React.createRef();
    // this.deleteModeRef = React.createRef();
  }

  handleClickGetId(e) {
    this.setState({ action_type: e.target.id });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  componentWillReceiveProps(nextProps) {

  }

  componentDidMount() {
    // $(".js-range-slider").ionRangeSlider();
  }

  promiseReceivingGoogleMaps = (maps) => {
    return new Promise((maps) => {
      return google.load(maps)
    })
  };

  addMarker(latLng, userRecord = null) {
    //console.log('ADDING MARKER USER RECORD ', userRecord);
    var image = {
      url: "https://res.cloudinary.com/dqoxbvata/image/upload/c_scale,h_43,w_35/v1559255915/mapmarker_vbczmu.svg",
      // size: new google.maps.Size(20, 32),
      // origin: new google.maps.Point(0,0),
      // anchor: new google.maps.Point(0, 0)
    };

    var marker = new this.google.maps.Marker({
      position: latLng,
      map: this.map,
      icon: image,
      animation: this.google.maps.Animation.DROP,
    });
    marker.getPosition = () => {return marker.position};
    // this.markerCluster.addMarker(marker);
    // this.state.clusters.push(latLng);
    
    var infowindow;
    if (userRecord) {
      console.log('ADDING MARKER', userRecord);
      var contentString = `<div class="text-center"><img class="map_image" src="${userRecord.imageAppart.downloadUrl|| "https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329"}" /><p class="appartment_name_map">${userRecord.dataAppart.descriptif}  ${userRecord.dataAppart.type}   <br /> <span class="prix_map"> ${userRecord.dataAppart.loyer}$ / mois </span></p> <a href=/profilAppart/${userRecord.locId} class="primary_btn_map"> Details </span> </div>`;

      infowindow = new this.google.maps.InfoWindow({
        content: contentString
      });
    }

    marker.addListener('click', (e) => {
      var newLatLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      };
      this.map.panTo(newLatLng);
      this.map.setZoom(13);

      if (infowindow) {
        infowindow.open(this.map, marker);
      }
    })



  }

  geocodePromise = (address) => new Promise((resolve) => {
    return this.geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        });
      }
    })
  }
  );


  async setMapAndSearchBox(google, query = "") {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: 45.516136,
        lng: -73.65683
      },
      zoom: 3,
      mapTypeId: 'roadmap',
      styles: styleMap
    });

    // this.markerCluster = new MarkerClusterer(map, {
    //   imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    // })

    this.map = map;
    var geocoder = new google.maps.Geocoder();
    this.geocoder = geocoder;

    var db = firebase.firestore();
    var proprietairesUid = [];

    var getUser = functions.httpsCallable('getUserInfo');

    var dbQuery = db.collection('apparts');


    if (query) {
      query.map(aQuery => {
        console.log("aQuery.value != false",  aQuery.value != false);
          console.log("Applying filter", aQuery);
          dbQuery = dbQuery.where(`d.${aQuery.filter}`, aQuery.operation, aQuery.value);
      })
    }

    var photoData;
   await dbQuery.get().then(async (querySnapshot) => {
      await querySnapshot.forEach(async (doc) => {
        await doc.ref.collection('photos').get().then(async snap => {
          await snap.forEach(async imageRef => {
           
            const imageData = imageRef.data()
            this.setState({
              imageData: imageData,
            })
            // console.log("Data Apaprtements", imageData)
            photoData = imageData
            return (imageData);
          })
        }) 
        
        // console.log('fesfsefsef', photoData );
        
        var dataAppart = doc.data().d;
        var address = doc.data().address;
        var coord = doc.data().l;
        var proprioUid = doc.data().d.proprio;
        let userRecord = {};
        userRecord.dataAppart = dataAppart;
        userRecord.locId = doc.id;
        if (!photoData)
        {
          userRecord.imageAppart = {downloadUrl: "https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" };
        }
        else{
          userRecord.imageAppart = photoData;
        }

          console.log("GETTING COORD", userRecord);
          if (coord) {
            console.log('THIS IS THE COORD', coord);
            var latLng = { lat: coord.latitude, lng: coord.longitude };
            this.addMarker(latLng, userRecord);
          }
          else {
            this.geocodePromise(address)
              .then(latLng => this.addMarker(latLng, userRecord))
              .catch(e => console.log('error in geocode', e))
          }
        

      })
    });

    var input = document.getElementById('map_input');

    var searchBox = new google.maps.places.SearchBox(input);
    searchBox.bindTo('bounds', map);
    this.setState({
      map: map,
      searchBox: searchBox,
    });

    map.addListener('bounds_changed', function () {
      //searchBox.setBounds(map.getBounds());
    }.bind(this));

    map.addListener('click', (event) => {if (this.state.mode === mode.ADD) this.addCircle(event)});

    const zonesRef = db.collection('zones_locataires').where("d.uid", "==", this.props.user.uid);
    zonesRef.get().then((data) => {

      data.docs.map((data) => {
        //console.log("ZONES", data.data());
        var circleData = data.data();
        circleData.id = data.id;
        circleData.latLng = { lat: circleData.l.latitude, lng: circleData.l.longitude };
        this.addCircle(circleData, false);
      });
    })


    searchBox.addListener('places_changed', function () {
      var place = searchBox.getPlaces()[0];

      if (place.length == 0) {
        return;
      }

      var bounds = new google.maps.LatLngBounds();
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      map.fitBounds(bounds);
    });

    //Map controls
    //add
    var propAddControlDiv = document.createElement('div');
    var propAddControl = new this.addPropControlAdd(propAddControlDiv, map);
    propAddControlDiv.index = 1;
    propAddControlDiv.addEventListener('click', () => {
      this.setState(previousState => {
        return {mode: previousState.mode == mode.ADD ? mode.DEFAULT : mode.ADD }
      })
    })

    //modify
    var propAddControlDivModify = document.createElement('div');
    var propAddControlModify = new this.addPropControlModify(propAddControlDivModify, map);
    propAddControlDivModify.index = 1;
    propAddControlDivModify.addEventListener('click', () => {
      this.setState(previousState => {
        return {mode: previousState.mode == mode.MODIFY ? mode.DEFAULT : mode.MODIFY }
      })
    })

    //delete
    var propAddControlDivDelete = document.createElement('div');
    var propAddControlDelete = new this.addPropControlDelete(propAddControlDivDelete, map);
    propAddControlDivDelete.index = 1;
    propAddControlDivDelete.addEventListener('click', () => {
      this.setState(previousState => {
        return {mode: previousState.mode == mode.DELETE ? mode.DEFAULT : mode.DELETE }
      })      
    })

    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(propAddControlDiv);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(propAddControlDivModify);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(propAddControlDivDelete );
    var nextProps = this.props;

    if (nextProps.geolocalisation) {
      var continent = nextProps.geolocalisation.continent;
      var loc = {};
      var zoom = 5;
      if (continent === "EUROPE") {
        loc = {
          lat: 48.864716,
          lng: 2.349014
        };
        zoom = 4;
      }
      else if (continent === "AFRIQUE") {
        loc = {
          lat: 6.620935,
          lng: 18.6927236
        };
        zoom = 3;
      }
      else if (continent === "AMERIQUE") {
        loc = {
          lat: 36.3,
          lng: -99
        };
        zoom = 3;
      }
      else if (continent === "ASIE") {
        loc = {
          lat: 39.913818,
          lng: 116.363625
        };
        zoom = 3;
      }
      else if (continent === "OCEANIE") {
        loc = {
          lat: -23.6993534,
          lng: 133.8757526
        };
        zoom = 3;
      }
      map.setCenter(
        loc
      );
      map.setZoom(zoom);
    }

    console.log("CLUSTERS", this.state.clusters)
 

  }

  onNewResearchClick() {
    var typeProprio = $('#')
  }

  resetPanier() {

  }

  handleClick() {
    var db = firebase.firestore();
    console.log('saving firebase');

    const is_residentiel = $("#residentiel").prop('checked');
    const is_studio = $("#is_appartement").prop('checked');
    const is_condo = $('#is_condo').prop('checked');
    const is_maison = $('#is_maison').prop('checked');
    const is_chalet = $('#is_chalet').prop('checked');
    const is_collocation = $('#is_collocation').prop('checked');
    const is_3demi = $('#is_3demi').prop('checked');
    const is_4demi = $('#is_4demi').prop('checked');
    const is_5demi = $('#is_5demi').prop('checked');
    const is_plus = $('#is_plus').prop('checked');

    const is_commercial = $('#is_commercial').prop('checked');
    const is_salleexposition = $('#is_salleexposition').prop('checked');
    const is_bureaugeneral = $('#is_bureaugeneral').prop('checked');
    const is_salleconference = $('#is_salleconference').prop('checked');
    const is_centreaffaire = $('#is_centreaffaire').prop('checked');
    const is_institutionnel = $('#is_institutionnel').prop('checked');
    const is_loftcommercial = $('#is_loftcommercial').prop('checked');
    const is_studiocommercial = $('#is_studiocommercial').prop('checked');
    const is_garage = $('#is_garage').prop('checked');
    const is_restaurant = $('#is_restaurant').prop('checked');
    const is_commercerue = $('#is_commercerue').prop('checked');
    const is_restaurantvolant = $('#is_restaurantvolant').prop('checked');

    const is_student = $('#is_student').prop('checked');
    const is_employed = $('#is_employed').prop('checked');
    const is_contractuel = $('#is_contractuel').prop('checked');
    const is_statutTemp = $('#is_statutTemp').prop('checked');
    const is_tourist = $('#is_tourist').prop('checked');

    const nb_chats = $('#nb_chats').prop('value');
    const nb_chiens = $('#nb_chiens').prop('value');

    const zoneRadius = $('#zoneradius').val();

    const nb_chambres = $('#nb_chambres').prop('value');
    const nb_stationnements = $('#nb_stationnements').prop('value');
    const nb_sallebain = $('#nb_sallebain').prop('value');
    const nb_garage = $('#nb_garage').prop('value');
    const prixMin = $('#prixMin').val();
    const prixMax = $('#prixMax').val();

    const new_critere =
    {
      is_residentiel: is_residentiel,
      is_studio: is_studio,
      is_condo: is_condo,
      is_maison: is_maison,
      is_chalet: is_chalet,
      is_collocation: is_collocation,
      is_3demi: is_3demi,
      is_4demi: is_4demi,
      is_5demi: is_5demi,
      is_plus: is_plus,

      is_commercial: is_commercial,
      is_salleexposition: is_salleexposition,
      is_bureaugeneral: is_bureaugeneral,
      is_salleconference: is_salleconference,
      is_centreaffaire: is_centreaffaire,
      is_institutionnel: is_institutionnel,
      is_loftcommercial: is_loftcommercial,
      is_studiocommercial: is_studiocommercial,
      is_garage: is_garage,
      is_restaurant: is_restaurant,
      is_commercerue: is_commercerue,
      is_restaurantvolant: is_restaurantvolant,

      is_student: is_student,
      is_professional: is_employed,
      is_contractuel: is_contractuel,
      is_statutTemp: is_statutTemp,
      is_tourist: is_tourist,

      nb_chats: nb_chats,
      nb_chiens: nb_chiens,

      zoneRadius: zoneRadius,

      nb_chambres: nb_chambres,
      nb_stationnements: nb_stationnements,
      nb_sallebain: nb_sallebain,
      nb_garage: nb_garage,
      prixMin: prixMin,
      prixMax: prixMax,
    };

    const {
    priceRange,
    chauffe,
    animaux,
    eau_chaude,
    laveuse_secheuse,
    eclairee,
    lave_vaisselle,
    type
    } = this.state

    console.log("PRICEMIN",priceRange[0]);
    console.log("PRICEMAX",priceRange[1]);
    var query = [
    {filter:"loyer", "operation": ">=", "value": priceRange[0]},
    {filter:"loyer", "operation": "<=", "value": priceRange[1]},
    {filter:"type",  "operation": "==", "value": type}
    ];
    
    if (chauffe) query.push({filter:"criteres.chauffe", "operation": "==", "value": "true"})
    if (animaux) query.push({filter:"criteres.animaux", "operation": "==", "value": "true"})
    if (eau_chaude) query.push({filter:"criteres.eau_chaude", "operation": "==", "value": "true"})
    if (laveuse_secheuse) query.push({filter:"criteres.laveuse_secheuse", "operation": "==", "value": "true"})
    if (eclairee) query.push({filter:"criteres.eclairee", "operation": "==", "value": "true"})
    if (lave_vaisselle) query.push({filter:"criteres_.lave_vaisselle", "operation": "==", "value": "true"})

    this.setMapAndSearchBox(this.google, query);
  }

  addPropControlAdd = (controlDiv, map) => {

    // Set CSS for the control border.
    var controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginLeft = '13px';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Click to add a propery';
    controlDiv.appendChild(controlUI);
    
    // Set CSS for the control interior.
    var controlText = document.createElement('div');
    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML = 'Add mode';
    this.addModeRef = controlText;
    controlUI.appendChild(controlText);
  }


  addPropControlModify = (controlDiv, map) => {

    // Set CSS for the control border.
    var controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginLeft = '13px';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Click to add a propery';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    var controlText = document.createElement('div');
    // controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML = 'Edit zone';
    this.modifyModeRef = controlText;
    controlUI.appendChild(controlText);
  }

  addPropControlDelete = (controlDiv, map) => {

    // Set CSS for the control border.
    var controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginLeft = '13px';
    controlUI.style.marginBottom = '22px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Click to add a propery';
    controlDiv.appendChild(controlUI);


    // Set CSS for the control interior.
    var controlText = document.createElement('div');
    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '5px';
    controlText.style.paddingRight = '5px';
    controlText.innerHTML = 'Delete mode';
    this.deleteModeRef = controlText;
    controlUI.appendChild(controlText);
  }

  addCircle(event, isNewCircle = true) {

    const latLng = event.latLng;
    // console.log("adding", event)
    let newCircle = new this.google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      center: latLng,
      radius: 100,
      draggable: false,
      editable: false,
    });

    if (isNewCircle) {
      newCircle.setRadius(100)
    }
    else {
      newCircle.id = event.id;
      newCircle.setRadius(event.d.radius);
    }

    const zonesRef = geofirestore.collection('zones_locataires');

    if (isNewCircle) { //add the new circle to the db
      const newZone = zonesRef.add({
        coordinates: new firebase.firestore.GeoPoint(latLng.lat(), latLng.lng()),
        radius: newCircle.radius,
        uid: firebase.auth().currentUser.uid
      })

      newCircle.id = newZone.id;
      const circlesCopy = this.state.circles;
    
      circlesCopy.set(newZone.id, { //add the newcircle to the map
        radius: newCircle.radius,
        center: {
          ...latLng
        },
        marker: newCircle
      });
      this.setState({
        circles: circlesCopy
      })

    }
    else {
      // console.log("CIRCLES", this.state.circles);
      const circlesCopy = this.state.circles;
      circlesCopy.set(event.id, { //add the existing circle to the map
        radius: event.d.radius,
        center: {
          ...latLng
        },
        marker: newCircle
      });
      this.setState({
        circles: circlesCopy
      })      
    }

    newCircle.addListener('click', (e) => {
      if (this.state.mode === mode.DELETE)
      {
        this.setState({ dialogDelete: true, lastCircle: newCircle, });
      }

    });

    newCircle.addListener('radius_changed', (e) => {
      var newRadius = newCircle.getRadius();
      var center = newCircle.getCenter();
      this.setState({
        dialog: true,
        lastCircle: newCircle,
        after: {
          radius: newRadius,
          center: center
        }
      });


    });

    newCircle.addListener('dragend', e => {
      // this.onZoneChanged(newCircle);
      var newRadius = newCircle.getRadius();
      var center = newCircle.getCenter();
      this.setState({
        dialog: true,
        lastCircle: newCircle,
        after: {
          radius: newRadius,
          center: center
        }
      });
    })
  }

  onZoneChanged(newCircle) {
    var newRadius = newCircle.getRadius();
    var center = newCircle.getCenter();

    const geoCollection = geofirestore.collection('apparts');
    const query = geoCollection.near({
      center: new firebase.firestore.GeoPoint(center.lat(), center.lng()),
      radius: Math.floor(newRadius / 1000)
    })

    if (!this.triggerNotification)
      return;

    query.get().then(apparts => {
      apparts.docs.map(appart => {
        var proprio = appart.data().proprio;
        console.log("Found appart", proprio);
        var sendNotification = functions.httpsCallable('sendNotification');
        sendNotification({ to: proprio, message: "PROPRIO_IN_ZONE" });
      })
    })

    this.triggerNotification = false;
    setTimeout(() => this.triggerNotication = true, 30000)
  }

  clickedVoirProfil = (userRecord) => {
    console.log("Clicked on ", userRecord);
    this.setState({
      clickedProfile: userRecord
    })
    this.toggle();
  }

  dialogClose = (confirmed) => {

    if (!this.state.lastCircle) return;

    const id = this.state.lastCircle.id;

    const theCircle = this.state.lastCircle;

    if (confirmed) {
      let theNewRadius = theCircle.getRadius();
      // console.log("THE CIRCLE IN QUESTION theNewRadius before: ", theNewRadius);
      if (theNewRadius > 1000) {
        theNewRadius = 1000;
      }
      else if (theNewRadius < 200) {
        theNewRadius = 200
      }
      // console.log("THE CIRCLE IN QUESTION theNewRadius after: ", theNewRadius);
      this.state.circles.get(id).radius = theNewRadius;
      theCircle.setRadius(theNewRadius);

      const theCenter = theCircle.getCenter();
      this.state.circles.get(id).center = theCircle.getCenter();

      this.onZoneChanged(theCircle);
      //newCircle.setMap(null);
      //newCircle.setMap(this.map)

      const zonesRef = geofirestore.collection('zones_locataires');
      zonesRef.doc(id).update({
        radius: theNewRadius,
        coordinates: new firebase.firestore.GeoPoint(theCenter.lat(), theCenter.lng()),
      }).catch(e => console.log("error radius update", e));
    }
    else {
      console.log("CANCELED");
      theCircle.setRadius(this.state.circles.get(id).radius);
      theCircle.setCenter(this.state.circles.get(id).center);

    }

    this.setState({ dialog: false })
  }

  dialogDelete = (confirmed) => {

    if (!this.state.lastCircle) return;

    const id = this.state.lastCircle.id;

    const theCircle = this.state.lastCircle;
    const zonesRef = geofirestore.collection('zones_locataires');

    if (confirmed) {
      zonesRef.doc(id.toString()).delete();
      theCircle.setMap(null);
    }

    this.setState({ dialogDelete: false })
  }

  handlePriceRangeChange = (event, newValue) => {
    this.setState({priceRange: newValue});
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value });
  }  

  launchSearch = () => {
    this.handleClick();
  }

  resetCircles = () => {
    console.log("reset");
    if (this.modifyModeRef){
      this.addModeRef.style.borderColor = "white";
      this.modifyModeRef.style.borderColor = "white";
      this.deleteModeRef.style.borderColor = "white";
    }
    this.state.circles.forEach((circle, key, map) => {
      // console.log("CIRCLE", circle)
      circle.marker.setDraggable(false);
      circle.marker.setEditable(false);
      circle.marker.clickable = false;
    })
  }

  render() {

    var action_type;
    action_type = this.state.action_type;

    if (action_type == "show_critere") {
        $("#show_critere").hide();
        $("#hide_critere").show();
        $(".critere_advanced_input").show();
        
    } else if (action_type == "hide_critere") {
        $("#show_critere").show();
        $("#hide_critere").hide();
        $(".critere_advanced_input").hide();
    }

    switch (this.state.mode){
      case "add":
          this.resetCircles();
          this.addModeRef.style.border = '2px solid';
          this.addModeRef.style.borderColor = "red";
      break;
      case "modify":
          this.resetCircles();
          this.modifyModeRef.style.border = '2px solid ';
          this.modifyModeRef.style.borderColor = "red";
          this.state.circles.forEach((circle, key, map) => {
            // console.log("CIRCLE", circle)
            circle.marker.setDraggable(true);
            circle.marker.setEditable(true);
            circle.marker.clickable = true;
          })

      break;
      case "delete":
          this.resetCircles();
          this.deleteModeRef.style.border = '2px solid';
          this.deleteModeRef.style.borderColor = "red";
      break;      
      case "default":
          this.resetCircles();
      break;
    }
      

    const dialog =
      <Dialog
        open={this.state.dialog}
        // onClose={this.dialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmer?"}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
      </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={() => { this.dialogClose(false) }} color="red">
            Annuler
      </Button>
          <Button onClick={() => { this.dialogClose(true) }} color="primary" autoFocus>
            Oui
      </Button>
        </DialogActions>
      </Dialog>

    const deleteDialog =
      <Dialog
        open={this.state.dialogDelete}
        // onClose={this.dialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Supprimer?"}</DialogTitle>
        {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous location data to
          Google, even when no apps are running.
    </DialogContentText>
      </DialogContent> */}
        <DialogActions>
          <Button onClick={() => { this.dialogDelete(false) }} color="red">
            Annuler
    </Button>
          <Button onClick={() => { this.dialogDelete(true) }} color="primary" autoFocus>
            Oui
    </Button>
        </DialogActions>
      </Dialog>

    return (
      <div>
        {dialog}
        {deleteDialog}
        <section style={{ paddingTop: '0px !important' }} className="MargeTop" id="mapPageContainer">
          <header id="critere_header">
            <div className="container" style={{ zIndex: '500' }}>
              <h1 className="critere_title"> Trouvez l’appartement qui vous convient !</h1>
              <div className="critere_container">
                <input id="map_input" className="form-control input_critere" type="text" placeholder="Rechercher par région, ville, rue" />
                {/* <span  onClick={this.launchSearch} className="critere_search_button"><span className="critere_search_text">Rechercher</span><i className="lnr lnr-magnifier search_icon" /></span> */}
                <p style={{ margin: '20px auto' }}>
                  <Typography>Loyer mensuel</Typography>
                  <Slider
                    value={this.state.priceRange}
                    onChange={this.handlePriceRangeChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={(value)=>`${value}$`}
                    min={0}
                    max={5000}
                    style={{width:'100%'}}
                    valueLabelDisplay="on"
                  />
                </p>
                <p className="critere_advanced">
                  Critères avancés
                <br />
                <i onClick={this.handleClickGetId} id="show_critere" className="fas fa-chevron-down" style={{cursor: 'pointer'}} />
                <i onClick={this.handleClickGetId} id="hide_critere" className="fas fa-chevron-up" style={{display: 'none', cursor: 'pointer'}} />
                </p> 

                <div className="critere_advanced_input" style={{display: 'none'}}>
                  <FormControl component="fieldset">
                    <InputLabel htmlFor="demo-controlled-open-select">Type</InputLabel>
                    <Select
                      value={this.state.type}
                      onChange={this.handleTextChange('type')}
                      input={<Input name="type" id="type-helper" />}
                      style={{ marginBottom: 30 }}
                    >
                      <MenuItem value={"Chambre"}>Chambre</MenuItem>
                      <MenuItem value={"1½"}>1½</MenuItem>
                      <MenuItem value={"2½"}>2½</MenuItem>
                      <MenuItem value={"3½"}>3½</MenuItem>
                      <MenuItem value={"4½"}>4½</MenuItem>
                      <MenuItem value={"5½"}>5½</MenuItem>
                      <MenuItem value={"6½+"}>6½+</MenuItem>
                    </Select>

                    <FormControlLabel
                      control={<Checkbox checked={this.state.chauffe} onChange={this.handleChange('chauffe')} value="chauffe" />}
                      label="Chauffée"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={this.state.eau_chaude} onChange={this.handleChange('eau_chaude')} value="eau_chaude" />}
                      label="Eau chaude"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={this.state.lave_vaisselle} onChange={this.handleChange('lave_vaisselle')} value="lave_vaisselle" />}
                      label="Lave-vaisselle"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={this.state.laveuse_secheuse} onChange={this.handleChange('laveuse_secheuse')} value="laveuse_secheuse" />}
                      label="Laveuse/sécheuse"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={this.state.animaux} onChange={this.handleChange('animaux')} value="animaux" />}
                      label="Accepte animaux"
                    />
                  </FormControl> 
                </div>   
              </div>
            </div>
          </header>

          <div className="container-fluid" style={{ padding: '0' }}>
            <div style={{ marginLeft: '0px', boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.4)', padding: '0px', borderRadius: '10px' }} className="col-12">
              {this.state.mapsReady && <div id="map"></div>}
            </div>
          </div>

          <div className="container">
            <div>
              <div style={{ paddingLeft: 0, marginTop: '50px' }} className="col-12">
                <p className="resultat_recherche_title">Voici les résultats de la recherche</p>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
                      <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                      <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="First slide" />
                      </div>
                      <div className="carousel-item">
                        <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Second slide" />
                      </div>
                      <div className="carousel-item">
                        <img className="d-block w-100 image_carousel" src="https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg" alt="Third slide" />
                      </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                  <div className="description_container">
                    <span className="description_name"> Bel et grand appartement sur la rive-sud</span>
                    <br />
                    <span className="description_price">950$ / mois</span>
                    <br />
                    <span className="description_owner_name"> Phanuel Randrianandraina</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  // console.log("IN CRITERE STATE", state);
  if (state.user) {
    return {
      user: state.user,
      geolocalisation: state.geolocalisation
    }
  }
}
export default connect(mapStateToProps)(CritereLocataire)