import React, { Component } from 'react';
import FormLocataire from './form_locataire';
import FormProprietaire from './form_proprietaire';
import {connect} from 'react-redux';

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      typeUser: "",
    }
  }

  componentDidMount() {
    const user= this.props.user;
    if (user){
      this.setState({
        email: user.email,
        picture: user.photoURL,
        nom: user.displayName,
      });
    }
  }
  render() {
    var pageToLoad, typeUtilisateur;
    typeUtilisateur = this.props.user.type;

    if (typeUtilisateur == "loc") {
      pageToLoad = <FormLocataire />;
    } else {
      pageToLoad = <FormProprietaire />;
    }
    return (
        <div> 
           {pageToLoad}
        </div>
    )
  }
}
function mapStateToProps(state) {
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps)(Settings);