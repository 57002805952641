import React, { Component } from 'react';
import posed, {PoseGroup} from 'react-pose';
import '../style/IlocMatch.css';
import {value, transform} from 'popmotion';
import firebase from 'firebase';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';


var database = firebase.database();
var firestore = firebase.firestore();
const { pipe, clamp, interpolate, conditional } = transform;

const Box = posed.div({    
    state: {test:0},
    draggable:  true,
    init: { scale: 1, boxShadow: '0px 0px 0px rgba(0,0,0,0)' },
    drag: { scale: 1, boxShadow: '5px 5px 10px rgba(0,0,0,0.5)',
    },  
    dragEnd: {
      x: "0",
      y: '0',
      transition: { type: 'spring',   stiffness: 200,
      damping: 20 },
      rotate: "0deg"
    },
    passive: {
      rotate: ['xy', (xy) =>   
         
        {
          const rotationInterpolate = interpolate([-200, 200], [-15, 15]);
          var toReturn = rotationInterpolate(xy.x); 
          //console.log("rotation ", toReturn);
          // if (xy.rotationSign < 0)
          // console.log("rotation Sign", xy.rotationsign);
          // console.log(toReturn *  xy.rotationsign);
          return toReturn *  xy.rotationsign;
        }
        
       // (xy.x) => { interpolate([-100, 100], [-30, 30])})
        
      , true]
    },
    exit:{
      x: ({swipeType}) => {
        if (swipeType === "right")
        {
          return 500;
        }
        else if (swipeType === "left")
        {
          return -500;
        }
      },
      opacity: 0,
      display: "none",
      transition: {
        x: { ease: 'easeOut', duration: 200 },
        opacity:  { ease: 'easeOut', duration: 200 },
        display: {duration: 0},
      }
    },

  });

const Tuto = posed.img({
  pressable: true,
  init: { opacity:0.8 },
  press: { opacity: 0 }
})

const NopeText = posed.div({
  passive: {
    opacity: ['x', pipe(
      interpolate([-100, 0], [1, 0]),
      clamp(0, 1)
    ), true]
  }
})

const LikeText = posed.div({
  passive: {
    opacity: ['x', pipe(
      interpolate([0, 100], [0, 1]),
      clamp(0, 1)
    ), true]
  }
})
const Uncheck = posed.i({
  passive: {
    opacity: ['x', pipe(
      interpolate([-100, 0], [1, 0]),
      clamp(0, 1)
    ), true]
  }
})

const Check = posed.i({
  passive: {
    opacity: ['x', pipe(
      interpolate([0, 100], [0, 1]),
      clamp(0, 1)
    ),true]
  }
})


class IlocMatch extends Component
{
  liked = [];

  constructor(props)
  {
    super(props);
    this.swipeLeft = this.swipeLeft.bind(this);
    this.swipeRight = this.swipeRight.bind(this);
    this.writeCartData = this.writeCartData.bind(this);
    this.state = {
      actualIndex :0
    }
  }
  writeCartData(appartId, liked, img) {
    var db = firebase.firestore();
    console.log('writing to cart', this.props.user.uid);
    this.liked.push(appartId);
     db.collection('carts')
     .doc(this.props.user.uid)
    .set({
      liked: this.liked,
    })
    .then(()=> console.log('reussi'))
    .catch((e) => console.log('error', e))
    
  }

  componentWillReceiveProps(nextProps) {

  }
  
  swipeRight(appartId, img) {
    const newIndex = this.actualIndex + 1;
    this.setState({actualIndex: this.state.actualIndex + 1 });
    this.writeCartData(appartId, true, img);
  }


  swipeLeft(appartId) {
    console.log('swiped left');
    const newIndex = this.actualIndex + 1;
    this.setState({actualIndex: this.state.actualIndex + 1 })
  }

  x = value(0);

    render () {
      const backgroundImages = [
      "https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg",
      "https://fr.ardennes-etape.be/maisons-de-vacances-ardennes/Vielsalm/Appartement-106459-01/Maison_Fiche2-Appartements-106459-01-Vielsalm-1313097-1L.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSim3RAYMCvmIQ3tO9UzpskUF2DWnobzWM6tY5PwZUtWcazk2WE",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS09KoUO5Q98jsccHUTEFMMxXseZagKAOodQwYjnCmCnIIiKd0a5w",  
      // "https://res.cloudinary.com/dqoxbvata/image/upload/v1538078873/1_wv5rdo.jpg",
      // "https://fr.ardennes-etape.be/maisons-de-vacances-ardennes/Vielsalm/Appartement-106459-01/Maison_Fiche2-Appartements-106459-01-Vielsalm-1313097-1L.jpg",
      // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSim3RAYMCvmIQ3tO9UzpskUF2DWnobzWM6tY5PwZUtWcazk2WE",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS09KoUO5Q98jsccHUTEFMMxXseZagKAOodQwYjnCmCnIIiKd0a5w",  
    ]

      if (this.props.user.type == 'loc')
      {

      }
      else {

      }     
      // const imagesToShow = backgroundImages.slice(this.state.actualIndex, this.state.actualIndex+2);
      
      const IlocMatchCards = backgroundImages.map((img, index) =>  {
       if (index === backgroundImages.length - 1)
       {
        return <IlocMatchCard first={true} x={this.x} id={index} key={index} img={img} onSwipeRight={this.swipeRight} onSwipeLeft={this.swipeLeft}/> 
       }
       else
       {
        return <IlocMatchCard first={false} x={this.x} id={index} key={index} img={img} onSwipeRight={this.swipeRight} onSwipeLeft={this.swipeLeft}/> 
       }
        
      });
      const GoToPanier = <NavLink to="panier" key="99"> Go to Panier</NavLink>;
      console.log( this.state.actualIndex)
      return (
        <div id="card-container">
            <PoseGroup className="flex-grid">
             { this.state.actualIndex <= backgroundImages.length -1 ? IlocMatchCards   : GoToPanier  }            
            </PoseGroup>
          <div class="text-center" style={{paddingTop: "10px"}}>
              {/* <Uncheck parentValues={this.x} id="uncheck" class="fa fa-times" aria-hidden="true"></Uncheck>
              <Check parentValues={this.x} id="check" class="fa fa-check" aria-hidden="true"></Check> */}
            </div>

            <div className="row">
                <div className="col-6 no-padding">
                    <Uncheck parentValues={this.x} className="suivant green_background"><i className="fas fa-arrow-circle-left"></i>&nbsp; Suivant</Uncheck>
                </div>
                <div className="col-6 no-padding">
                    <Check parentValues={this.x} className="favoris pink_background"><i className="fas fa-heart"></i>&nbsp; Favoris</Check>
                </div>
            </div>
        </div>
      )
    }
}

class IlocMatchCard extends Component
{
  constructor(props)
  {
    super(props);   
    this.touchStart = this.touchStart.bind(this);
    this.valueChangeListener = this.valueChangeListener.bind(this);
    this.touchEnded = this.touchEnded.bind(this);
    this.state = {
      transformOrigin: "center center",
      rotationSign: 1,
      actualX:0,
      pose: "init",
      swipeType: 0,
      firstClick: false
    }
    
  }

  componentDidMount() {
    const height = document.getElementsByClassName('box')[0].clientHeight;
    const width = document.getElementsByClassName('box')[0].clientWidth;
    this.setState({height:height});
    this.setState({width:width});
  }

  xy = value({x:0, y:0, rotationsign:1});

  touchStart(e){
    this.setState({firstClick:true});
    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY;
    const height = document.getElementsByClassName('box')[0].clientHeight;
    const width = document.getElementsByClassName('box')[0].clientWidth;

    var xHalfLeft = Boolean(touchX < width / 2);
    var yHalfTop = Boolean(touchY < height / 2);

    var transformOrigin = "";
    if (!xHalfLeft && yHalfTop) //zone 1
    {
      var transformOrigin = "0% 0%";
      this.setState({rotationSign:1});
    }
    else if (xHalfLeft && yHalfTop) //zone 2
    {
      var transformOrigin = "100% 0%";
      this.setState({rotationSign:-1});
    }
    else if (xHalfLeft && !yHalfTop) // zone 3
    {
      var transformOrigin = "";
      this.setState({rotationSign:-1});
    }
    else //zone 4
    {
      var transformOrigin = "top left";
      this.setState({rotationSign:-1});
    }
    
    this.setState({transformOrigin:transformOrigin});
    var prevValue = this.xy.current;
    prevValue.rotationSign = this.state.rotationSign;
    this.xy.update(prevValue);
  }

  valueChangeListener(value)   
  {
    var prevValue = this.xy.current;
    prevValue.x = value;
    this.props.x.update(prevValue);
    this.xy.update(prevValue);
    this.setState({actualX:value});
  }
  
  touchEnded(){
    console.log("HEERE", this.state.actualX);
    console.log(this.state.width /2.5);
    if (Math.abs(this.state.actualX) > (this.state.width /3))
    {
      if (this.state.actualX > 0) 
      {
        this.props.onSwipeRight(this.props.id, this.props.img);
        this.setState({swipeType:"right"});
      }
      else if (this.state.actualX < 0)
      {
        this.props.onSwipeLeft(this.props.id, this.props.img);
        this.setState({swipeType:"left"});
      }
      this.setState({pose:"exit"});
    }
  }

  render () {
    const backgroundImageUrl = this.props.img;
    const style = {
        backgroundColor: "white",
    //   backgroundImage: 'url(' + backgroundImageUrl + ')',
        backgroundSize: "100% 100%",
        transformOrigin: this.state.transformOrigin,
      
    }
    return (
      // <div className="card-image-container"> 
        <Box
           swipeType={this.state.swipeType} pose={this.state.pose} 
            onTouchStart={this.touchStart} 
            onTouchEnd={this.touchEnded} 
            i={this.state.transformOrigin} parentValues={{xy: this.xy}} onValueChange={{x: this.valueChangeListener}} style={style}  className="box"> 
            <LikeText   className="like text-in-card">YES</LikeText>
            <NopeText   className="nope text-in-card">NO</NopeText>
            <div class="carousel-caption">
                <img style={{height: '75px', borderRadius: '100%', marginBottom: '5px'}} src="https://firebasestorage.googleapis.com/v0/b/iloc-16b8d.appspot.com/o/Images%2Fblank_profil.jpg?alt=media&token=0bcbb9bf-511c-40df-9a6c-3f6d53c91329" />                          
                <p className="matching_appartement_name_proprio">Nom Prenom</p>
                <hr />
                <p className="matching_price_proprio">Type de logement :</p>
                <p className="matching_price_proprio">Durée :</p>
                <p className="matching_price_proprio">Statut civil :</p>
                <p className="matching_price_proprio">Statut professionnel :</p>
            </div>
        </Box>
    )    
  }
}
function mapStateToProps(state) {
  console.log("IN MATCH", state.user);
  if (state.user)
  {
    return {
      user: state.user,
    }
  }
}
export default connect (mapStateToProps)(IlocMatch);
